import Immutable from "immutable";
import React from "react";
import * as GQL from "src/types/graphql";
import * as State from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/editMessagesState";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/editMessagesDraft";
import {
  Change,
  hasChanges,
  MessageTemplateId,
  MessageTemplateIdType
} from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/common";
import { FormType as EditMessagesFormType } from "./components/types";

const LocalStorageEditMessagesKey = "editMessages";

export type EditMessagesInitialValues = {
  editMessages: EditMessagesFormType;
};

export type Action =
  | {
      type: "UpdateEditMessages";
      editMessages: Draft.EditMessagesDraft;
      messageTemplateType: GQL.message_template_type_enum;
    }
  | { type: "Load"; json: string }
  | {
      type: "Refresh";
      initialValues: EditMessagesInitialValues;
      messageTemplateType: GQL.message_template_type_enum;
    }
  | { type: "Save"; messageTemplateType: GQL.message_template_type_enum }
  | { type: "SaveComplete" }
  | { type: "Reset"; messageTemplateType: GQL.message_template_type_enum }
  | { type: "ResetComplete" };

export function reducer(state: State.State, action: Action): State.State {
  switch (action.type) {
    case "Save":
      return state.set("save", action.messageTemplateType);

    case "SaveComplete":
      return state.set("save", undefined);

    case "Load":
      return load(state, action.json);

    case "Reset":
      return reset(state, action.messageTemplateType);

    case "ResetComplete":
      return state.set("reset", undefined);

    case "UpdateEditMessages":
      return updateEditMessages(
        state,
        action.editMessages,
        action.messageTemplateType
      );

    case "Refresh":
      return refresh(action.initialValues, action.messageTemplateType);

    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
}

function reset(
  state: State.State,
  messageTemplateType: GQL.message_template_type_enum
): State.State {
  const editMessages = state.get("editMessages").map(clearChange);

  return state
    .set("editMessages", editMessages)
    .set("reset", messageTemplateType);
}

function clearChange<T, U>(change: Change<T, U>): Change<T, U> {
  return { original: change.original, draft: undefined };
}

function updateEditMessages(
  state: State.State,
  draft: Draft.EditMessagesDraft,
  messageTemplateType: GQL.message_template_type_enum
): State.State {
  const originalEditMessagesState = state
    .get("editMessages")
    .get(MessageTemplateId(messageTemplateType));
  if (!originalEditMessagesState) {
    return state;
  }

  const original = originalEditMessagesState.original;
  const hasChangesResult = hasChanges(original, draft);

  const updatedState: State.State = state.update(
    "editMessages",
    (editMessages) =>
      editMessages.set(MessageTemplateId(messageTemplateType), {
        ...originalEditMessagesState,
        draft: hasChangesResult ? draft : undefined
      })
  );

  return updatedState;
}

function toState(
  initialValues: EditMessagesInitialValues,
  messageTemplateType: GQL.message_template_type_enum
): State.State {
  const editMessages: [MessageTemplateIdType, State.EditMessagesChange][] = [
    [
      MessageTemplateId(messageTemplateType),
      { original: initialValues.editMessages, draft: undefined }
    ]
  ];

  return State.NewState({
    editMessages: Immutable.Map(editMessages)
  });
}

function refresh(
  initialValues: EditMessagesInitialValues,
  messageTemplateType: GQL.message_template_type_enum
): State.State {
  return reset(
    toState(initialValues, messageTemplateType),
    messageTemplateType
  );
}

export function useFormTemplateEditMessagesProcessor(
  initialValues: EditMessagesInitialValues,
  messageTemplateType: GQL.message_template_type_enum,
  formTemplateId: uuid
) {
  const initialState = toState(initialValues, messageTemplateType);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const json = loadFromLocalstorage(formTemplateId);
    dispatch({
      type: "Load",
      json
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (state.get("save")) {
      saveToLocalstorage(state.set("save", undefined), formTemplateId);
      dispatch({ type: "SaveComplete" });
    }

    if (state.get("reset")) {
      clearLocalstorage(formTemplateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return { state, dispatch };
}

function loadFromLocalstorage(formTemplateId: uuid): string {
  return (
    localStorage.getItem(`${formTemplateId}-${LocalStorageEditMessagesKey}`) ??
    "{}"
  );
}

function load(state: State.State, json: string): State.State {
  return State.deserialize(state, JSON.parse(json));
}

function saveToLocalstorage(state: State.State, formTemplateId: uuid): void {
  const stateWithoutOriginal = State.serialize(state);
  localStorage.setItem(
    `${formTemplateId}-${LocalStorageEditMessagesKey}`,
    JSON.stringify(stateWithoutOriginal)
  );
}

function clearLocalstorage(formTemplateId: uuid): void {
  localStorage.removeItem(`${formTemplateId}-${LocalStorageEditMessagesKey}`);
}
