import _ from "lodash";
import Immutable from "immutable";
import * as GQL from "src/types/graphql";
import { removeFields, toJSON } from "src/services/object";

export type IsDraft = {
  readonly isDraft: true;
};

export type Draft<ORIGINAL> = ORIGINAL & IsDraft;

export type Change<T, V = T> = {
  readonly original: T;
  readonly draft: V | undefined;
};

export type WithDraft<T> = { readonly draft: T };

export const FormTemplateId = (formTemplateId: uuid) =>
  Immutable.Record<{ formTemplateId: uuid }>({
    formTemplateId
  })({ formTemplateId });
export type FormTemplateIdType = Immutable.Record<{ formTemplateId: uuid }>;

export const MessageTemplateId = (
  messageTemplateId: GQL.message_template_type_enum
) =>
  Immutable.Record<{ messageTemplateId: GQL.message_template_type_enum }>({
    messageTemplateId
  })({ messageTemplateId });

export type MessageTemplateIdType = Immutable.Record<{
  messageTemplateId: uuid;
}>;

export function toOriginal<ORIGINAL>(draft: Draft<ORIGINAL>): ORIGINAL {
  return removeFields(draft, "isDraft") as ORIGINAL;
}

export function hasChanges<ORIGINAL extends {}>(
  original: ORIGINAL | undefined | null,
  draft: Draft<ORIGINAL> | undefined
): boolean {
  if (original === undefined || original === null) {
    return true;
  }

  if (draft === undefined) {
    return false;
  }

  return !_.isEqual(toJSON(original), toJSON(toOriginal(draft)));
}
