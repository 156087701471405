import type { Settings } from "./settings";

import * as React from "react";
import { createContext, ReactNode } from "react";

import { INITIAL_SETTINGS } from "./settings";

type SettingsContextShape = {
  setOptions: (options: Partial<Settings>) => void;
  settings: Settings;
};

const Context: React.Context<SettingsContextShape> = createContext({
  setOptions: (options: Partial<Settings>) => {
    return;
  },
  settings: INITIAL_SETTINGS,
});

export const SettingsContext = ({
  children,
  initialSettings,
}: {
  children: ReactNode;
  initialSettings?: Partial<Settings>;
}): JSX.Element => {
  const [settings, setSettings] = React.useState({
    ...INITIAL_SETTINGS,
    ...initialSettings,
  });

  const setOptions = React.useCallback((options: Partial<Settings>) => {
    setSettings((prevOptions) => ({
      ...prevOptions,
      ...options,
    }));
  }, []);

  const contextValue = React.useMemo(() => {
    return { setOptions, settings };
  }, [setOptions, settings]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useSettings = (): SettingsContextShape => {
  return React.useContext(Context);
};
