import {
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { RiLightbulbLine } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card } from "src/components/Layout/Card";
import { Selection } from "src/hooks/useMultiselectState";
import { useOrganization } from "src/hooks/useOrganization";
import * as FormTemplate from "src/services/formTemplate";
import { OrgAdmin } from "src/services/url";
import * as GQL from "src/types/graphql";
import { BulkExportState } from "../export";
import { GqlSchoolForm, SchoolFormKeyRecord } from "../types";

type ExportMode = "express" | "custom" | null;
type ExportModeObject = {
  mode: ExportMode;
  title: string;
  description: string;
};

const exportModes = [
  {
    mode: "express" as ExportMode,
    title: "Express",
    description:
      "Export everything in your current view, download automatically once done",
  },
  {
    mode: "custom" as ExportMode,
    title: "Custom",
    description: "Customize file name and how to receive the export",
  },
];

interface BulkExportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>;
  handleBulkExport: () => Promise<void>;
  formTemplate?: GQL.FormTemplateFragment;
  exportSubmitted?: { text: string; scheduled: boolean };
  dropoffFormTemplateId?: uuid;
}

export const BulkExportDialog: React.FC<BulkExportDialogProps> = ({
  isOpen,
  onClose,
  selection,
  handleBulkExport,
  formTemplate,
  exportSubmitted,
  dropoffFormTemplateId,
}) => {
  const navigate = useNavigate();
  const organization = useOrganization();
  const [searchParams] = useSearchParams();
  const [modeSelected, setModeSelected] = useState<ExportMode>(null);
  const [modeSubmitted, setModeSubmitted] = useState<ExportMode>(null);

  const questionIdsInOrder = useMemo(() => {
    return FormTemplate.Question.getQuestionIdsInOrder(
      formTemplate ? FormTemplate.fromGQL(formTemplate).sections : undefined
    );
  }, [formTemplate]);

  const onCloseComplete = useCallback(() => {
    modeSubmitted === "express" && handleBulkExport();
    modeSubmitted === "custom" &&
      navigate(
        organization
          .map((org) =>
            OrgAdmin.Forms.export({
              organization: org,
              formTemplateId: formTemplate?.id ?? "",
              params: searchParams.toString(),
            })
          )
          .withDefault("#"),
        {
          state: {
            formTemplateId: formTemplate?.id,
            questionIdsInOrder,
            selection: {
              selectionType: selection.isInclusive ? "Inclusive" : "Exclusive",
              ids: selection.keysIncludedOrExcluded,
            },
            dropoffFormTemplateId,
          } as BulkExportState,
        }
      );
    setModeSelected(null);
    setModeSubmitted(null);
  }, [
    dropoffFormTemplateId,
    formTemplate?.id,
    handleBulkExport,
    modeSubmitted,
    navigate,
    organization,
    questionIdsInOrder,
    searchParams,
    selection.isInclusive,
    selection.keysIncludedOrExcluded,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      onCloseComplete={onCloseComplete}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export</ModalHeader>
        <ModalBody>
          {exportSubmitted && (
            <VStack alignItems="start" gap={4}>
              <Text>{exportSubmitted.text}</Text>
              {exportSubmitted.scheduled && (
                <VStack alignItems="start">
                  <Tag size="md" variant="solid" colorScheme="primary">
                    <Icon boxSize={4} as={RiLightbulbLine} paddingRight={1} />
                    <Text size="xs">Tip</Text>
                  </Tag>
                  <Text>
                    You can find this and any other scheduled export under Data
                    services, Scheduled exports.
                  </Text>
                </VStack>
              )}
            </VStack>
          )}
          {!exportSubmitted && (
            <>
              <Text>
                Selected {selection.size} form{selection.size > 1 && "s"}. How
                would you like to export?
              </Text>
              <Spacer height={4} />
              <Flex gap={2} textAlign="center">
                {exportModes.map((modeObject) => (
                  <ExportModeCard
                    key={modeObject.mode}
                    modeObject={modeObject}
                    setModeSelected={setModeSelected}
                    selected={modeObject.mode === modeSelected}
                  />
                ))}
              </Flex>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {exportSubmitted && (
            <Button onClick={onClose} variant="ghost">
              Done
            </Button>
          )}
          {!exportSubmitted && (
            <>
              <Button onClick={onClose} variant="ghost">
                Cancel
              </Button>
              <Button
                isDisabled={!modeSelected}
                onClick={() => {
                  setModeSubmitted(modeSelected);
                  onClose();
                }}
              >
                Next
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type ExportModeCardProps = {
  modeObject: ExportModeObject;
  setModeSelected: React.Dispatch<React.SetStateAction<ExportMode>>;
  selected: boolean;
};

const ExportModeCard: React.FC<ExportModeCardProps> = ({
  modeObject,
  setModeSelected,
  selected,
}) => {
  const cardSelectedStyle = selected
    ? {
        color: "primary.500",
        background: "primary.50",
        borderColor: "primary.500",
        _hover: {
          cursor: "pointer",
          background: "primary.20",
        },
      }
    : {};
  return (
    <Card
      showBorder
      padding={4}
      flex={1}
      _hover={{
        cursor: "pointer",
        background: "blackAlpha.200",
      }}
      onClick={() => setModeSelected(modeObject.mode)}
      {...cardSelectedStyle}
    >
      <Flex direction="column" gap={3}>
        <Heading size="md">{modeObject.title}</Heading>
        <Text fontSize="xs">{modeObject.description}</Text>
      </Flex>
    </Card>
  );
};
