import { isEmptyLexicalState } from "src/components/WysiwygEditor/lexical/utils/converters";
import { MESSAGE_CONTENT_LIMIT } from "src/constants";
import { z } from "zod";

export const FormSchema = z.object({
  emailSubject: z.string().min(1, "This field is required"),
  emailMarkup: z.string().optional(),
  emailText: z.string().min(1, "This field is required"),
  emailLexical: z
    .string()
    .optional()
    .superRefine((val, ctx) => {
      if (isEmptyLexicalState(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "This field is required."
        });
      }
    }),
  legacyMarkup: z.string().optional(),
  smsBody: z
    .string()
    .min(1, "This field is required")
    .superRefine((val, ctx) => {
      if (val.length > MESSAGE_CONTENT_LIMIT) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Limit reached ${MESSAGE_CONTENT_LIMIT - val.length}`
        });
      }
    })
});
export type FormType = z.infer<typeof FormSchema>;
