import { Button, Divider, Flex, Heading, Spacer } from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import { Form, Formik, FormikErrors } from "formik";
import React, { ChangeEvent, useState } from "react";
import { useParams } from "react-router";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { NotFound } from "src/components/Feedback/NotFound";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { DetailHeader } from "src/components/Layout/DetailHeader";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { RelationshipsTable } from "src/components/Table/RelationshipsTable";
import { GET_PERSON_RELATIONSHIPS } from "src/components/graphql/queries";
import { RelationshipTypes, SearchAndFilterTypes } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrderByParams } from "src/hooks/useCommonSearchParams";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { AttendanceForm } from "src/scenes/orgAdmin/components/Forms/AttendanceForm";
import {
  StudentAttendanceItemValidator,
  StudentAttendanceItemValidatorCurrent,
} from "src/schemas/Student";
import * as breadcrumb from "src/services/breadcrumb";
import { formatAttendingSchool } from "src/services/person";
import { isNotNull } from "src/services/predicates";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { SafeParseReturnType, ZodError } from "zod";
import { StudentForm } from "../components/Forms/StudentForm";
import {
  REPLACE_APPLICANT_ATTENDING_SCHOOLS,
  UPDATE_STUDENT,
} from "./graphql/mutations";
import {
  GET_FORMS_BY_STUDENT_ID,
  GET_STUDENTS,
  GET_STUDENT_BY_ID_ADMIN_PORTAL,
} from "./graphql/queries";
import {
  StudentProfileForm,
  StudentProfileFormAttendanceItem,
  StudentProfileSchema,
  StudentProfileValidator,
} from "./schemas";
import { RelatedFormsTable } from "./tables/RelatedFormsTable";
import { buildOrderBy, getSiblings } from "./tables/helpers";
import { PossibleDuplicatesTable } from "src/components/Table/PossibleDuplicatesTable";

// TODO: separate component for View so that we don't have this editingDisabled prop
export const EditStudent = ({ editingDisabled = false }) => {
  const { glossary } = useGlossary();
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const organization = useOrganization();
  const userPermissions = useUserPermissions();
  const { isElevatedSchoolAdmin, applyingSchoolIds, attendingSchoolIds } =
    useSchoolAdmin();

  const toast = useAvelaToast();
  const { id: student_id = "" } = useParams();
  const [isActive, setIsActive] = useState<boolean>();
  const flags = useFlags([
    "current-applying-schools",
    "student-sibling-table",
    "avela-labs-possible-student-duplicates",
  ]);

  const [searchParams] = useSearchParams();
  const enrollmentPeriodId =
    searchParams.get(SearchAndFilterTypes.EnrollmentPeriod) ?? "";

  const { remoteData: studentRemoteData } = useRemoteDataQuery<
    GQL.GetStudentByIdAdminPortal,
    GQL.GetStudentByIdAdminPortalVariables
  >(GET_STUDENT_BY_ID_ADMIN_PORTAL, {
    variables: { student_id },
  });

  const { remoteData: studentRelationships } = useRemoteDataQuery<
    GQL.GetPersonRelationships,
    GQL.GetPersonRelationshipsVariables
  >(GET_PERSON_RELATIONSHIPS, {
    variables: {
      id: student_id,
    },
  });

  const { orderBy: relatedFormsOrderBy, setOrderBy: setRelatedFormsOrderBy } =
    useOrderByParams();

  const { remoteData: relatedForms } = useRemoteDataQuery<
    GQL.GetFormsByStudentId,
    GQL.GetFormsByStudentIdVariables
  >(GET_FORMS_BY_STUDENT_ID, {
    variables: {
      id: student_id,
      order_by: buildOrderBy(relatedFormsOrderBy),
    },
  });

  const [updateStudent, { remoteData: updateStudentRemoteData }] =
    useRemoteDataMutation<GQL.UpdateStudent, GQL.UpdateStudentVariables>(
      UPDATE_STUDENT
    );

  const [
    replaceApplicantAttendingSchools,
    { remoteData: reaplceApplicantAttendingSchoolsRemoteData },
  ] = useRemoteDataMutation<
    GQL.replaceApplicantAttendingSchools,
    GQL.replaceApplicantAttendingSchoolsVariables
  >(REPLACE_APPLICANT_ATTENDING_SCHOOLS);

  const isLoading =
    updateStudentRemoteData.isLoading() ||
    reaplceApplicantAttendingSchoolsRemoteData.isLoading() ||
    reaplceApplicantAttendingSchoolsRemoteData.isLoading();

  const allowedCurrentSchoolsEditors = [
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.DISTRICT_ADMIN,
  ];
  const canEditCurrentSchools = useRequiredHasuraRoles(
    allowedCurrentSchoolsEditors
  );

  const handleSubmit = async (form: StudentProfileForm) => {
    organization.do(async (org) => {
      if (isActive !== undefined) form.active = isActive;

      setSubmitting(true);
      try {
        const { applicant_attending_schools, ...student } =
          StudentProfileValidator.parse(form);
        await updateStudent({
          variables: {
            id: student_id,
            student: {
              ...student,
            },
          },
          refetchQueries: [GET_STUDENTS],
        });

        if (canEditCurrentSchools) {
          const delete_attending_schools: GQL.applicant_attending_school_bool_exp =
            {
              person_id: { _eq: student_id },
              enrollment_period_id: {
                _nin: applicant_attending_schools.map(
                  (item) => item.enrollment_period_id
                ),
              },
            };

          const insert_attending_schools: GQL.applicant_attending_school_insert_input[] =
            applicant_attending_schools.map((item) =>
              formatAttendingSchool({
                person_id: student_id,
                ...item,
              })
            );

          await replaceApplicantAttendingSchools({
            variables: {
              delete_attending_schools: delete_attending_schools,
              insert_attending_schools: insert_attending_schools,
            },
          });
        }

        toast({
          id: "update-student",
          title: glossary`Student updated`,
          isClosable: true,
          status: "info",
        });
        navigate(Url.OrgAdmin.Students.index(org));
      } catch (err) {
        console.error(`${JSON.stringify(err)}`);
        toast.error({
          title: glossary`Error updating student`,
        });
      } finally {
        setSubmitting(false);
      }
    });
  };

  const handleChangeStatus = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const validate = (
    values: StudentProfileForm
  ): void | object | Promise<FormikErrors<StudentProfileForm>> => {
    let errors: FormikErrors<StudentProfileForm> =
      {} as FormikErrors<StudentProfileForm>;
    try {
      StudentProfileValidator.parse(values);

      const attendance_errors =
        [] as FormikErrors<StudentProfileFormAttendanceItem>[];
      values.applicant_attending_schools.forEach((item, index) => {
        attendance_errors.push(
          {} as FormikErrors<StudentProfileFormAttendanceItem>
        );
        try {
          StudentAttendanceItemValidator.parse(item);
          if (
            item.attendance_status === GQL.attendance_status_type_enum.CURRENT
          ) {
            StudentAttendanceItemValidatorCurrent.parse(item);
          }
        } catch (error) {
          if (error instanceof ZodError) {
            attendance_errors[index] = {
              ...attendance_errors[index],
              ...error.formErrors.fieldErrors,
            };
            errors.applicant_attending_schools = attendance_errors;
          }
        }
      });
    } catch (error) {
      if (error instanceof ZodError) {
        errors = {
          ...error.formErrors.fieldErrors,
          ...errors,
        };
      }
    }
    return errors;
  };

  const canEditUser = userPermissions.hasOne("user:update");

  return (
    <GQLRemoteDataView remoteData={studentRemoteData}>
      {(data) => {
        if (data.person_by_pk === null) {
          return <NotFound />;
        }

        const student: GQL.GetStudentByIdAdminPortal_person_by_pk =
          data.person_by_pk;

        type ParsedType = SafeParseReturnType<
          GQL.GetStudentByIdAdminPortal_person_by_pk,
          StudentProfileForm
        >;
        const parsed: ParsedType = StudentProfileSchema.safeParse(student);
        if (!parsed.success) {
          console.error(parsed.error);
          return <GenericError />;
        }
        const forms = student.forms.map((app) => ({
          id: app.id,
          enrollmentPeriodName: app.form_template.enrollment_period.name,
          formTemplateName: app.form_template.name,
          formTemplateId: app.form_template.id,
        }));

        const school_selection = [
          ...(data.person_by_pk?.organization?.schools ?? []),
        ].sort((lhs, rhs) => (lhs.name < rhs.name ? -1 : 1));

        const siblings = getSiblings(
          student,
          isElevatedSchoolAdmin,
          applyingSchoolIds,
          attendingSchoolIds
        );

        return (
          <>
            {!editingDisabled && (
              <Breadcrumb
                items={breadcrumb.student.getBreadcrumbsForEdit(
                  organization,
                  data.person_by_pk
                )}
                mb={8}
              />
            )}
            <Formik<StudentProfileForm>
              initialValues={parsed.data}
              onSubmit={handleSubmit}
              validate={validate}
            >
              {({ values }) => {
                return (
                  <Flex as={Form} direction="column" gap={12} width="100%">
                    <DetailHeader
                      data={student}
                      type={GQL.person_type_enum.applicant}
                      onActiveChange={handleChangeStatus}
                      isLoading={isLoading}
                      isActive={isActive}
                      isDisabled={!canEditUser || editingDisabled}
                    />
                    <Flex direction="column" gap={6}>
                      <Heading as="h2" fontSize="2xl">
                        Student Profile
                      </Heading>
                      <StudentForm
                        firstName={student.first_name}
                        lastName={student.last_name}
                        forms={forms}
                        isDisabled={!canEditUser || editingDisabled}
                      />
                    </Flex>

                    {flags["current-applying-schools"].enabled && (
                      <WithRequiredHasuraRoles
                        roles={allowedCurrentSchoolsEditors}
                      >
                        <Divider />
                        <Flex direction="column" gap={6}>
                          <Heading as="h2" fontSize="2xl">
                            Attended schools
                          </Heading>
                          <AttendanceForm
                            organization={organization}
                            attendance_selection_data={{
                              enrollment_periods:
                                data.person_by_pk?.organization
                                  ?.enrollment_periods ?? [],
                              schools: school_selection,
                            }}
                            values={values}
                          ></AttendanceForm>
                        </Flex>
                      </WithRequiredHasuraRoles>
                    )}
                    <Divider />
                    <GQLRemoteDataView remoteData={studentRelationships}>
                      {(relationshipData) => (
                        <RelationshipsTable
                          tableData={relationshipData.relationship_person
                            .map((item) =>
                              item.first?.id === student.id
                                ? { id: item.id, person: item.second }
                                : { id: item.id, person: item.first }
                            )
                            .filter((item) => isNotNull(item.person))}
                          personId={data.person_by_pk?.id}
                          entityType={GQL.person_type_enum.applicant}
                          relationshipType={RelationshipTypes.Guardian}
                          readOnly={editingDisabled}
                        />
                      )}
                    </GQLRemoteDataView>

                    {flags["student-sibling-table"].enabled && (
                      <RelationshipsTable
                        tableData={siblings.map((sibling) => ({
                          id: sibling.id,
                          person: sibling,
                        }))}
                        personId={student.id}
                        entityType={GQL.person_type_enum.applicant}
                        relationshipType={RelationshipTypes.Sibling}
                        readOnly={true}
                      />
                    )}

                    {flags["avela-labs-possible-student-duplicates"]
                      .enabled && <PossibleDuplicatesTable student={student} />}

                    <GQLRemoteDataView remoteData={relatedForms}>
                      {(relatedFormsData) => (
                        <RelatedFormsTable
                          enrollmentPeriodId={enrollmentPeriodId}
                          applicantId={student.id}
                          isActiveApplicant={student.active}
                          data={relatedFormsData.form}
                          onSort={setRelatedFormsOrderBy}
                        />
                      )}
                    </GQLRemoteDataView>
                    {!editingDisabled && (
                      <Flex align="center" gap={3}>
                        <Spacer />
                        <Button
                          as={NavLink}
                          to={organization
                            .map((org) => Url.OrgAdmin.Students.index(org))
                            .withDefault("#")}
                          isLoading={organization.isLoading()}
                          variant="link"
                        >
                          Cancel
                        </Button>
                        {canEditUser && (
                          <Button
                            type="submit"
                            marginLeft={4}
                            isLoading={submitting}
                          >
                            Update
                          </Button>
                        )}
                      </Flex>
                    )}
                    {editingDisabled && (
                      <WithUserPermissions permissions={["user:update"]}>
                        <AdminFormButtons>
                          <Button
                            as={NavLink}
                            to={Url.OrgAdmin.Parents.edit(
                              organization,
                              student.id
                            )}
                          >
                            Edit
                          </Button>
                        </AdminFormButtons>
                      </WithUserPermissions>
                    )}
                  </Flex>
                );
              }}
            </Formik>
          </>
        );
      }}
    </GQLRemoteDataView>
  );
};
