import { BreadcrumbType } from "..";
import { capitalizeFirstLetter, splitPascalCase } from "../../format";
import * as Url from "../../url";

export const getBreadcrumbsForList = (): BreadcrumbType[] => {
  return [
    {
      label: "Glossaries",
      href: Url.Admin.Glossaries.index(),
    },
  ];
};

export const getBreadcrumbsForEdit = (
  organizationId: uuid,
  organizationName: string
): BreadcrumbType[] => {
  return [
    ...getBreadcrumbsForList(),
    {
      label: `${capitalizeFirstLetter(splitPascalCase(organizationName))}${
        organizationName.endsWith("s") ? "'" : "'s"
      } glossary`,
      href: Url.Admin.Glossaries.edit(organizationId),
    },
  ];
};
