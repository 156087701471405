import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb as ChakraBreadrumb,
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbLink,
  Icon,
  StyleProps,
  Skeleton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Glossary } from "src/components/Text/Glossary";
import { BreadcrumbData, BreadcrumbType } from "src/services/breadcrumb";
import * as RD from "src/types/remoteData";

type BreadcrumbProps = StyleProps & {
  items: BreadcrumbData[];
};

export const Breadcrumb = ({ items, ...restProps }: BreadcrumbProps) => {
  return (
    <ChakraBreadrumb
      separator={<Icon as={ChevronRightIcon} color="gray.500" marginX={1} />}
      {...restProps}
    >
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        if (RD.isRemoteData(item)) {
          if (item.isLoading()) {
            return (
              <ChakraBreadcrumbItem key={`${index}-skeleton`}>
                <Skeleton width="5rem" height="1rem" data-testid="loading" />
              </ChakraBreadcrumbItem>
            );
          }
          if (item.hasData()) {
            return createBreadcrumbItem({ isLast, index, item: item.data });
          } else {
            return null;
          }
        }

        return createBreadcrumbItem({ isLast, index, item });
      })}
    </ChakraBreadrumb>
  );
};

type BreadcrumbItemProps = {
  index: number;
  isLast: boolean;
  item: BreadcrumbType;
};
const createBreadcrumbItem = ({ isLast, index, item }: BreadcrumbItemProps) => {
  return (
    <ChakraBreadcrumbItem
      fontSize="xs"
      fontWeight="bold"
      isCurrentPage={isLast}
      key={item.href}
    >
      <BreadcrumbLink
        as={isLast ? undefined : Link}
        to={item.href}
        color={isLast ? "gray.700" : "primary.500"}
      >
        <Glossary>{item.label}</Glossary>
      </BreadcrumbLink>
    </ChakraBreadcrumbItem>
  );
};
