import { Flex, FormControl, FormLabel, Heading, Text } from "@chakra-ui/react";
import { useField } from "formik";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import {
  InformationPopover,
  InformationPopoverTypes
} from "./InformationPopover";

export const Configuration: React.FC = () => {
  const [{ value }] = useField<boolean>("lotteryAndOffersEnabled");

  return (
    <Flex direction="column" gap={6}>
      <Heading fontSize="lg" color="gray.700">
        Configuration
      </Heading>

      <Flex>
        <SwitchInput<boolean>
          label=""
          name="locked"
          checked={[false, ""]}
          unchecked={[true, ""]}
        />

        <Flex direction="column" width="100%">
          <Text>Accept new form entries</Text>
          <Text fontSize="sm">
            When enabled, families will be able to start a new entry for this
            form - unless they already have one in progress.
          </Text>
        </Flex>
      </Flex>
      <Flex>
        <SwitchInput<boolean>
          label=""
          name="lotteryAndOffersEnabled"
          checked={[true, ""]}
          unchecked={[false, ""]}
        />
        <Flex direction="column" width="100%">
          <>
            <Flex gap={2} alignItems="center">
              <Text>Lottery & offers</Text>
              <InformationPopover
                id={InformationPopoverTypes.LotteryAndOffers}
              />
            </Flex>
            <Text fontSize="sm">
              With lottery & offers; Capacities and Lottery orders, along with
              new Automatic messages and Statuses can be configured
            </Text>
          </>

          {value && (
            <Flex mt={2}>
              <FormControl>
                <FormLabel>Offer starts</FormLabel>
                <InputWithValidationControl
                  id="offerStartAt"
                  name="offerStartAt"
                  inputProps={{ type: "datetime-local" }}
                />
              </FormControl>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
