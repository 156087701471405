import { useLocation } from "react-router-dom";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_ORGANIZATION_BY_ID } from "../graphql/queries";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { identity } from "lodash";
import { z } from "zod";

/**
 * The loading mechanism for organization data follows this pattern:
 * 1. First checks if an organization was passed via router state
 * 2. If state exists, returns successful remote data directly
 * 3. Otherwise, queries GraphQL for organization details using provided ID
 * 4. Maps GraphQL response into simplified organization model with id and name
 * 5. Returns failure state if organization lookup fails
 */
export const useOrganizationFromId = (
  id: string | undefined
): RD.RemoteData<unknown, Organization> => {
  const location = useLocation();
  const state = LocationStateSchema.safeParse(location.state);
  const organizationFromState = state.success
    ? state.data.organization
    : undefined;

  const { remoteData: organizationRD } = useRemoteDataQuery<
    GQL.GetOrganization,
    GQL.GetOrganizationVariables
  >(GET_ORGANIZATION_BY_ID, {
    variables: { id: id ?? "" },
    skip: !id || organizationFromState !== undefined,
  });

  if (organizationFromState && organizationFromState.id === id) {
    return RD.success(organizationFromState);
  }

  return organizationRD.mapError<unknown>(identity).andThen((data) =>
    data.organization_by_pk
      ? RD.success({
          id: data.organization_by_pk.id,
          name: data.organization_by_pk.name,
        })
      : RD.failure(new Error("Invalid organization"))
  );
};
const OrganizationSchema = z.object({
  id: z.string(),
  name: z.string(),
});
type Organization = z.infer<typeof OrganizationSchema>;
const LocationStateSchema = z.object({ organization: OrganizationSchema });
