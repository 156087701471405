import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { EmptyStateCard } from "src/components/EmptyState";
import { GenericError } from "src/components/Feedback/GenericError";
import { FormSection } from "src/components/Layout/FormSection";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { ReactComponent as NoRecordsEmptyState } from "src/images/no-records.svg";
import { toTuple3 } from "src/types/remoteData";
import {
  useGetCustomQuestionAnswerQuery,
  useGetPersonAnswerBankQuery,
  useGetSelectedPersonAnswerBankQuery,
} from "./api";
import { useCustomQuestionContext } from "./context/CustomQuestionContext";
import { OutdatedAnswerAlert } from "./form/OutdatedAnswerAlert";
import { AnswerBankRadioGroup } from "./list/AnswerBankRadioGroup";
import * as amplitude from "@amplitude/analytics-browser";

export const ListAnswerBankRecords: FunctionComponent<{}> = () => {
  const {
    customQuestion,
    customQuestionType,
    formId,
    personId,
    sourceIdCloneIdMapping,
  } = useCustomQuestionContext();

  const customQuestionAnswerRemoteData = useGetCustomQuestionAnswerQuery({
    form_id: formId,
    question_id: customQuestion.id,
  });

  const answerBankRemoteData = useGetPersonAnswerBankQuery(
    sourceIdCloneIdMapping,
    { custom_question_type_id: customQuestionType.id, person_id: personId }
  );

  const selectedAnswerBankRemoteData = useGetSelectedPersonAnswerBankQuery(
    sourceIdCloneIdMapping,
    { form_id: formId, question_id: customQuestion.id }
  );

  const { isOpen: outdatedAlertIsOpen, onClose: outdatedAlertOnClose } =
    useDisclosure({
      defaultIsOpen: true,
    });

  return (
    <RemoteDataView
      remoteData={toTuple3(
        answerBankRemoteData,
        selectedAnswerBankRemoteData,
        customQuestionAnswerRemoteData
      )}
      error={() => (
        <GenericError message="Failed to load answer bank options." />
      )}
    >
      {([answerBankRecords, selectedAnswerBank, customQuestionAnswer]) => {
        if (!customQuestionAnswer) {
          return null;
        }

        if (answerBankRecords.length === 0 && selectedAnswerBank.length === 0) {
          return (
            <EmptyStateCard
              description={`You don't have any ${customQuestion.question.toLocaleLowerCase()} yet`}
              Svg={NoRecordsEmptyState}
            >
              <AddAnswerBankButton isEmptyBank />
            </EmptyStateCard>
          );
        }

        return (
          <FormSection
            title={`Select ${customQuestion.question.toLocaleLowerCase()}`}
          >
            <Flex direction="column" gap={2}>
              {outdatedAlertIsOpen && (
                <OutdatedAnswerAlert
                  formId={formId}
                  questionId={customQuestion.id}
                  nestedQuestions={customQuestion.nestedQuestions}
                  closeAlert={outdatedAlertOnClose}
                />
              )}
              <AnswerBankRadioGroup
                answerBankRecords={answerBankRecords}
                selectedAnswerBankId={selectedAnswerBank[0]?.answerBankId}
                formAnswerValues={customQuestionAnswer}
              />
              <AddAnswerBankButton />
            </Flex>
          </FormSection>
        );
      }}
    </RemoteDataView>
  );
};

const AddAnswerBankButton: FunctionComponent<{ isEmptyBank?: boolean }> = ({
  isEmptyBank,
}) => {
  const { customQuestion, setShowAnswerBank } = useCustomQuestionContext();

  return (
    <Button
      size="sm"
      variant={isEmptyBank ? "outline" : "solid"}
      colorScheme="gray"
      width="100%"
      onClick={() => {
        setShowAnswerBank(false);
        amplitude.track("Add new entry clicked", {
          cqt_name: customQuestion.question,
          button_location: isEmptyBank
            ? "empty_state_primary"
            : "card_secondary",
        });
      }}
    >
      Add new {customQuestion.question.toLocaleLowerCase()}
    </Button>
  );
};
