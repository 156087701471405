import { useApolloClient } from "@apollo/client";
import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Icon, useDisclosure } from "@chakra-ui/react";
import _ from "lodash";
import { useEffect } from "react";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";
import { FormTabsTypes, SearchAndFilterTypes } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { Selection } from "src/hooks/useMultiselectState";
import { useOrganization } from "src/hooks/useOrganization";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import useUser from "src/hooks/useUser";
import {
  triggerDownload,
  triggerDownloadFromUrl,
} from "src/services/dataTransfer";
import {
  CsvWorkerInputMessage,
  CsvWorkerOutputMessage,
} from "src/services/dataTransfer/csvWorker";
import {
  ExportWorkerInputMessage,
  ExportWorkerOutputMessage,
} from "src/services/dataTransfer/exportWorker";
import * as FormTemplate from "src/services/formTemplate";
import { Question } from "src/services/formTemplate";
import * as Url from "src/services/url/OrgAdmin";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { ExportRequest, SelectionType } from "../../dataServices/exports/types";
import { useExportManagementAPI } from "../../dataServices/exports/useExportManagementAPI";
import {
  EXPORT_QUESTIONS_AND_VERIFICATIONS,
  GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD,
} from "../graphql/queries";
import {
  GqlSchoolForm,
  SchoolFormId,
  SchoolFormKeyRecord,
  SchoolFormKeyRecordFactory,
} from "../types";
import { BulkExportDialog } from "./BulkExportDialog";

const DEFAULT_BATCH_SIZE = 20;

const TOAST_ID = "BulkExportButton";

interface BulkExportButtonProps {
  formTemplate?: GQL.FormTemplateFragment;
  onFetchAllIds: () => Promise<SchoolFormId[]>;
  onFetchByIds: (ids: SchoolFormId[]) => Promise<GQL.ExportForms>;
  selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>;
  dropoffFormTemplateId?: uuid;
}

export const BulkExportButton: React.FC<BulkExportButtonProps> = ({
  formTemplate,
  onFetchAllIds,
  onFetchByIds,
  selection,
  dropoffFormTemplateId,
}) => {
  const toast = useAvelaToast();
  const organization = useOrganization();
  const user = useUser();
  const api = useExportManagementAPI();
  const flags = useFlags(["current-applying-schools"]);

  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { batch } = useQueryParams();
  const [searchParams] = useSearchParams();

  const { isSchoolAdmin } = useSchoolAdmin();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: submittedIsOpen,
    onClose: submittedOnClose,
    onOpen: submittedOnOpen,
  } = useDisclosure({
    onClose: () => {
      // remove exportSubmittedText and scheduled from state
      const { exportSubmittedText, scheduled, ...otherState } =
        location.state ?? {};
      navigate(".", {
        state: otherState,
        replace: true,
      });
    },
  });

  // Use location.state to keep track of export customization form submission state
  // The form is on a different page from this button component
  const { exportSubmittedText, scheduled } = location.state ?? {};
  useEffect(() => {
    if (exportSubmittedText) {
      submittedOnOpen();
    }
  }, [exportSubmittedText, navigate, searchParams, submittedOnOpen]);

  const errorHandler = (error: unknown) => {
    toast.close(TOAST_ID);
    toast.error({
      title: "Error exporting forms",
      duration: null,
      id: TOAST_ID,
    });
    console.error(JSON.stringify(error));
  };

  const isImportTab =
    searchParams.get(SearchAndFilterTypes.Tab) ===
    FormTabsTypes.FormListImports;
  const isImportPath =
    matchPath(Url.FormImport.viewPath, location.pathname) !== null;
  const isImportList = isImportTab || isImportPath;

  const backendExportModeOn = !isImportList; // TODO: backend export for imported forms

  const handleBulkExport = async () => {
    try {
      if (backendExportModeOn) {
        toast({
          description:
            "You'll receive an email once ready, you can close this window or navigate elsewhere if needed.",
          duration: null,
          id: TOAST_ID,
          status: "loading",
          title: "Export in progress",
          isClosable: true,
        });

        const searchParamsObject = Object.fromEntries(searchParams.entries());
        if (dropoffFormTemplateId) {
          // Replace dropoffFormTemplateId in searchParams with the value of the prop dropoffFormTemplateId,
          // which is needed for the search query in the backend.
          // (The existing value is the same as formTemplate.id.)
          searchParamsObject.dropoffFormTemplateId = dropoffFormTemplateId;
        }
        const searchParamsString = new URLSearchParams(
          searchParamsObject
        ).toString();

        const selectionObject = {
          selectionType: selection.isInclusive
            ? SelectionType.Inclusive
            : SelectionType.Exclusive,
          ids: selection.keysIncludedOrExcluded,
        };
        const questionIdsInOrder = Question.getQuestionIdsInOrder(
          formTemplate ? FormTemplate.fromGQL(formTemplate).sections : undefined
        );
        const formTemplateId = formTemplate?.id ?? "";
        const organizationId = organization.toNullable()?.id;
        if (user.status !== Status.OK && user.status !== Status.NOT_VERIFIED) {
          throw new Error("Can't find current user");
        }
        const login = user.data.loginType;
        if (login.type !== "email") {
          throw new Error("User does not have email");
        }
        const email = login.email;
        const postData: ExportRequest = {
          exportConfig: {
            searchParamsString,
            selection: selectionObject,
            formTemplateId: formTemplateId,
          },
          email,
          questionIdsInOrder,
        };
        const response = await api.createExport(organizationId ?? "", postData);
        if (!response?.pk) {
          throw new Error("Bulk export management API error");
        }
        const { pk } = response;

        // Poll for completion and auto-download
        const checkExportCompletion = async () => {
          try {
            const response = await api.getOneExport(organizationId ?? "", pk);
            if (!response || response.status === "FAILED") {
              throw new Error("Export failed");
            }
            if (response.status === "COMPLETED") {
              if (!response.url) {
                throw new Error("Export failed");
              }
              clearInterval(pollingInterval);
              triggerDownloadFromUrl(response.url);
              toast.update(TOAST_ID, {
                description:
                  "It has been automatically downloaded and sent to your email.",
                status: "success",
                title: "Export ready",
                isClosable: true,
              });
            }
          } catch (error) {
            clearInterval(pollingInterval);
            errorHandler(error);
          }
        };
        const pollingInterval = setInterval(
          async () => await checkExportCompletion(),
          5000
        );
      } else {
        toast({
          description: "Download will start shortly",
          duration: null,
          id: TOAST_ID,
          isClosable: false,
          title: "Export in progress",
        });

        const ids = await selection.materializeKeys(async () =>
          (await onFetchAllIds()).map(SchoolFormKeyRecordFactory)
        );

        const questionsAndVerifications = await apolloClient.query<
          GQL.ExportQuestionsAndVerifications,
          GQL.ExportQuestionsAndVerificationsVariables
        >({
          query: EXPORT_QUESTIONS_AND_VERIFICATIONS,
          variables: { form_template_id: formTemplate?.id ?? "" },
        });

        if (questionsAndVerifications.error)
          throw new Error(questionsAndVerifications.error.message);

        const questionsMap = Question.toQuestionMap(
          questionsAndVerifications.data.question_by_form_template
        );

        const questionIdsInOrder = Question.getQuestionIdsInOrder(
          formTemplate ? FormTemplate.fromGQL(formTemplate).sections : undefined
        );

        const schoolRankingSection = formTemplate?.sections.find(
          (section) =>
            section?.type ===
            GQL.form_template_section_type_enum.SchoolRankingSection
        );
        const rankingEnabled =
          schoolRankingSection?.schools_ranking_section?.ranking_enabled ??
          false;

        const formVerifications =
          questionsAndVerifications.data.form_verification;

        const disclaimerSectionTitle =
          questionsAndVerifications.data.form_template_section[0]?.title ??
          null;

        const tagGroupsData = await apolloClient.query<
          GQL.GetTagGroupsByEnrollmentPeriod,
          GQL.GetTagGroupsByEnrollmentPeriodVariables
        >({
          query: GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD,
          variables: {
            enrollment_period_id: formTemplate?.enrollment_period_id ?? "",
          },
        });
        if (tagGroupsData.error) throw new Error(tagGroupsData.error.message);
        const tagGroups = tagGroupsData.data.tag_group;

        const csvWorker = new Worker(
          new URL("src/services/dataTransfer/csvWorker.ts", import.meta.url)
        );

        const exportWorker = new Worker(
          new URL("src/services/dataTransfer/exportWorker.ts", import.meta.url)
        );

        csvWorker.onmessage = (e: MessageEvent<CsvWorkerOutputMessage>) => {
          exportWorker.terminate();
          csvWorker.terminate();
          triggerDownload(new Blob([e.data]), "form.csv");
          toast.close(TOAST_ID);
        };

        exportWorker.onerror = errorHandler;
        csvWorker.onerror = errorHandler;

        exportWorker.onmessage = (
          e: MessageEvent<ExportWorkerOutputMessage>
        ) => {
          const message: CsvWorkerInputMessage = e.data;
          csvWorker.postMessage(message);
        };

        const batchSizeInt = parseInt(batch as string);
        const batchSize = isNaN(batchSizeInt)
          ? DEFAULT_BATCH_SIZE
          : batchSizeInt;
        // Group by form to avoid fetching shared form data more than once.
        const idsByForm = Object.values(_.groupBy(ids, (id) => id.formId));
        const chunks = _.chunk(idsByForm, batchSize);

        let batchNumber = 0;
        for (const chunk of chunks) {
          ++batchNumber;
          const batchIds = chunk.flat();

          const records = await onFetchByIds(batchIds);

          const message: ExportWorkerInputMessage = {
            input: {
              records,
              // TODO: remove non-null assertion. We cannot pass in RemoteData because it's not serializable, so this is a workaround.
              organization: organization.toNullable()!,
              questionsMap,
              questionIdsInOrder,
              formVerifications,
              disclaimerSectionTitle,
              tagGroups,
              options: {
                skipRank: isSchoolAdmin || !rankingEnabled,
                includeAttendingSchool:
                  flags["current-applying-schools"].enabled,
              },
            },
            batch: {
              batchNumber,
              totalBatches: chunks.length,
            },
          };
          exportWorker.postMessage(message);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Button
        leftIcon={<Icon as={DownloadIcon} />}
        onClick={backendExportModeOn ? onOpen : handleBulkExport}
        size="sm"
        variant="banner"
      >
        Export
      </Button>
      {backendExportModeOn && (
        <>
          {/* Two separate dialogs
              - One for before showing the export customization form, allow choosing between "Express" and "Custom"
              - One for after submitting the export customization form, confirming submission
            This is needed since sharing the dialog for both purposes leads to complex state management.
            The modal must be closed before navigating to the form page; without closing it, the form page scroll is stuck. */}
          <BulkExportDialog
            isOpen={isOpen}
            onClose={onClose}
            selection={selection}
            handleBulkExport={handleBulkExport}
            formTemplate={formTemplate}
            dropoffFormTemplateId={dropoffFormTemplateId}
          />
          <BulkExportDialog
            isOpen={submittedIsOpen}
            onClose={submittedOnClose}
            selection={selection}
            handleBulkExport={handleBulkExport}
            formTemplate={formTemplate}
            exportSubmitted={{ text: exportSubmittedText, scheduled }}
          />
        </>
      )}
    </>
  );
};
