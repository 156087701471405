import { Box, Flex, Icon, VStack } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { MotionBox } from "src/animations/MotionBox";
import { useAnimatePresenceWithReduceMotion } from "src/hooks/useAnimation";
import { NavItemElement } from ".";
import { AnimationContext } from "./AnimationContext";
import { ImLab } from "react-icons/im";
import { NavItemIconType } from "./NavItem";

export type NavGroupElement = React.ReactElement<Props>;
type NavItemNode = NavItemElement | boolean | undefined | null;
type Props = {
  kind?: "NavGroupElement";
  label?: string;
  trailIcon?: NavItemIconType;
  children: NavItemNode | NavItemNode[];
};

export function NavGroup({ label, children, trailIcon }: Props) {
  const animatePresence = useAnimatePresenceWithReduceMotion();
  return (
    <AnimationContext.Consumer>
      {({ mode, disableAnimation }) => (
        <VStack
          as="li"
          align="flex-start"
          margin="0 0 2.5rem 0"
          padding="0"
          justifyContent="center"
        >
          <AnimatePresence initial={false}>
            {mode === "full" && label && (
              <MotionBox
                disableAnimation={disableAnimation}
                fontSize="sm"
                display="flex"
                alignItems="center"
                height="2.5rem"
                {...animatePresence({
                  initial: { opacity: 0 },
                  animate: { opacity: 1 },
                  transition: { delay: 0.15 },
                })}
              >
                <Flex alignItems="center" gap="4">
                  {label}
                  {trailIcon && (
                    <Icon
                      w="5"
                      h="5"
                      as={ImLab}
                      color="primary.500"
                      aria-hidden="true"
                    />
                  )}
                </Flex>
              </MotionBox>
            )}
            {mode === "compact" && label && <Box height="2.5rem" />}
          </AnimatePresence>
          <VStack
            align="flex-start"
            as="ul"
            margin="0"
            width="100%"
            aria-label={label}
          >
            {children}
          </VStack>
        </VStack>
      )}
    </AnimationContext.Consumer>
  );
}
