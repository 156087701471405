import { html } from "src/components/HtmlViewer/utils";
import * as GQL from "src/types/graphql";

export const FORM_CANCELLED = {
  type: GQL.message_template_type_enum.FormCancelled,
  emailSubject: "Your {{organization.name}} form has been canceled.",
  emailHtml: html`<p>
    Dear {{parent.name}}, <br />
    <br />
    Your {{organization.name}} form for {{student.name}} for
    {{enrollmentPeriod.name}} has been canceled by an administrator. <br />
    If this was a mistake, please contact the {{organization.name}} Common Form
    team school or district administrator. <br />
  </p>`,
  emailText: `Dear {{parent.name}},

Your {{organization.name}} form for {{student.name}} for {{enrollmentPeriod.name}} has been canceled by an administrator.
If this was a mistake, please contact the {{organization.name}} Common Form team school or district administrator.`,
  sms: "Your {{organization.name}} form for {{student.name}} has been canceled."
};
