import { Box, Flex, IconButton, Skeleton, Tooltip } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { RiRefreshLine, RiEditLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import * as Url from "src/services/url";
import * as RD from "src/types/remoteData";
import { RelativeTime } from "src/components/Text/RelativeTime";
import { useField } from "formik";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { GenericError } from "src/components/Feedback/GenericError";
import { Table } from "src/components/Table/Table";
import { Env, useEnv } from "src/services/env";
import useAccessToken from "src/hooks/useAccessToken";
import { AuthData, Status } from "src/types/authData";
import { z } from "zod";
import { Map, Set } from "immutable";
import { useAvelaToast } from "src/hooks/useAvelaToast";

export const ExploreList: React.FC<Props> = ({ organization }) => {
  const toast = useAvelaToast();
  const { remoteData, timestamps, refreshInstance } =
    useExploreList(organization);
  const [, , helper] = useField("organizationConfigs.Explore.disabled");
  const [refreshState, setRefreshState] = useState<Set<string>>(Set());

  useEffect(() => {
    if (remoteData.hasData()) {
      helper.setValue(remoteData.data.length === 0 ? true : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteData]);

  return (
    <Flex direction="column" gap={4}>
      <SwitchInput<true | undefined>
        isDisabled={true}
        label="Explore"
        name={`organizationConfigs.Explore.disabled`}
        checked={[undefined, ""]}
        unchecked={[true, ""]}
        direction="row"
        formLabelProps={{ fontSize: "md", alignItems: "center" }}
      />
      <Flex direction="column" gap={2} marginLeft={12}>
        <RemoteDataView
          error={(error) => <GenericError error={error} />}
          remoteData={remoteData}
          loading={
            <Flex direction="column" gap="2">
              <Skeleton width="100%" height="3rem" />
              <Skeleton width="100%" height="3rem" />
              <Skeleton width="100%" height="3rem" />
            </Flex>
          }
        >
          {(data) => {
            if (data.length === 0) {
              return null;
            }

            return (
              <Table
                tableHeader={() => <></>}
                data={data}
                columns={[
                  {
                    id: "1",
                    header: "Instance",
                    accessorFn: (row) => row.domain,
                    size: 1000,
                  },
                  {
                    id: "2",
                    header: "Actions",
                    cell: ({ row }) => {
                      const timestamp =
                        timestamps.get(row.original.path) ?? RD.loading();
                      return (
                        <Flex
                          gap={2}
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <RemoteDataView
                            remoteData={timestamp}
                            error={() => (
                              <GenericError
                                message="Error retrieving last update"
                                variant="inline"
                              />
                            )}
                            loading={<Skeleton height="1.5rem" width="10rem" />}
                          >
                            {(data) => (
                              <Box>
                                Last refreshed{" "}
                                {<RelativeTime timestamp={data} />} ago
                              </Box>
                            )}
                          </RemoteDataView>
                          <Tooltip label="Refresh configurations" hasArrow>
                            <IconButton
                              isLoading={refreshState.has(row.original.path)}
                              aria-label="Refresh configurations"
                              variant="outline"
                              colorScheme="gray"
                              icon={<RiRefreshLine />}
                              onClick={async () => {
                                try {
                                  setRefreshState((state) =>
                                    state.add(row.original.path)
                                  );
                                  await refreshInstance(row.original.path);
                                } catch (error) {
                                  console.error(error);
                                  toast.error({
                                    title: "Instance could not be refreshed",
                                    description:
                                      error instanceof Error
                                        ? error.message
                                        : "Unknown error",
                                  });
                                } finally {
                                  setRefreshState((state) =>
                                    state.remove(row.original.path)
                                  );
                                }
                              }}
                            />
                          </Tooltip>
                          <Tooltip label="Edit configurations" hasArrow>
                            <IconButton
                              as={Link}
                              to={Url.Admin.Organizations.editExplore(
                                organization.id,
                                row.original.path
                              )}
                              state={{ organization }}
                              aria-label="Edit configurations"
                              variant="outline"
                              colorScheme="gray"
                              icon={<RiEditLine />}
                            />
                          </Tooltip>
                        </Flex>
                      );
                    },
                  },
                ]}
              />
            );
          }}
        </RemoteDataView>
      </Flex>
    </Flex>
  );
};

/** Hooks */

const useExploreList = (organization: Organization): ReturnType => {
  const env = useEnv();

  const [remoteData, setRemoteData] = useState<
    RD.RemoteData<Error, ExploreInstance[]>
  >(RD.loading());
  const [timestamps, setTimestamps] = useState(Map<string, TimestampRD>());

  useEffect(() => {
    const goFetch = async () => {
      try {
        const exploreInstances = await fetchExploreList(env, organization.path);
        const timestampsResponses = await Promise.all(
          exploreInstances.map(({ path }) =>
            fetchTimestamp(env, path).then(
              (t) => [path, t] satisfies [string, TimestampRD]
            )
          )
        );
        setRemoteData(RD.success(exploreInstances));
        setTimestamps(Map(timestampsResponses));
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          setRemoteData(RD.failure(error));
        } else {
          setRemoteData(
            RD.failure(new Error("Unknown error", { cause: error }))
          );
        }
      }
    };
    goFetch();
  }, [env, organization.path]);

  const accessToken = useAccessToken();
  const pollTimestampChanges = useCallback(
    async (
      explorePath: string,
      currentTimestamp: TimestampRD | undefined
    ): Promise<void> => {
      const pollInterval = 2_000; /* ms */
      const pollDuration = 300_000; /* ms = 5 minutes */
      const maxPolling = Math.round(pollDuration / pollInterval);
      let polling = 0;

      return new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          try {
            // Check if we reach the maximum polling attempts
            if (polling >= maxPolling) {
              clearInterval(intervalId);
              reject(
                new Error(
                  "Refresh operation timed out. Please contact the Avela team to help troubleshoot this error."
                )
              );
              return;
            }

            const updatedTimestamp = await fetchTimestamp(env, explorePath);

            // Check if timestamp has changed
            if (!isEqual(currentTimestamp, updatedTimestamp)) {
              setTimestamps(timestamps.set(explorePath, updatedTimestamp));
              clearInterval(intervalId);
              resolve();
              return;
            }

            polling++;
          } catch (error) {
            console.error("Error polling timestamp:", error);
            clearInterval(intervalId);
            reject(error);
          }
        }, pollInterval);
      });
    },
    [env, timestamps]
  );

  const refreshInstance = useCallback(
    async (explorePath: string) => {
      const currentTimestamp = timestamps.get(explorePath);
      await refreshExplore(env, accessToken, explorePath);
      await pollTimestampChanges(explorePath, currentTimestamp);
    },
    [accessToken, env, pollTimestampChanges, timestamps]
  );

  return { remoteData, refreshInstance, timestamps };
};

/** API Calls */

async function fetchExploreList(
  env: Env,
  applyPath: string
): Promise<ExploreInstance[]> {
  const response = await fetch(
    `${env.REACT_APP_EXPLORE_SERVICE_URL}/mapping/apply/${applyPath}`,
    {
      method: "GET",
    }
  );
  const json = await response.json();
  const exploreInstances = ExploreInstanceList.parse(json);
  return exploreInstances;
}

async function fetchTimestamp(
  env: Env,
  explorePath: string
): Promise<TimestampRD> {
  try {
    const response = await fetch(
      `${env.REACT_APP_EXPLORE_SERVICE_URL}/metadata/${explorePath}/en.data.json`,
      { method: "GET" }
    );
    const json = await response.json();
    return RD.success(TimestampSchema.parse(json).updated_at);
  } catch (error) {
    return RD.failure(
      error instanceof Error
        ? error
        : new Error("Unknown error", { cause: error })
    );
  }
}

async function refreshExplore(
  env: Env,
  accessToken: AuthData<string>,
  explorePath: string
) {
  if (accessToken.status !== Status.OK) {
    throw new Error("Missing access token");
  }

  await fetch(`${env.REACT_APP_EXPLORE_SERVICE_URL}/refresh/${explorePath}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.data}`,
    },
  });
}

/** Types */

type Organization = {
  id: string;
  path: string;
};
type Props = {
  organization: Organization;
};
const ExploreInstanceSchema = z.object({
  path: z.string(),
  domain: z.string(),
});
type ExploreInstance = z.infer<typeof ExploreInstanceSchema>;
const ExploreInstanceList = z.array(ExploreInstanceSchema);
const TimestampSchema = z.object({ updated_at: z.string() });
type TimestampRD = RD.RemoteData<Error, string>;

type ReturnType = {
  remoteData: RD.RemoteData<Error, ExploreInstance[]>;
  timestamps: Map<string, RD.RemoteData<Error, string>>;
  refreshInstance: (explorePath: string) => Promise<void>;
};

/** Helpers function */
function isEqual(a: TimestampRD | undefined, b: TimestampRD | undefined) {
  return a?.hasData() && b?.hasData() && a.data === b.data;
}
