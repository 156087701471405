export const AvelaAdminOrg = "admin";

function template(entity: string, action?: string): string {
  return `/${AvelaAdminOrg}/${entity}${action ?? ""}`;
}

function indexNewEditTemplates(entity: string) {
  return {
    index: (): string => template(entity),
    new: (): string => template(entity, "/new"),
    edit: (id: string): string => template(entity, `/${id}/edit`),
    editPolicy: (id: string): string => template(entity, `/${id}/edit-policy`),
    editExplore: (id: string, exploreId: string): string =>
      template(entity, `/${id}/explore/${exploreId}`),
    indexPath: template(entity),
    newPath: template(entity, "/new"),
    editPath: template(entity, "/:id/edit"),
    editPolicyPath: template(entity, "/:id/edit-policy"),
    editExplorePath: template(entity, "/:id/explore/:explorePath"), // TODO: update this path when we know more about explore
  };
}

export const Path = `/${AvelaAdminOrg}`;

export const Organizations = {
  ...indexNewEditTemplates("organizations"),
};

export const PriorityTemplates = {
  ...indexNewEditTemplates("priority-templates"),
};

const GLOSSARIES = "glossaries";
export const Glossaries = {
  index: (): string => template(GLOSSARIES),
  edit: (id: string): string => template(GLOSSARIES, `/${id}/edit`),
  indexPath: template(GLOSSARIES),
  editPath: template(GLOSSARIES, "/:id/edit"),
};

export const GradesConfig = {
  ...indexNewEditTemplates("grades-config"),
};

export const Programs = {
  ...indexNewEditTemplates("programs"),
};

export const Boundaries = {
  ...indexNewEditTemplates("boundaries"),
};

export const QuestionTypes = {
  ...indexNewEditTemplates("question-types"),
  view: (id: string): string => template("question-types", `/${id}/view`),
  viewPath: template("question-types", "/:id/view"),
};
