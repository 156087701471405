import { html } from "src/components/HtmlViewer/utils";
import * as GQL from "src/types/graphql";

export const OFFER_ACCEPTED = {
  type: GQL.message_template_type_enum.OfferAccepted,
  emailSubject:
    "You have accepted an offer from {{organization.name}}! / / ¡Ha aceptado una oferta de {{organization.name}}!",
  emailHtml: html`<p>
    Dear {{parent.name}},<br />
    <br />
    You have accepted an offer from {{organization.name}} for {{student.name}},
    for grade {{grade}} for {{enrollmentPeriod.name}}!<br />
    <br />
    You can review the status of {{student.name}}’s form anytime:
    {{applyUrl}}<br />
    <br />
    If you need help, please contact {{supportEmail}}.<br />
    <br />
    -{{organization.name}} Team<br />
    <br />
    —<br />
    Estimado/a {{parent.name}},<br />
    <br />
    ¡Ha aceptado una oferta de {{organization.name}} para {{student.name}}, para
    el grado {{grade}} para {{enrollmentPeriod.name}}!<br />
    <br />
    Puede revisar el estado de la aplicacion de {{student.name}} en cualquier
    momento: {{applyUrl}}<br />
    <br />
    Si necesita ayuda, por favor contacte a {{supportEmail}}.<br />
    <br />
    -Equipo de {{organization.name}}<br />
  </p>`,
  emailText: `Dear {{parent.name}},

You have accepted an offer from {{organization.name}} for {{student.name}}, for grade {{grade}} for {{enrollmentPeriod.name}}!

You can review the status of {{student.name}}’s form anytime: {{{applyUrl}}}

If you need help, please contact {{supportEmail}}.

-{{organization.name}} Team

—
Estimado/a {{parent.name}},

¡Ha aceptado una oferta de {{organization.name}} para {{student.name}}, para el grado {{grade}} para {{enrollmentPeriod.name}}!

Puede revisar el estado de la aplicacion de {{student.name}} en cualquier momento: {{{applyUrl}}}

Si necesita ayuda, por favor contacte a {{supportEmail}}.

-Equipo de {{organization.name}}`,
  sms: "You have accepted an offer from {{organization.name}}!/¡Ha aceptado una oferta de {{organization.name}}!"
};
