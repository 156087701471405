import { Alert, Box } from "@chakra-ui/react";
import { useEffect } from "react";

type Props = { message?: string; error?: Error; variant?: "alert" | "inline" };
export const GenericError: React.FC<Props> = ({
  message,
  error,
  variant = "alert",
}) => {
  useEffect(() => {
    console.error(error);
  }, [error]);
  const errorMessage = message ? message : "Oops! Something went wrong!";
  switch (variant) {
    case "inline":
      return (
        <Box as="span" color="red" padding={1}>
          {errorMessage}
        </Box>
      );
    case "alert":
      return <Alert status="error">{errorMessage}</Alert>; // TODO better default generic error
  }
};
