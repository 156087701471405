import { useCallback, useEffect, useState } from "react";
import { useDocumentStorage } from "./useDocumentStorage";
import {
  EmptyRichContentDocument,
  RichContentDocument,
  RichContentDocumentSchema,
  RichContentDocumentType,
} from "src/types/messages/content";
import { isAxiosError } from "axios";
import { editorStateToPlainText } from "src/components/WysiwygEditor/lexical/utils/converters";
import { LexicalEditor } from "lexical";
import { editorStateToHtmlWithCIDs } from "src/components/WysiwygEditor/lexical/utils/converters";
import * as RD from "src/types/remoteData";

export function useMessageRichContent(emailEditor?: LexicalEditor | null) {
  const {
    getUploadUrl,
    upload,
    getDownloadUrl,
    download,
    getDeleteUrl,
    deleteFile,
  } = useDocumentStorage();

  const getEmailDocument = useCallback(() => {
    if (emailEditor) {
      const htmlWithCIDs = editorStateToHtmlWithCIDs(emailEditor);
      return {
        lexical: emailEditor.getEditorState().toJSON(),
        html: htmlWithCIDs?.html,
        text: editorStateToPlainText(emailEditor),
        attachments: htmlWithCIDs?.attachments,
      } satisfies RichContentDocument;
    }
    return null;
  }, [emailEditor]);

  const uploadRichContent = useCallback(async () => {
    const emailDocument = getEmailDocument();
    if (emailDocument) {
      const uploadUrl = await getUploadUrl({
        filename: "message_payload.json",
        document_type: "MessagePayload",
        document_content_type: "application/json",
      });

      if (!uploadUrl) {
        throw new Error("Unable to get upload URL");
      }

      const uploadResponse = await upload(
        JSON.stringify(emailDocument),
        "application/json",
        uploadUrl
      );

      if (isAxiosError(uploadResponse)) {
        throw new Error("Unable to upload message payload", uploadResponse);
      }

      return { document_id: uploadUrl.document_id, emailDocument };
    }

    return { document_id: null, emailDocument };
  }, [getUploadUrl, upload, getEmailDocument]);

  // at the moment, we will assume that there is only one rich content document per message template
  // in the future, with possible multiple translations, we will need to change this
  const downloadRichContent = useCallback(
    async (
      richContents?: { content_id: string }[]
    ): Promise<RichContentDocumentType | undefined> => {
      if (richContents && richContents?.length > 0) {
        const documentId = richContents[0]?.content_id;
        if (documentId) {
          const url = await getDownloadUrl(documentId);
          if (!url) {
            return;
          }
          // TODO: handle errors
          return RichContentDocumentSchema.parse(await download(url, "json"));
        }
      }

      return {
        lexical: null,
        html: null,
        text: null,
        attachments: null,
      } satisfies EmptyRichContentDocument;
    },
    [getDownloadUrl, download]
  );

  const deleteRichContent = useCallback(
    async (contentId: string) => {
      const deleteUrl = await getDeleteUrl(contentId);
      if (deleteUrl) {
        await deleteFile(deleteUrl);
      }
    },
    [getDeleteUrl, deleteFile]
  );

  return { uploadRichContent, downloadRichContent, deleteRichContent };
}

export function useMessageRichContentQuery(
  contentId: string | undefined | null
) {
  const { downloadRichContent } = useMessageRichContent();

  const [result, setResult] = useState<
    RD.RemoteData<Error, RichContentDocumentType>
  >(RD.notAsked());

  useEffect(() => {
    if (!contentId) {
      setResult(RD.notAsked());
      return;
    }

    downloadRichContent([{ content_id: contentId }])
      .then((richContent) => {
        if (richContent) {
          setResult(RD.success(richContent));
        } else {
          setResult(RD.failure(new Error("Unable to download rich content")));
        }
      })
      .catch((error) => {
        setResult((prev) => RD.failure(error, prev));
      });

    setResult((prev) => RD.loading(prev));
  }, [contentId, downloadRichContent]);

  return { remoteData: result };
}
