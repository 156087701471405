import { html } from "src/components/HtmlViewer/utils";
import * as GQL from "src/types/graphql";

export const FORM_SUBMITTED_RANK_DISABLED = {
  type: GQL.message_template_type_enum.FormSubmitted,
  emailSubject:
    "You have successfully submitted your {{organization.name}}! / ¡Ha enviado exitosamente su {{organization.name}}!",
  emailText: `
Dear {{parent.name}},

Thank you for submitting your {{organization.name}} for {{student.name}} for grade {{grade}} for {{enrollmentPeriod.name}}.

The school choices you submitted are
{{schools.name}}

You can edit your form until it closes on {{form.closeDate}}.
{{{applyUrl}}}

We will attempt to match your child to your highest possible choice with space available. You will be notified by email when offers will go out on {{form.offerDate}}.

If you need help, please contact {{supportEmail}}.

—

Estimado/a {{parent.name}},

Gracias por enviar su aplicación de {{organization.name}} para {{student.name}} para el grado {{grade}} para {{enrollmentPeriod.name}}.

Sus elecciones de escuelas enviadas son
{{schools.name}}

Puede editar su aplicación hasta que la misma se cierre el {{form.closeDate}}.
{{{applyUrl}}}

Intentaremos hacer coincidir a su hijo/a con su elección posible más alta que tenga espacio disponible. Se le notificará por correo electrónico el {{form.offerDate}} con la escuela escogida.

Si necesita ayuda, por favor contacte a {{supportEmail}}.`,
  emailHtml: html` Dear {{parent.name}},<br />
    <br />
    Thank you for submitting your {{organization.name}} for {{student.name}} for
    grade {{grade}} for {{enrollmentPeriod.name}}.<br />
    <br />
    The school choices you submitted are<br />
    {{schools.name}}
    <br />
    You can edit your form until it closes on {{form.closeDate}}.<br />
    <a href="{{applyUrl}}">Edit Form</a><br />
    <br />
    We will attempt to match your child to your highest possible choice with
    space available. You will be notified by email when offers will go out on
    {{form.offerDate}}.<br />
    <br />
    If you need help, please contact {{supportEmail}}.<br />
    —<br />
    Estimado/a {{parent.name}},<br />
    <br />
    Gracias por enviar su aplicación de {{organization.name}} para
    {{student.name}} para el grado {{grade}} para {{enrollmentPeriod.name}}.<br />
    <br />
    Sus elecciones de escuelas enviadas son<br />
    {{schools.name}}
    <br />
    Puede editar su aplicación hasta que la misma se cierre el
    {{form.closeDate}}.<br />
    <a href="{{applyUrl}}">Editar aplicación</a><br />
    <br />
    Intentaremos hacer coincidir a su hijo/a con su elección posible más alta
    que tenga espacio disponible. Se le notificará por correo electrónico el
    {{form.offerDate}} con la escuela escogida.<br />
    <br />
    Si necesita ayuda, por favor contacte a {{supportEmail}}.<br />`,
  sms: `You've submitted your {{organization.name}}! You can edit it until {{form.closeDate}}. Ha enviado su aplicación de {{organization.name}}. Puede editar antes del {{form.closeDate}}. School choices/Las opciones: {{#schools}}{{name}}{{^last}}, {{/last}}{{/schools}}.`
};
