import { useMemo } from "react";
import { z } from "zod";

const EnvSchema = z.object({
  NODE_ENV: z.string(),
  REACT_APP_HASURA_URL: z.string(),
  REACT_APP_AUTH0_DOMAIN: z.string(),
  REACT_APP_AUTH0_CLIENT_ID: z.string(),
  REACT_APP_FORM_SERVICE_URL: z.string(),
  REACT_APP_WEGLOT_APIKEY: z.string(),
  REACT_APP_AVELA_OFFLINE_AUTH_TOKEN: z.string().optional(),
  REACT_APP_GOOGLE_API_KEY: z.string().optional(),
  REACT_APP_MATCH_API_URL: z.string().optional(),
  REACT_APP_FLAGSMITH_URL: z.string(),
  REACT_APP_FLAGSMITH_ENVIRONMENT_KEY: z.string(),
  REACT_APP_ASSETS_BASE_URL: z.string(),
  REACT_APP_CONFIG_URL: z.string(),
  REACT_APP_ORGANIZATION_SERVICE_URL: z.string(),
  REACT_APP_ELIGIBILITY_SERVICE_URL: z.string(),
  REACT_APP_EXPLORE_SERVICE_URL: z.string(),
});

export type Env = z.infer<typeof EnvSchema>;

export function read(): Env {
  return EnvSchema.parse(process.env);
}

export function useEnv() {
  return useMemo(() => read(), []);
}
