import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import {
  $createTextNode,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  TextNode,
} from "lexical";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Flex, Portal, Text } from "@chakra-ui/react";
import { mergeRegister } from "@lexical/utils";
import { Variable } from "src/types/messages/variables";
import { useVariables } from "../hooks/useVariables";
import { $createVariableNode, VariableNode } from "../nodes/VariableNode";

const VariablesRegex = new RegExp("({{(.*))");

const ExactVariablesRegex = new RegExp("({{(.+?)}})");

type VariablePayload = {
  key: string;
};

export const INSERT_VARIABLE_COMMAND: LexicalCommand<VariablePayload> =
  createCommand("INSERT_VARIABLE_COMMAND");

function checkForBraceVariables(
  text: string,
  minMatchLength: number,
  exact: boolean = false
): MenuTextMatch | null {
  const match = exact
    ? ExactVariablesRegex.exec(text)
    : VariablesRegex.exec(text);

  if (match !== null) {
    const matchingString = match[2] ?? "";
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index,
        matchingString,
        replaceableString: match[1] ?? "",
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(
  text: string,
  exact: boolean = false
): MenuTextMatch | null {
  return checkForBraceVariables(text, 0, exact);
}

/**
 * Split Lexical TextNode and return a new TextNode only containing matched text.
 */
function splitNodeContainingQuery(
  anchorNode: TextNode,
  match: MenuTextMatch
): TextNode | null {
  if (!anchorNode.isSimpleText()) {
    return null;
  }

  const startOffset = match.leadOffset;
  const matchOffset = startOffset + match.replaceableString.length;

  let newNode;
  if (startOffset === 0 && matchOffset === anchorNode.getTextContent().length) {
    newNode = anchorNode;
  } else if (startOffset === 0) {
    [newNode] = anchorNode.splitText(startOffset, matchOffset);
  } else {
    [, newNode] = anchorNode.splitText(startOffset, matchOffset);
  }
  return newNode ?? null;
}

class VariableTypeaheadOption extends MenuOption {
  variable: Variable;

  constructor(variable: Variable) {
    super(variable.name);
    this.variable = variable;
  }
}

function VariablesTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: VariableTypeaheadOption;
}) {
  return (
    <Flex
      id={"typeahead-item-" + index}
      key={index}
      tabIndex={-1}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      direction="column"
      mt={2}
      backgroundColor={isSelected ? "gray.100" : "white"}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <Text fontSize="md">{`{{${option.variable.key}}}`}</Text>
      <Text fontSize="sm" color="blackAlpha.700">
        {option.variable.description}
      </Text>
    </Flex>
  );
}

export default function VariablesPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const { isValid, search, isLoading } = useVariables();
  const [queryString, setQueryString] = useState<string | null>(null);
  const [options, setOptions] = useState<Array<VariableTypeaheadOption>>([]);
  useEffect(() => {
    if (queryString !== null && queryString !== undefined && !isLoading) {
      search(queryString, (newResults) => {
        setOptions(
          newResults.map((result) => new VariableTypeaheadOption(result))
        );
      });
    }
  }, [queryString, search, isLoading]);

  const recentVariables: Variable[] = useMemo(
    () =>
      JSON.parse(localStorage.getItem("recentVariables") || "[]").filter(
        (variable: Variable) => isValid(variable.key)
      ),
    [isValid]
  );

  const recentOptions = useMemo(() => {
    return recentVariables.map((result) => new VariableTypeaheadOption(result));
  }, [recentVariables]);

  useEffect(() => {
    if (!editor.hasNodes([VariableNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      // insert variable command for toolbar
      editor.registerCommand<VariablePayload>(
        INSERT_VARIABLE_COMMAND,
        (payload) => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return false;
          }
          // the command initiates the typeahead menu, so insert a text node with the payload
          const variableNode = $createTextNode(payload.key);
          $insertNodes([variableNode]);
          variableNode.selectEnd();

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      // transform text nodes to variable nodes
      editor.registerNodeTransform(TextNode, (textNode: TextNode) => {
        if (textNode.isSimpleText()) {
          const match = getPossibleQueryMatch(textNode.getTextContent(), true);
          if (match) {
            const textNodesToMatch = splitNodeContainingQuery(textNode, match);
            if (textNodesToMatch) {
              const variableKey = match.matchingString;
              textNodesToMatch.replace(
                $createVariableNode(variableKey, isValid(variableKey))
              );
            }
          }
        }
      }),
      // validate each variable node key
      editor.registerNodeTransform(VariableNode, (node: VariableNode) => {
        const variableKey = node.getVariableKey();
        const isValidKey = isValid(variableKey);
        if (node.isValid() !== isValidKey) {
          node.replace($createVariableNode(variableKey, isValidKey));
        }
      })
    );
  }, [editor, isValid]);

  const checkForVariableMatch = useCallback((text: string) => {
    return getPossibleQueryMatch(text) ?? null;
  }, []);

  const onSelectOption = useCallback(
    (
      selectedOption: VariableTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      const { variable } = selectedOption;
      const alreadySetAsRecent = recentVariables.find(
        (recentVariable) => recentVariable.key === variable.key
      );

      if (!alreadySetAsRecent && recentVariables.length >= 3) {
        recentVariables.unshift(variable);
        recentVariables.pop();
        localStorage.setItem(
          "recentVariables",
          JSON.stringify(recentVariables)
        );
      } else if (!alreadySetAsRecent) {
        localStorage.setItem(
          "recentVariables",
          JSON.stringify([...recentVariables, variable])
        );
      }

      editor.update(() => {
        const variableNode = $createVariableNode(
          variable.key,
          isValid(variable.key)
        );
        if (nodeToReplace) {
          nodeToReplace.replace(variableNode);
        } else {
          $getSelection()?.insertNodes([variableNode]);
        }
        variableNode.selectEnd();
        closeMenu();
      });
    },
    [editor, isValid, recentVariables]
  );

  return (
    <LexicalTypeaheadMenuPlugin<VariableTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForVariableMatch}
      options={[...recentOptions, ...options]}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) =>
        options.length ? (
          <Portal containerRef={anchorElementRef}>
            <Flex
              direction="column"
              width="350px"
              maxH="380px"
              p={0}
              borderRadius="sm"
              border="1px solid"
              borderColor="blackAlpha.100"
              background="white"
              boxShadow="lg"
              overflow="auto"
            >
              {recentOptions.length > 0 && (
                <Flex
                  direction="column"
                  borderBottom={`1px solid`}
                  p={5}
                  gap={2}
                  borderColor="gray.200"
                >
                  <Text
                    fontSize="sm"
                    color="blackAlpha.800"
                    fontWeight="semibold"
                  >
                    Recent variables
                  </Text>

                  {recentOptions.map((option, i: number) => (
                    <VariablesTypeaheadMenuItem
                      index={i}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i);
                      }}
                      key={i}
                      option={option}
                    />
                  ))}
                </Flex>
              )}
              <Flex direction="column" p={4} gap={2}>
                <Text
                  fontSize="sm"
                  color="blackAlpha.800"
                  fontWeight="semibold"
                >
                  All variables
                </Text>
                {options.map((option, i: number) => (
                  <VariablesTypeaheadMenuItem
                    index={recentOptions.length + i}
                    isSelected={selectedIndex === recentOptions.length + i}
                    onClick={() => {
                      setHighlightedIndex(recentOptions.length + i);
                      selectOptionAndCleanUp(option);
                    }}
                    onMouseEnter={() => {
                      setHighlightedIndex(recentOptions.length + i);
                    }}
                    key={i}
                    option={option}
                  />
                ))}
              </Flex>
            </Flex>
          </Portal>
        ) : null
      }
    />
  );
}
