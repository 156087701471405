import { gql } from "@apollo/client";

export const UPDATE_MESSAGE_TEMPLATE = gql`
  mutation UpdateMessageTemplate(
    $objects: message_template_insert_input!
    $existing_content_id: uuid
    $skip_delete: Boolean!
  ) {
    insert_message_template_one(
      object: $objects
      on_conflict: {
        update_columns: [
          email_markup
          email_plain_text
          email_subject
          sms_body
          enabled
        ]
        constraint: message_template_type_form_template_id_key
      }
    ) {
      id
      enabled
    }

    delete_message_template_rich_content(
      where: { content_id: { _eq: $existing_content_id } }
    ) @skip(if: $skip_delete) {
      affected_rows
    }
  }
`;

export const UPDATE_MESSAGE_TEMPLATES = gql`
  mutation UpdateMessageTemplates(
    $messageTemplateInserts: [message_template_insert_input!] = []
    $messageTemplateUpdates: [message_template_updates!] = []
  ) {
    insert_message_template(objects: $messageTemplateInserts) {
      affected_rows
      returning {
        id
        type
      }
    }
    update_message_template_many(updates: $messageTemplateUpdates) {
      affected_rows
    }
  }
`;
