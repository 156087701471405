import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import axios from "axios";
import Papa from "papaparse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FiChevronDown, FiClock, FiDownload, FiX } from "react-icons/fi";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import {
  StatusTag,
  StatusTagMetadata,
} from "src/components/DataDisplay/StatusTag";
import { ProgressMeter } from "src/components/Feedback/ProgressMeter";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { SortType } from "src/components/Table/SortButton";
import { TextWithOverflowTooltip } from "src/components/TextWithOverflowTooltip";
import { useDocumentStorage } from "src/hooks/useDocumentStorage";
import { useDownloadProgress } from "src/hooks/useDownload";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { GET_MATCH_DETAILS } from "src/scenes/orgAdmin/match/graphql/queries";
import * as breadcrumb from "src/services/breadcrumb";
import { triggerDownload } from "src/services/dataTransfer";
import { formatIsoDateToOrg } from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { ShowPublicViewButton } from "./components/ShowPublicViewButton";

// Define the placement status metadata
const placementStatuses: Record<ResultStatus, StatusTagMetadata> = {
  Offer: {
    label: "Offered",
    longLabel: "Offered",
    icon: RiLogoutBoxRLine,
    colorScheme: "blue",
  },
  Waitlist: {
    label: "Waitlisted",
    longLabel: "Waitlisted",
    icon: FiClock,
    colorScheme: "orange",
  },
  "Not eligible - No schools selected": {
    label: "Not eligible",
    longLabel: "Not eligible",
    icon: FiX,
    colorScheme: "gray",
  },
};

// Define the data structure for match details
interface MatchDetail {
  formId: string;
  student: {
    name: string;
    id: string;
  };
  school: string;
  placement: keyof typeof placementStatuses;
  grade: string;
  lotteryNumber: string;
  priorityGroup: string;
  tags: string;
  lotteryPosition: string;
  calculatedWaitlistPosition: string;
}

export enum ResultStatus {
  Offer = "Offer",
  Waitlist = "Waitlist",
  NotEligible = "Not eligible - No schools selected",
}

interface MatchResultRow {
  "App ID": string;
  "Student First Name": string;
  "Student Last Name": string;
  "Student ID": string;
  School: string;
  "School ID": string;
  "Lottery Result": ResultStatus;
  Grade: string;
  "Lottery Number": string;
  "Priority Group": string;
  Tags: string;
  "Lottery Position": string;
  "Calculated Waitlist Position": string;
}

export const Details: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const organization = useOrganization();
  const { matchId } = useParams<{ matchId: string }>();
  const [matchResults, setMatchResults] = useState<MatchDetail[]>([]);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<SortType>("unsorted");
  const [csvText, setCsvText] = useState<string>("");
  const [configText, setConfigText] = useState<string>("");
  const [capacitiesCsvText, setCapacitiesCsvText] = useState<string>("");
  const { isSchoolAdmin } = useSchoolAdmin();
  const { downloadProgress, onDownloadProgress, resetDownloadProgress } =
    useDownloadProgress();

  const { getDownloadUrl } = useDocumentStorage();
  const { remoteData: matchData } = useRemoteDataQuery<
    GQL.GetMatchDetails,
    GQL.GetMatchDetailsVariables
  >(GET_MATCH_DETAILS, {
    variables: { matchId: matchId ?? "" },
    skip: !matchId,
    fetchPolicy: "cache-and-network",
  });

  // Fetch and parse CSV when match data is loaded
  useEffect(() => {
    async function fetchResults() {
      if (!matchData.hasData()) return;
      const documentId = matchData.data.match_run_by_pk?.results_document_id;
      if (!documentId) return;

      setIsLoadingResults(true);
      resetDownloadProgress();
      try {
        const documentUrl = await getDownloadUrl(documentId);
        if (!documentUrl) throw new Error("Failed to get document URL");

        const response = await axios.get(documentUrl, { onDownloadProgress });
        let csvText = response.data as string;

        // For school admins, remove the Rank column from the CSV
        if (isSchoolAdmin) {
          const rows = Papa.parse(csvText, { header: true });
          if (rows.data && rows.data.length > 0) {
            // Remove Rank field from each row
            const filteredData = rows.data.map((row: any) => {
              const { Rank, ...rest } = row;
              return rest;
            });

            // Generate new CSV without Rank column
            csvText = Papa.unparse(filteredData);
          }
        }
        setCsvText(csvText);

        Papa.parse<MatchResultRow>(csvText, {
          header: true,
          complete: (results) => {
            const parsedResults = results.data
              .filter((row) => row["App ID"]) // Filter out empty rows
              .map((row) => ({
                formId: row["App ID"],
                student: {
                  name: `${row["Student First Name"]} ${row["Student Last Name"]}`,
                  id: row["Student ID"],
                },
                school: row["School"],
                placement: row["Lottery Result"],
                grade: row["Grade"],
                lotteryNumber: row["Lottery Number"],
                priorityGroup: row["Priority Group"],
                tags: row["Tags"],
                lotteryPosition: row["Lottery Position"],
                calculatedWaitlistPosition: row["Calculated Waitlist Position"],
              }));

            setMatchResults(parsedResults);
          },
          error: (error: any) => {
            console.error("CSV Parse Error:", error);
            setMatchResults([]);
            setCsvText("");
          },
        });
      } catch (error) {
        console.error("Error fetching results:", error);
        setMatchResults([]);
        setCsvText("");
      } finally {
        setIsLoadingResults(false);
      }
    }

    fetchResults();
  }, [
    matchData,
    getDownloadUrl,
    onDownloadProgress,
    resetDownloadProgress,
    isSchoolAdmin,
  ]);

  // Fetch capacity snapshot when match data is loaded
  useEffect(() => {
    async function fetchCapacities() {
      if (!matchData.hasData()) return;
      const documentId =
        matchData.data.match_run_by_pk?.capacities_snapshot_document_id;
      if (!documentId) return;

      try {
        const documentUrl = await getDownloadUrl(documentId);
        if (!documentUrl)
          throw new Error("Failed to get capacities document URL");

        const response = await fetch(documentUrl);
        const capacitiesCsvText = await response.text();
        setCapacitiesCsvText(capacitiesCsvText);
      } catch (error) {
        console.error("Error fetching capacities:", error);
        setCapacitiesCsvText("");
      }
    }

    fetchCapacities();
  }, [matchData, getDownloadUrl]);

  // Fetch config when match data is loaded
  useEffect(() => {
    async function fetchConfig() {
      if (!matchData.hasData()) return;
      const documentId =
        matchData.data.match_run_by_pk?.match_config_snapshot_document_id;
      if (!documentId) return;

      try {
        const documentUrl = await getDownloadUrl(documentId);
        if (!documentUrl) throw new Error("Failed to get config document URL");

        const response = await fetch(documentUrl);
        const configText = await response.text();
        setConfigText(configText);
      } catch (error) {
        console.error("Error fetching config:", error);
        setConfigText("");
      }
    }

    fetchConfig();
  }, [matchData, getDownloadUrl]);

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      setSortField(headerId);
      setSortDirection(sortType);
    },
    []
  );

  const handleFetchMore = useCallback((limit: number, offset: number) => {
    setPageSize(limit);
    setCurrentPage(Math.floor(offset / limit) + 1);
  }, []);

  const matchName = matchData
    .map((data) => data.match_run_by_pk?.name ?? "Match")
    .withDefault("Match");
  const handleDownload = useCallback(
    async (type: "results" | "capacities" | "rules") => {
      switch (type) {
        case "results":
          if (csvText) {
            const blob = new Blob([csvText], { type: "text/csv" });
            triggerDownload(blob, `${matchName}-results.csv`);
          }
          break;
        case "capacities":
          if (capacitiesCsvText) {
            const blob = new Blob([capacitiesCsvText], { type: "text/csv" });
            triggerDownload(blob, `${matchName}-capacities.csv`);
          }
          break;
        case "rules":
          if (configText) {
            const blob = new Blob([configText], { type: "application/json" });
            triggerDownload(blob, `${matchName}-rules.json`);
          }
          break;
      }
    },
    [csvText, configText, capacitiesCsvText, matchName]
  );

  // Sort and paginate the data
  const sortedAndPaginatedData = useMemo(() => {
    const sortedResults = [...matchResults];

    if (sortField && sortDirection !== "unsorted") {
      sortedResults.sort((a, b) => {
        let aValue: string;
        let bValue: string;

        switch (sortField) {
          case "student":
            aValue = a.student.name.toLowerCase();
            bValue = b.student.name.toLowerCase();
            break;
          case "school":
            aValue = a.school.toLowerCase();
            bValue = b.school.toLowerCase();
            break;
          default:
            return 0;
        }

        if (sortDirection === "asc") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }

    // Paginate the sorted results
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return sortedResults.slice(start, end);
  }, [matchResults, sortField, sortDirection, currentPage, pageSize]);

  return (
    <GQLRemoteDataView remoteData={matchData}>
      {(data) => {
        const matchName = data.match_run_by_pk?.name ?? "Match";
        const runDate = data.match_run_by_pk?.created_at
          ? formatIsoDateToOrg(
              data.match_run_by_pk.created_at,
              organization,
              "MM/dd/yyyy"
            )
          : "";

        const columns: ColumnDef<MatchDetail>[] = [
          {
            id: "student",
            header: "Student",
            accessorKey: "student",
            cell: (info) => {
              const student = info.getValue() as MatchDetail["student"];

              const url = Url.OrgAdmin.Students.edit(organization, student.id);
              return (
                <Box>
                  <Link
                    href={url}
                    color="primary.500"
                    textDecoration="underline"
                  >
                    {student.name}
                  </Link>
                  <Text fontSize="sm" color="gray.500">
                    {student.id}
                  </Text>
                </Box>
              );
            },
          },
          {
            id: "placement",
            header: "Placement",
            accessorKey: "placement",
            cell: (info) => {
              const placement =
                info.getValue() as keyof typeof placementStatuses;
              return <StatusTag status={placementStatuses[placement]!} />;
            },
          },
          {
            id: "school",
            header: "School",
            accessorKey: "school",
            cell: (info) => (
              <Text fontSize="sm">{info.getValue() as string}</Text>
            ),
          },
          {
            id: "grade",
            header: "Grade",
            accessorKey: "grade",
          },
          {
            id: "priorityGroup",
            header: "Priority Group",
            accessorKey: "priorityGroup",
          },
          {
            id: "lotteryNumber",
            header: "Lottery Number",
            accessorKey: "lotteryNumber",
          },
          {
            id: "lotteryPosition",
            header: "Lottery Position",
            accessorKey: "lotteryPosition",
          },
          {
            id: "calculatedWaitlistPosition",
            header: "Calculated Waitlist Position",
            accessorKey: "calculatedWaitlistPosition",
          },
          {
            id: "tags",
            header: "Tags",
            accessorKey: "tags",
            cell: (info) => {
              const tags = info.row.original.tags.split(",");
              if (tags.length > 1) {
                return (
                  <Tooltip hasArrow label={info.row.original.tags}>
                    <HStack tabIndex={0} width="fit-content">
                      <Tag variant="tag">
                        <Text
                          overflow="hidden"
                          textOverflow="ellipsis"
                          maxWidth="300px"
                        >
                          {tags[0]}
                        </Text>
                      </Tag>
                      <Tag variant="tag">+{tags.length - 1}</Tag>
                    </HStack>
                  </Tooltip>
                );
              }
              if (tags[0]) {
                return (
                  <Tag variant="tag">
                    <TextWithOverflowTooltip
                      maxWidth="300px"
                      content={tags[0] ?? ""}
                      hasArrow
                    />
                  </Tag>
                );
              }
              return <></>;
            },
          },
          {
            id: "formId",
            header: "Form ID",
            accessorKey: "formId",
            cell: (info) => (
              <Text fontSize="sm" color="gray.600">
                {info.getValue() as string}
              </Text>
            ),
          },
        ];

        return (
          <VStack align="stretch" spacing={6} width="100%" height="80%">
            <Breadcrumb
              items={breadcrumb.match.getBreadcrumbsForDetails(
                organization,
                matchId ?? ""
              )}
            />
            <HStack justify="space-between" align="flex-start" width="100%">
              <VStack align="flex-start" spacing={1}>
                <Text color="gray.600">Results for</Text>
                <Heading as="h1" size="lg" textAlign="left">
                  {matchName}
                </Heading>
                <Text color="gray.500" fontSize="md">
                  Run on {runDate}
                </Text>
              </VStack>

              <HStack spacing={2}>
                <Menu>
                  <MenuButton
                    as={Button}
                    leftIcon={<FiDownload />}
                    rightIcon={<FiChevronDown />}
                    colorScheme="gray"
                    isDisabled={!csvText && !capacitiesCsvText && !configText}
                  >
                    Download
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => handleDownload("results")}
                      isDisabled={!csvText}
                    >
                      Results as .CSV
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleDownload("capacities")}
                      isDisabled={!capacitiesCsvText}
                    >
                      Capacities as .CSV
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleDownload("rules")}
                      isDisabled={!configText}
                    >
                      Rules as .JSON
                    </MenuItem>
                  </MenuList>
                </Menu>

                <ShowPublicViewButton id={matchId ?? ""} />
              </HStack>
            </HStack>

            {matchData.isLoading() || isLoadingResults ? (
              <Center h="100%">
                <Flex
                  direction="column"
                  alignItems="center"
                  gap={2}
                  width="100%"
                >
                  <Text>Downloading results...</Text>
                  <ProgressMeter
                    value={downloadProgress * 100}
                    size="lg"
                    maxWidth="400px"
                  />
                </Flex>
              </Center>
            ) : (
              <PaginatedTable<MatchDetail>
                data={sortedAndPaginatedData}
                columns={columns}
                isLoading={false}
                sortableColumnIds={["student", "school"]}
                onChangeSort={handleChangeSort}
                count={matchResults.length}
                offset={(currentPage - 1) * pageSize}
                limit={pageSize}
                onFetchMore={handleFetchMore}
              />
            )}
          </VStack>
        );
      }}
    </GQLRemoteDataView>
  );
};
