import { Flex } from "@chakra-ui/react";
import React from "react";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { ExploreList } from "../ExploreList";

type Props = { organization: { id: string; path: string } };
export const LicensesForm: React.FC<Props> = ({ organization }) => {
  return (
    <Flex direction="column" gap={4}>
      <SwitchInput<true | undefined>
        label="Match"
        name={`organizationConfigs.Match.disabled`}
        checked={[undefined, ""]}
        unchecked={[true, ""]}
        direction="row"
        formLabelProps={{ fontSize: "md", alignItems: "center" }}
      />
      <ExploreList organization={organization} />
    </Flex>
  );
};
