import { useCallback, useEffect, useState } from "react";
import { getVariables, Variable } from "src/types/messages/variables";
import { useSettings } from "../context/SettingsContext";

export function useVariables() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    settings: { enableVariables, variablesContext },
  } = useSettings();

  const [variables, setVariables] = useState<Variable[]>([]);

  useEffect(() => {
    setVariables(enableVariables ? getVariables(variablesContext) : []);
    setIsLoading(false);
  }, [enableVariables, variablesContext]);

  const search = useCallback(
    (string: string, callback: (results: Array<Variable>) => void): void => {
      setTimeout(() => {
        const results =
          string.length === 0
            ? variables
            : variables.filter((variable) =>
                variable.key.toLowerCase().includes(string.toLowerCase())
              );
        callback(results);
      }, 500);
    },
    [variables]
  );

  const isValid = useCallback(
    (key: string): boolean => {
      return variables.some(
        (variable) => variable.key === key || `{{${variable.key}}}` === key
      );
    },
    [variables]
  );

  return { search, isValid, isLoading };
}
