import { BreadcrumbData } from "..";
import * as Url from "../../url";
import * as RD from "src/types/remoteData";

export const getBreadcrumbsForList = (): BreadcrumbData[] => {
  return [
    {
      label: "Organizations",
      href: Url.Admin.Organizations.index(),
    },
  ];
};

export const getBreadcrumbsForEdit = (
  organizationData: RD.RemoteData<unknown, { id: string; name: string }>
): BreadcrumbData[] => {
  return [
    ...getBreadcrumbsForList(),
    organizationData.map((data) => ({
      label: `Edit ${data.name}`,
      href: Url.Admin.Organizations.edit(data.id),
    })),
  ];
};

export const getBreadcrumbsForEditExplore = (
  organizationData: RD.RemoteData<unknown, { id: string; name: string }>,
  exploreId: string
): BreadcrumbData[] => {
  return [
    ...getBreadcrumbsForEdit(organizationData),
    organizationData.map((data) => ({
      label: `Edit ${exploreId}`,
      href: Url.Admin.Organizations.editExplore(data.id, exploreId),
    })),
  ];
};
