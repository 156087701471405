import { Route } from "react-router-dom";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { EditPolicy } from "src/scenes/admin/organizations/EditPolicy";
import { Organizations } from "src/services/url/Admin";
import { Index } from ".";
import { Edit } from "./Edit";
import { New } from "./New";
import { EditExplore } from "./EditExplore";

export const AdminOrganizationsRoutes = (
  <Route
    path={Organizations.indexPath}
    element={
      <PreserveState>
        <RequireUserPermissions permission="navigation.admin.organizations">
          <Index />
        </RequireUserPermissions>
      </PreserveState>
    }
  >
    <Route
      path={Organizations.newPath}
      element={
        <RequireUserPermissions permission="organization:create">
          <New />
        </RequireUserPermissions>
      }
    />
    <Route
      path={Organizations.editPath}
      element={
        <RequireUserPermissions permission="organization:update">
          <Edit />
        </RequireUserPermissions>
      }
    />

    <Route
      path={Organizations.editPolicyPath}
      element={
        <RequireUserPermissions permission="organization:update">
          <EditPolicy />
        </RequireUserPermissions>
      }
    />

    <Route
      path={Organizations.editExplorePath}
      element={
        <RequireUserPermissions permission="organization:update">
          <EditExplore />
        </RequireUserPermissions>
      }
    />
  </Route>
);
