import { html } from "src/components/HtmlViewer/utils";
import * as GQL from "src/types/graphql";

export const WAITLISTED = {
  type: GQL.message_template_type_enum.Waitlisted,
  emailSubject:
    "You are on the waitlist at {{organization.name}}/Estás en la lista de espera de {{organization.name}}",
  emailHtml: html`<p>
    Dear {{parent.name}},<br />
    <br />
    {{student.name}} is on the waitlist {{organization.name}} at
    {{waitlist.schoolName}} for grade {{grade}} for
    {{enrollmentPeriod.name}}!<br />
    <br />
    Please log in to review your waitlist position {{applyUrl}}<br />
    If you need help, please contact {{supportEmail}}.<br />
    <br />
    -{{organization.name}} Team<br />
    —---<br />
    <br />
    Estimado/a {{parent.name}},<br />
    <br />
    {{student.name}} está en la lista de espera de {{organization.name}} en
    {{waitlist.schoolName}} para el grado {{grade}} para
    {{enrollmentPeriod.name}}.<br />
    <br />
    Por favor, inicie sesión para revisar su posición en la lista de espera
    {{applyUrl}}<br />
    <br />
    -Equipo de {{organization.name}}<br />
  </p>`,
  emailText: `Dear {{parent.name}},
{{student.name}} is on the waitlist {{organization.name}} at
{{waitlist.schoolName}} for grade {{grade}} for {{enrollmentPeriod.name}}!
Please log in to review your waitlist position {{{applyUrl}}}
If you need help, please contact {{supportEmail}}.
-{{organization.name}} Team

——-

Estimado/a  {{parent.name}},
{{student.name}} está en la lista de espera de {{organization.name}} en
{{waitlist.schoolName}} para el grado {{grade}} para {{enrollmentPeriod.name}}.
Por favor, inicie sesión para revisar su posición en la lista de espera {{{applyUrl}}}
Si necesita ayuda, por favor contacte a {{supportEmail}}.
-Equipo de {{organization.name}}`,
  sms: "{{student.name}} is on the waitlist at {{organization.name}}. Log in to review it / {{student.name}} está en la lista de espera de {{organization.name}}. Inicie sesión para revisarlo"
};
