import { z } from "zod";
import { MESSAGE_CONTENT_LIMIT } from "src/constants";
import { isEmptyLexicalState } from "src/components/WysiwygEditor/lexical/utils/converters";
export const generateMessageSchema = (
  hasRecipientsWithEmail: boolean,
  hasRecipientsWithPhone: boolean,
  maxSmsLength: number = MESSAGE_CONTENT_LIMIT
) =>
  z.object({
    subject: z.string().superRefine((val, ctx) => {
      if (hasRecipientsWithEmail && !val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Email subject is required when at least one of your recipients has an email contact method.`,
        });
      }
    }),
    email_body: z.string().superRefine((val, ctx) => {
      if (hasRecipientsWithEmail && isEmptyLexicalState(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Email content is required when one of your recipients has an email contact method.`,
        });
      }
    }),
    sms_body: z.string().superRefine((val, ctx) => {
      if (val.length > maxSmsLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Limit reached ${maxSmsLength - val.length}`,
        });
      }
      if (hasRecipientsWithPhone && !val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `SMS content is required when one of your recipients has an SMS contact method.`,
        });
      }
    }),
  });
