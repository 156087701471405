import { z } from "zod";

export const LanguageSchema = z.enum([
  "en",
  "af",
  "sq",
  "am",
  "ar",
  "hy",
  "az",
  "eu",
  "be",
  "bn",
  "bs",
  "bg",
  "ca",
  "ceb",
  "zh-CN",
  "zh-TW",
  "co",
  "hr",
  "cs",
  "da",
  "nl",
  "eo",
  "et",
  "fi",
  "fr",
  "fy",
  "gl",
  "ka",
  "de",
  "el",
  "gu",
  "ht",
  "ha",
  "haw",
  "iw",
  "hi",
  "hmn",
  "hu",
  "is",
  "ig",
  "id",
  "ga",
  "it",
  "ja",
  "jv",
  "kn",
  "kk",
  "km",
  "rw",
  "ko",
  "ku",
  "ky",
  "lo",
  "lv",
  "lt",
  "lb",
  "mk",
  "mg",
  "ms",
  "ml",
  "mt",
  "mi",
  "mr",
  "mn",
  "my",
  "ne",
  "no",
  "ny",
  "or",
  "ps",
  "fa",
  "pl",
  "pt",
  "pa",
  "ro",
  "ru",
  "sm",
  "gd",
  "sr",
  "st",
  "sn",
  "sd",
  "si",
  "sk",
  "sl",
  "so",
  "es",
  "su",
  "sw",
  "sv",
  "tl",
  "tg",
  "ta",
  "tt",
  "te",
  "th",
  "tr",
  "tk",
  "uk",
  "ur",
  "ug",
  "uz",
  "vi",
  "cy",
  "xh",
  "yi",
  "yo",
  "zu",
]);

export type Language = z.infer<typeof LanguageSchema>;

const BoundaryStyleSchema = z.object({
  color: z.string(),
});

const BoundaryConfigSchema = z.object({
  boundaryType: z.string(),
  idProperty: z.string(),
  showAllBoundaries: z.boolean(),
  showAddressBoundary: z.boolean(),
  showSelectedPlacementBoundary: z.boolean(),
  tagLabel: z.string().optional(),
  showMapLabel: z.boolean().optional(),
  style: z.record(z.string(), BoundaryStyleSchema).optional(),
});

const BoundaryValueSchema = z.object({
  url: z.string(),
  config: BoundaryConfigSchema,
});

const BoundarySchema = z.object({
  sort: z.boolean().optional(),
  sortText: z.string().optional(),
  values: z.array(BoundaryValueSchema),
});

const HslSchema = z.object({
  hue: z.string(),
  saturation: z.string(),
  lightness: z.string(),
});

const VariableWordsSchema = z.object({
  school: z.object({
    singular: z.object({
      label: z.string(),
    }),
    plural: z.object({
      label: z.string(),
    }),
  }),
  checkEligibility: z
    .object({
      label: z.string(),
    })
    .optional(),
});

const ConfigurableLinkSchema = z.object({
  label: z.string(),
  href: z.string(),
  opensNewTab: z.boolean().optional(),
});

const NavigationSectionSchema = z.object({
  links: z.array(ConfigurableLinkSchema),
});

const BaseStaticSchema = z.object({
  contentType: z.literal("static"),
  label: z.string().optional(),
  value: z.string(),
  helpText: z.string().optional(),
});

const BaseFieldSchema = z.object({
  contentType: z.literal("field").optional(),
  fieldName: z.string(),
  label: z.string().optional(),
  helpText: z.string().optional(),
  displayTransformation: z.literal("range").optional(),
});

const BaseLinkSchema = z.object({
  type: z.literal("website"),
  opensNewTab: z.boolean().optional(),
  helpText: z.string().optional(),
});

const ContentItemLinkSchema = z.union([
  BaseFieldSchema.merge(BaseLinkSchema),
  BaseStaticSchema.merge(BaseLinkSchema),
]);

const ContentItemSchema = z.union([
  ContentItemLinkSchema,
  BaseStaticSchema,
  BaseFieldSchema,
]);

const KeyInfoTypesSchema = z.enum([
  "location",
  "contact",
  "principal",
  "hours",
  "bus",
  "group",
  "paper",
  "building",
  "other",
  "website",
  "award",
  "pushpin",
]);

const BaseKeyInfoSchema = z.object({
  type: KeyInfoTypesSchema,
});

const KeyInfoSchema = z.union([
  BaseFieldSchema.merge(BaseKeyInfoSchema),
  BaseStaticSchema.merge(BaseKeyInfoSchema),
  ContentItemLinkSchema,
]);

const TabSchema = z.object({
  label: z.string(),
  items: z.array(ContentItemSchema),
});

const ProfileSectionSchema = z.object({
  highlighted: z.array(ContentItemSchema),
  keyInfo: z.array(KeyInfoSchema),
  tabs: z.array(TabSchema),
});

const SavedListSectionSchema = z.object({
  disclaimer: z
    .object({
      hoverableText: z.string(),
      header: z.string(),
      content: z.string(),
    })
    .optional(),
  meter: z
    .object({
      fieldName: z.string(),
      defaultAcceptanceRate: z.number(),
      minimumDesired: z.number(),
      individualScalingFactor: z.number(),
      hoverableStates: z.object({
        1: BaseStaticSchema,
        2: BaseStaticSchema,
        3: BaseStaticSchema,
        4: BaseStaticSchema,
        5: BaseStaticSchema,
      }),
    })
    .optional(),
  applyNowButton: ConfigurableLinkSchema.extend({
    forwardSavedSchools: z.boolean().optional(),
  }),
});

const ListSectionSchema = z.object({
  highlighted: z.array(ContentItemSchema),
  disclaimer: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .optional(),
});

const MapSectionSchema = z.object({
  popover: z.object({
    highlighted: z.array(ContentItemSchema),
  }),
  listPopover: z.object({
    highlighted: z.array(ContentItemSchema),
  }),
  miniPlacementCard: z.object({
    highlighted: z.array(ContentItemSchema),
  }),
});

const BaseFilterSchema = z.object({
  label: z.string(),
  fieldName: z.string(),
});

const MultipleFilterSchema = BaseFilterSchema.extend({
  type: z.literal("multiple"),
  options: z.array(z.string()),
});

const DistanceFilterSchema = BaseFilterSchema.extend({
  type: z.literal("distance"),
  fieldName: z.literal("distance"),
  maxMiles: z.number(),
  step: z.number().optional(),
});

const FilterSchema = z.union([MultipleFilterSchema, DistanceFilterSchema]);

const PlacementMappingSchema = z.object({
  idFieldName: z.string(),
  nameFieldName: z.string(),
  latFieldName: z.string(),
  lngFieldName: z.string(),
  boundaryFieldName: z.record(z.string(), z.string()).optional(),
});

const MessagingSchema = z.object({
  sms: z.object({
    from: z.string(),
    body: z.string(),
    applyNow: z.string(),
  }),
  email: z.object({
    from: z.string(),
    subject: z.string(),
    body: z.string(),
    applyNow: z.string(),
  }),
});

const PlacementEligibilitySchema = z.object({
  fieldName: z.string(),
  disabledValue: z.string(),
});

const EligibilityConfigSchema = z.object({
  isEnabled: z.boolean(),
  isMandatory: z.boolean(),
  learnMoreUrl: z.string(),
  placementEligibility: PlacementEligibilitySchema,
  welcomeModal: z.object({
    acknowledgementEnabled: z.boolean(),
    titleText: z.string(),
    bodyText: z.string(),
    acknowledgementText: z.string(),
    cancelButtonText: z.string(),
    continueButtonText: z.string(),
  }),
  error: z.object({
    header: z.string(),
    body: z.string(),
  }),
});

const TranslationsSchema = z.object({
  savedFirstSchool: z.object({
    header: z.string(),
    body: z.string(),
    closeButton: z.object({ label: z.string() }),
  }),
  forms: z.object({
    dropdown: z.object({
      helpText: z.string(),
    }),
    address: z.object({
      addressLine1: z.object({ label: z.string(), helpText: z.string() }),
      addressLine2: z.object({ label: z.string(), helpText: z.string() }),
      zipCode: z.object({ label: z.string(), helpText: z.string() }),
      city: z.object({ label: z.string(), helpText: z.string() }),
      state: z.object({ label: z.string(), helpText: z.string() }),
    }),
  }),
  eligibility: z.object({
    filter: z.object({
      header: z.string(),
      body: z.string(),
    }),
    error: z.object({
      header: z.string(),
      body: z.string(),
    }),
    notEligibleForAny: z.object({
      header: z.string(),
      body: z.string(),
    }),
    learnMoreUrl: z.object({ label: z.string() }),
    alert: z.object({
      avlBtn: z.object({ label: z.string() }),
      label: z.string(),
    }),
    nudge: z.object({
      avlBtn: z.object({ label: z.string() }),
      label: z.string(),
    }),
    stepOne: z.object({
      body: z.string(),
      enrollmentPeriod: z.object({ label: z.string() }),
      formTemplate: z.object({ label: z.string() }),
      nextButton: z.object({ label: z.string() }),
      cancelButton: z.object({ label: z.string() }),
    }),
    stepTwo: z.object({
      body: z.string(),
      noEligibleCancelButton: z.object({ label: z.string() }),
      noEligibleNextButton: z.object({ label: z.string() }),
      nextButton: z.object({ label: z.string() }),
      cancelButton: z.object({ label: z.string() }),
    }),
    eligibilityModal: z.object({
      header: z.string(),
      empty: z.object({ label: z.string() }),
      loadingMessage: z.object({ label: z.string() }),
    }),
    welcomeModal: z.object({
      header: z.string(),
      changeEligibility: z.object({ label: z.string() }),
      body: z.string(),
      acknowledgement: z.object({ label: z.string() }),
      cancelButton: z.object({ label: z.string() }),
      continueButton: z.object({ label: z.string() }),
    }),
  }),
});

export const UIConfigSchema = z.object({
  maxNumRankable: z.number(),
  languages: z.array(LanguageSchema).optional(),
  boundary: BoundarySchema.optional(),
  primaryColor: HslSchema.optional(),
  secondaryColor: HslSchema.optional(),
  logo: ConfigurableLinkSchema.extend({
    imageUrl: z.string(),
  }),
  variableWords: VariableWordsSchema,
  navigation: NavigationSectionSchema,
  profile: ProfileSectionSchema,
  savedList: SavedListSectionSchema.optional(),
  list: ListSectionSchema,
  map: MapSectionSchema,
  filters: z.array(FilterSchema),
  placement: PlacementMappingSchema,
  messaging: MessagingSchema,
  analytics: z
    .object({
      googleMeasurementId: z.string().optional(),
    })
    .optional(),
  doNotTranslate: z.array(z.string()).optional(),
  eligibility: EligibilityConfigSchema.optional(),
  translations: TranslationsSchema.optional(),
});

export type UIConfig = z.infer<typeof UIConfigSchema>;
