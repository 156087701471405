import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Form, Formik, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { AvelaSelectField } from "src/components/Inputs/SelectField";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import { FORM_STATUS } from "src/constants";
import { ALL_SECTION_TYPES } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/Content/services";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import { validateWithZod } from "src/services/formValidations";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { z } from "zod";

const z_number = z.coerce
  .number({
    errorMap: (issue, { defaultError }) => ({
      message:
        issue.code === "invalid_type"
          ? "Must contain only numbers."
          : defaultError,
    }),
  })
  .optional()
  .refine((value) => value === null || value === undefined || value >= 0, {
    message: "Must be a number greater than or equal to 0",
  });

type FamilyEditableStatusType = {
  id: keyof typeof FORM_STATUS;
  label: (typeof FORM_STATUS)[keyof typeof FORM_STATUS]["label"];
};

const FAMILY_EDITABLE_STATUSES = Object.entries(FORM_STATUS).map(
  ([key, status]) => ({
    id: key,
    label: status.label,
  })
) as FamilyEditableStatusType[];

const SectionFormSchema = z.object({
  id: z.string(),
  title: z.string().trim().min(1, "Section title is required"),
  description: z.string().optional(),
  key: z.string().nullable(),
  type: z.nativeEnum(GQL.form_template_section_type_enum),
  permissionLevel: z.string().nullable(),
  minSchools: z_number,
  maxSchools: z_number,
  exploreUrl: z.string().optional(),
  rankingEnabled: z.boolean().optional().default(true),
  familyEditableStatuses: z
    .array(
      z.object({
        id: z.nativeEnum(GQL.form_status_enum),
        label: z.string(),
      })
    )
    .nullable(),
});

export type SectionFormType = z.infer<typeof SectionFormSchema>;
type EditProps = {
  isOpen: boolean;
  section?:
    | Draft.Section
    | Draft.NewSection
    | Draft.Disclaimer
    | Draft.SchoolRanking
    | Draft.NewSchoolRanking;
  onCancelButtonClick: () => void;
  onCreate: (section: SectionFormType) => void;
  applicableSectionTypes?: string[];
};

const FamilyEditableStatusesSection: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<SectionFormType>();

  const renderFamilyEditableStatusDropdown = useCallback(
    (item: FamilyEditableStatusType) => {
      return <Box>{item.label}</Box>;
    },
    []
  );

  return (
    <Flex alignItems="start" mt="0.5rem" mb="1.5rem">
      <Flex direction="column">
        <Text>Family edit by status</Text>
        <Text fontSize="xs" color="gray.600" fontWeight="400">
          Allow Families to edit only during certain statuses. Leave it blank to
          fully prevent editing.
        </Text>
        <FormControl id="familyEditableStatuses" w="100%" mt={2}>
          <FormLabel htmlFor="familyEditableStatuses" mb={0}>
            Can edit during
          </FormLabel>
          <MultiSelectInput<FamilyEditableStatusType>
            size="md"
            options={FAMILY_EDITABLE_STATUSES}
            initialValues={values.familyEditableStatuses ?? []}
            onChange={(selectedStatuses) => {
              setFieldValue("familyEditableStatuses", selectedStatuses);
            }}
            renderDropdownItem={renderFamilyEditableStatusDropdown}
          />
          {values.type ===
            GQL.form_template_section_type_enum.SchoolRankingSection && (
            <Text fontSize="sm" color="gray.600" fontWeight="400" mt={2}>
              The school ranking section also enables editing school specific
              questions in any following section.
            </Text>
          )}
        </FormControl>
      </Flex>
    </Flex>
  );
};

export const SectionFormDialog: React.FC<EditProps> = ({
  isOpen,
  section,
  onCancelButtonClick,
  onCreate,
  applicableSectionTypes,
}) => {
  const schoolSection =
    section?.type === GQL.form_template_section_type_enum.SchoolRankingSection
      ? (section as Draft.SchoolRanking)
      : null;
  const initialValues: SectionFormType = {
    id: section?.id ?? crypto.randomUUID(),
    type: (section?.type ??
      applicableSectionTypes?.at(0)) as GQL.form_template_section_type_enum,
    title: section?.title ?? "",
    description: section?.description ?? "",
    key: section?.key ?? "",
    permissionLevel: section?.permissionLevel ?? "",
    minSchools: schoolSection?.minSchools,
    maxSchools: schoolSection?.maxSchools,
    exploreUrl: schoolSection?.exploreUrl ?? "",
    rankingEnabled: schoolSection?.rankingEnabled ?? true,
    familyEditableStatuses:
      section?.familyEditableStatuses?.map((status) => ({
        id: status,
        label:
          FAMILY_EDITABLE_STATUSES.find((item) => item.id === status)?.label ??
          "",
      })) ?? [],
  };

  const validateFormikValues = async (values: SectionFormType) => {
    const errors = await validateWithZod(SectionFormSchema)(values);

    if (errors) return errors;

    const { minSchools, maxSchools } = values;

    if (minSchools && maxSchools && Number(minSchools) > Number(maxSchools))
      return {
        minSchools: "Min schools must not exceed max schools.",
        maxSchools: "Max schools must not be less than min schools.",
      };
  };

  const sectionTypeLabels = {
    DisclaimerSection: "Disclaimer",
    GeneralSection: "General",
    PreRankingSection: "Pre-ranking",
    SchoolRankingSection: "School ranking",
  };

  const sectionTypes = applicableSectionTypes ?? ALL_SECTION_TYPES;

  return (
    <Formik<SectionFormType>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onCreate}
      validate={validateFormikValues}
    >
      {(formik) => {
        const onCancel = () => {
          formik.handleReset();
          onCancelButtonClick();
        };

        return (
          <Modal
            isOpen={isOpen}
            onClose={onCancel}
            scrollBehavior="inside"
            isCentered={true}
          >
            <ModalOverlay />
            <ModalContent as={Form} maxH="60vh" minH="20rem">
              <ModalHeader>
                {section ? "Edit section" : "Add section"}
              </ModalHeader>
              <ModalBody as={Flex} direction="column" gap={2}>
                <FormControl>
                  <FormLabel>Section title</FormLabel>
                  <InputWithValidationControl
                    id="title"
                    name="title"
                    isRequired={false}
                  />
                </FormControl>
                <FormControl>
                  <AvelaSelectField
                    label="Section category"
                    name="type"
                    isDisabled={!!section}
                    options={sectionTypes.map((value) => ({
                      label: sectionTypeLabels[value as AF.SectionType],
                      value: value,
                    }))}
                    marginBottom="1.5rem"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Section description</FormLabel>
                  <TextareaWithValidationControl
                    id="description"
                    name="description"
                    marginBottom="1.5rem"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Section key (optional)</FormLabel>
                  <InputWithValidationControl id="key" name="key" />
                </FormControl>

                {formik.values.type ===
                  GQL.form_template_section_type_enum.SchoolRankingSection && (
                  <>
                    <FormControl>
                      <FormLabel>Explore Link URL</FormLabel>
                      <InputWithValidationControl
                        id="exploreUrl"
                        name="exploreUrl"
                        inputProps={{
                          placeholder:
                            "E.g.: https://springfield.explore.avela.org",
                        }}
                      />
                    </FormControl>
                    <HStack>
                      <FormControl>
                        <FormLabel>Min. schools (optional)</FormLabel>
                        <InputWithValidationControl
                          id="minSchools"
                          name="minSchools"
                          inputProps={{ placeholder: "E.g.: 3" }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Max. schools (optional)</FormLabel>
                        <InputWithValidationControl
                          id="maxSchools"
                          name="maxSchools"
                          inputProps={{ placeholder: "E.g.: 7" }}
                        />
                      </FormControl>
                    </HStack>
                  </>
                )}

                <Flex alignItems="start" mt="0.5rem" mb="1.5rem">
                  <SwitchInput<string | null>
                    label=""
                    name="permissionLevel"
                    checked={["admin", ""]}
                    unchecked={[null, ""]}
                    containerProps={{ direction: "row", gap: "0.5rem" }}
                  />
                  <Flex direction="column">
                    <Text>Internal-only</Text>
                    <Text fontSize="xs" color="gray.600" fontWeight="400">
                      When enabled, it will not be displayed to parents.
                    </Text>
                  </Flex>
                </Flex>

                {formik.values.type ===
                  GQL.form_template_section_type_enum.SchoolRankingSection && (
                  <>
                    <Flex alignItems="start" mb="1.5rem">
                      <SwitchInput<boolean | null>
                        label=""
                        name="rankingEnabled"
                        checked={[true, ""]}
                        unchecked={[false, ""]}
                        containerProps={{ direction: "row", gap: "0.5rem" }}
                      />
                      <Flex direction="column">
                        <Text>Ordered ranking</Text>
                        <Text fontSize="xs" color="gray.600" fontWeight="400">
                          When enabled, choices will have an order of preference
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                )}

                <Divider />
                <FamilyEditableStatusesSection />
              </ModalBody>
              <ModalFooter gap="1.0rem">
                <Button variant="ghost" colorScheme="gray" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit">
                  {section ? "Update" : "Add"} section
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
