import { Button, Flex, Icon, Radio } from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine, RiEdit2Line } from "react-icons/ri";
import { NoAnswerText } from "src/components/Form/QuestionAnswer";
import { DataLabel } from "src/components/Inputs/DataLabel";
import { Card } from "src/components/Layout/Card";
import { useCustomQuestionContext } from "../context/CustomQuestionContext";
import { AnswerBankRecord } from "./schemas/AnswerBank";
import { AnswerBankRecordField } from "./schemas/AnswerBankField";
import { formatPhoneNumber } from "react-phone-number-input";
import * as amplitude from "@amplitude/analytics-browser";

const MAX_DEFAULT_FIELDS_TO_DISPLAY = 2;

type AnswerBankRadioProps = {
  answerBankRecord: AnswerBankRecord;
  selected: boolean;
};

export const AnswerBankRadio: FunctionComponent<AnswerBankRadioProps> = (
  props
) => {
  const { answerBankRecord, selected } = props;
  const { fields, answerBankId } = answerBankRecord;
  const [showAllFields, setShowAllFields] = useState(false);

  const fieldsExceedDisplayLimit =
    fields.length > MAX_DEFAULT_FIELDS_TO_DISPLAY;

  const fieldsToDisplay = fields.slice(0, MAX_DEFAULT_FIELDS_TO_DISPLAY);
  const outstandingFields = fields.slice(MAX_DEFAULT_FIELDS_TO_DISPLAY);

  const buttonText = showAllFields ? "View less" : "View more";

  const { setShowAnswerBank, setAnswerToEdit, customQuestion } =
    useCustomQuestionContext();

  const cardSelectedStyle = selected
    ? { background: "primary.50", borderColor: "primary.500" }
    : {};

  return (
    <Card showBorder padding={4} {...cardSelectedStyle}>
      <Radio
        value={answerBankId}
        display="flex"
        isInvalid={false} // disable red ring
      >
        <Flex direction="column" gap={1}>
          {fieldsToDisplay.map((field) => {
            return <RadioOptionText key={field.clonedQuestionId} {...field} />;
          })}
          {showAllFields &&
            outstandingFields.map((field) => {
              return (
                <RadioOptionText key={field.clonedQuestionId} {...field} />
              );
            })}
          {fieldsExceedDisplayLimit && (
            <Flex>
              <Button
                leftIcon={
                  <Icon
                    as={showAllFields ? RiArrowUpSLine : RiArrowDownSLine}
                  />
                }
                variant="ghost"
                size="xs"
                color="gray.700"
                onClick={() => {
                  if (!showAllFields) {
                    amplitude.track("View more clicked", {
                      cqt_name: customQuestion.question,
                    });
                  }
                  setShowAllFields(!showAllFields);
                }}
                padding={0}
              >
                {buttonText}
              </Button>
            </Flex>
          )}
        </Flex>
      </Radio>
      <Button
        colorScheme="gray"
        leftIcon={<RiEdit2Line />}
        size="sm"
        variant="outline"
        background="white"
        width="100%"
        onClick={() => {
          amplitude.track("Edit entry clicked", {
            cqt_name: customQuestion.question,
          });
          setAnswerToEdit(answerBankRecord);
          setShowAnswerBank(false);
        }}
        mt={4}
      >
        Edit
      </Button>
    </Card>
  );
};

const RadioOptionText: FunctionComponent<AnswerBankRecordField> = (props) => {
  const { questionLabel, questionType } = props;
  const labelProps = { color: "blackAlpha.700" };
  let answer;
  switch (questionType) {
    case "FreeText":
    case "Email":
    case "Date":
    case "Number":
      // TODO: handle date and number type
      answer = props.answer;
      break;
    case "PhoneNumber":
      answer = props.answer ? formatPhoneNumber(props.answer) : "";
      break;
    case "SingleSelect":
      answer = props.clonedQuestionSelectOptionLabel;
      break;
    default: {
      const _exhaustiveCheck: never = questionType;
      return _exhaustiveCheck;
    }
  }

  return (
    <Flex direction="column">
      <DataLabel {...labelProps}>{questionLabel}</DataLabel>
      {answer && answer.length > 0 ? answer : <NoAnswerText />}
    </Flex>
  );
};
