import { html } from "src/components/HtmlViewer/utils";
import * as GQL from "src/types/graphql";

export const OFFER_DECLINED = {
  type: GQL.message_template_type_enum.OfferDeclined,
  emailSubject:
    "You have declined an offer from {{organization.name}}/Ha declinado una oferta de {{organization.name}}",
  emailHtml: html`<p>
    Dear {{parent.name}},<br />
    <br />
    You have declined an offer from {{organization.name}} for {{student.name}},
    for grade {{grade}} for {{enrollmentPeriod.name}}.<br />
    <br />
    If this was a mistake, please {{supportEmail}}. You can review the status of
    {{student.name}}’s form anytime: {{applyUrl}}<br />
    <br />
    -{{organization.name}} Team<br />
    <br />
    —---<br />
    <br />
    Estimado/a {{parent.name}},<br />
    <br />
    Ha declinado una oferta de {{organization.name}} para {{student.name}}, para
    el grado {{grade}} para {{enrollmentPeriod.name}}<br />.
    <br />
    Si esto fue un error, por favor contacte a {{supportEmail}}. Puede revisar
    el estado de la aplicacion de {{student.name}} en cualquier momento:
    {{applyUrl}}<br />
    <br />
    -Equipo de {{organization.name}}<br />
  </p>`,
  emailText: `Dear {{parent.name}},

You have declined an offer from {{organization.name}} for {{student.name}}, for grade {{grade}} for {{enrollmentPeriod.name}}.

If this was a mistake, please {{supportEmail}}. You can review the status of {{student.name}}’s form anytime: {{{applyUrl}}}

-{{organization.name}} Team

—---

Estimado/a {{parent.name}},

Ha declinado una oferta de {{organization.name}} para {{student.name}}, para el grado {{grade}} para {{enrollmentPeriod.name}}.

Si esto fue un error, por favor contacte a {{supportEmail}}. Puede revisar el estado de la aplicacion de {{student.name}} en cualquier momento: {{{applyUrl}}}

-Equipo de {{organization.name}}`,
  sms: "You have declined an offer from {{organization.name}}. Ha declinado una oferta de {{organization.name}}"
};
