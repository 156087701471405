import { VariablesContext } from "src/types/messages/variables";

export type Settings = {
  enableFontFamily: boolean;
  enableFontSize: boolean;
  hasLinkAttributes: boolean;
  enableVariables: boolean;
  variablesContext?: VariablesContext;
};

export type SettingName = keyof Settings;

export const DEFAULT_SETTINGS: Settings = {
  enableFontFamily: false,
  enableFontSize: false,
  hasLinkAttributes: false,
  enableVariables: false,
} as const;

// These are mutated in setupEnv
export const INITIAL_SETTINGS: Settings = {
  ...DEFAULT_SETTINGS,
};
