import { Button, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { FunctionComponent, useMemo } from "react";
import { CustomQuestionAnswersByQuestionId } from "src/components/Form/QuestionForm/formik";
import { answerIsEmpty } from "src/services/formTemplate/answer";
import { formatNestedQuestionAsProps } from "src/services/formTemplate/customQuestion";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import { isNotNull } from "src/services/predicates";
import { ClonedQuestion, WithId } from "src/types/formTemplate";
import { useCustomQuestionContext } from "../context/CustomQuestionContext";
import { AnswerBankEntryFormFields } from "./AnswerBankEntryFormFields";
import * as amplitude from "@amplitude/analytics-browser";

type AnswerBankEntryFormProps = {
  initialValues: Record<string, string>;
  onSubmitHandler: (values: CustomQuestionAnswersByQuestionId) => Promise<void>;
};

/**
 * The content and arrangement of this form is based on the CustomQuestionType
 * and its fields. This component transforms the fields' question into
 * shapes consumable by our Avela Form framework.
 */
export const AnswerBankEntryForm: FunctionComponent<
  AnswerBankEntryFormProps
> = (props) => {
  const { initialValues, onSubmitHandler } = props;

  const {
    customQuestion,
    customQuestionType,
    setShowAnswerBank,
    setAnswerToEdit,
  } = useCustomQuestionContext();

  const fieldQuestions: ClonedQuestion<WithId>[] = useMemo(() => {
    return customQuestionType.custom_question_type_fields
      .map((field) => {
        return formatNestedQuestionAsProps(field.question);
      })
      .filter(isNotNull);
  }, [customQuestionType.custom_question_type_fields]);

  const isEditingAnswer = useMemo(() => {
    return !answerIsEmpty(initialValues);
  }, [initialValues]);

  return (
    <Flex direction="column" gap={2}>
      <Formik<CustomQuestionAnswersByQuestionId>
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validate={validateFormTemplate(fieldQuestions, {
          rankedSchoolIds: [],
          previousFormSchoolIds: [],
        })}
      >
        <Form>
          <AnswerBankEntryFormFields
            fieldQuestions={fieldQuestions}
            isEditingAnswer={isEditingAnswer}
          />
        </Form>
      </Formik>
      <Button
        size="sm"
        variant="outline"
        colorScheme="gray"
        width="100%"
        onClick={() => {
          setShowAnswerBank(true);
          setAnswerToEdit(null);
          amplitude.track("Cancel adding entry clicked", {
            cqt_name: customQuestion.question,
          });
        }}
      >
        Cancel
      </Button>
    </Flex>
  );
};
