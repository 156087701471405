import * as GQL from "src/types/graphql";

export type VariableKey =
  | "organization.name"
  | "organization.path"
  | "parent.name"
  | "student.name"
  | "enrollmentPeriod.name"
  | "form.name"
  | "form.closeDate"
  | "form.offerDate"
  | "form.id"
  | "form.shortId"
  | "grade"
  | "schools.name"
  | "applyUrl"
  | "supportEmail"
  | "offer.schoolName"
  | "waitlist.schoolName";

export type Variable = {
  key: VariableKey;
  name: string;
  description: string;
};

export const AllVariables: Variable[] = [
  {
    key: "organization.name",
    name: "Organization name",
    description: "Inserts organization name",
  },
  {
    key: "organization.path",
    name: "Organization path",
    description: "Inserts organization path",
  },
  {
    key: "parent.name",
    name: "Parent full name",
    description: "Inserts the name of the recipient Parent",
  },
  {
    key: "student.name",
    name: "Student full name",
    description: "Inserts the name of the student on the form",
  },
  {
    key: "enrollmentPeriod.name",
    name: "Enrollment period name",
    description: "Inserts enrollment period name",
  },
  {
    key: "form.id",
    name: "Form ID",
    description: "Inserts the ID of the form",
  },
  {
    key: "form.shortId",
    name: "Form short ID",
    description: "Inserts the first 8 characters of the form ID",
  },
  {
    key: "form.name",
    name: "Form name",
    description: "Inserts form name",
  },
  {
    key: "form.closeDate",
    name: "Form close date",
    description: "Inserts the date the form closes if specified",
  },
  {
    key: "form.offerDate",
    name: "Form offer date",
    description: "Inserts the date for offers if specified",
  },
  {
    key: "grade",
    name: "Grade name",
    description: "Inserts the grade the form is associated with",
  },
  {
    key: "schools.name",
    name: "School name",
    description: "Inserts the school names chosen on the form",
  },
  {
    key: "applyUrl",
    name: "Organization apply URL",
    description: "Inserts the URL for the organization’s apply page",
  },
  {
    key: "supportEmail",
    name: "Support email",
    description: "Inserts support email",
  },
  {
    key: "offer.schoolName",
    name: "School name for offer (only for offer related message)",
    description: "School name for offer (only for offer related message)",
  },
  {
    key: "waitlist.schoolName",
    name: "School name for waitlist (only for waitlist related message)",
    description: "School name for waitlist (only for waitlist related message)",
  },
].sort((a, b) => a.key.localeCompare(b.key)) as Variable[];

export type VariablesContext =
  | {
      type: "triggered";
      messageTemplateType: GQL.message_template_type_enum;
    }
  | {
      type: "adhoc";
    };

const BaseTriggeredVariables: VariableKey[] = [
  "organization.name",
  "organization.path",
  "parent.name",
  "student.name",
  "form.name",
  "form.id",
  "form.shortId",
  "enrollmentPeriod.name",
  "form.closeDate",
  "form.offerDate",
  "grade",
  "schools.name",
  "applyUrl",
  "supportEmail",
];

const OfferVariables: VariableKey[] = [
  ...BaseTriggeredVariables,
  "offer.schoolName",
];
const WaitlistVariables: VariableKey[] = [
  ...BaseTriggeredVariables,
  "waitlist.schoolName",
];

const TriggeredVariableMap = {
  [GQL.message_template_type_enum.FormStarted]: BaseTriggeredVariables,
  [GQL.message_template_type_enum.FormSubmitted]: BaseTriggeredVariables,
  [GQL.message_template_type_enum.FormAdminCancelled]: BaseTriggeredVariables,
  [GQL.message_template_type_enum.FormCancelled]: BaseTriggeredVariables,
  [GQL.message_template_type_enum.FormDeleted]: BaseTriggeredVariables,
  [GQL.message_template_type_enum.OfferExtended]: OfferVariables,
  [GQL.message_template_type_enum.OfferAccepted]: OfferVariables,
  [GQL.message_template_type_enum.OfferDeclined]: OfferVariables,
  [GQL.message_template_type_enum.OfferRevoked]: OfferVariables,
  [GQL.message_template_type_enum.Waitlisted]: WaitlistVariables,
  [GQL.message_template_type_enum.WaitlistRemoved]: WaitlistVariables,
  [GQL.message_template_type_enum.WaitlistWithdrawn]: WaitlistVariables,
};

const AdhocVariables: VariableKey[] = [
  "organization.name",
  "organization.path",
  "parent.name",
  "applyUrl",
  "supportEmail",
];

export function getVariables(context?: VariablesContext) {
  let keys = BaseTriggeredVariables;
  if (context?.type === "triggered") {
    keys = TriggeredVariableMap[context.messageTemplateType];
  } else if (context?.type === "adhoc") {
    keys = AdhocVariables;
  }
  return AllVariables.filter((variable) => keys.includes(variable.key));
}
