import { z } from "zod";

const BaseAttr = z.object({
  fieldName: z.string(),
  translate: z.boolean().optional(),
});

const StringAttr = BaseAttr.extend({
  type: z.literal("string"),
});

const IndexAttr = BaseAttr.extend({
  type: z.literal("index"),
  intoFieldName: z.string(),
  separator: z.string(),
  index: z.number(),
});

const ListAttr = BaseAttr.extend({
  type: z.literal("list"),
  separator: z.string(),
});

const RegexAttr = BaseAttr.extend({
  type: z.literal("regex"),
  intoFieldName: z.string(),
  regex: z.string(),
});

const MappedAttr = BaseAttr.extend({
  type: z.literal("mapped"),
  mapping: z.record(z.string(), z.string()),
  defaultValue: z.string().optional(),
  separator: z.string().optional(),
});

const Attr = z.discriminatedUnion("type", [
  StringAttr,
  IndexAttr,
  ListAttr,
  RegexAttr,
  MappedAttr,
]);
export type AttrType = z.infer<typeof Attr>;

export const DataConfigSchema = z.array(Attr);
export type DataConfig = z.infer<typeof DataConfigSchema>;
