import { Flex, Skeleton } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { replaceCIDsWithInlineImages } from "src/components/WysiwygEditor/lexical/utils/converters";
import { useMessageRichContent } from "src/hooks/useMessageRichContent";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { RichContentDocumentType } from "src/types/messages/content";
import { GET_MESSAGE_TEMPLATE } from "../graphql/queries";
import {
  defaultTemplateRankDisabled,
  defaultTemplateRankEnabled
} from "../messageTemplateBody";
import { FormType } from "./components/types";
import { EditFormTemplateEditMessagesProvider } from "./context";
import { EditMessageTemplateForm } from "./EditMessageTemplateForm";
import { Loading } from "src/components/Feedback/Loading";

export const EditMessageTemplate: FunctionComponent = () => {
  const { formTemplateId = "", messageTemplateType = "" } = useParams();
  const [richContent, setRichContent] = useState<RichContentDocumentType>();
  const { downloadRichContent } = useMessageRichContent();

  const templateType = messageTemplateType as GQL.message_template_type_enum;
  const { remoteData } = useRemoteDataQuery<
    GQL.GetMessageTemplate,
    GQL.GetMessageTemplateVariables
  >(GET_MESSAGE_TEMPLATE, {
    variables: {
      formTemplateId: formTemplateId,
      templateType
    },
    skip: !formTemplateId || !messageTemplateType
  });

  useEffect(() => {
    if (remoteData.hasData()) {
      downloadRichContent(
        remoteData.data.message_template[0]?.message_template_rich_contents
      ).then((content) => {
        setRichContent(content);
      });
    }
  }, [downloadRichContent, remoteData]);

  if (remoteData.isNotAsked() || !richContent) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100vw"
      >
        <Loading />
      </Flex>
    );
  }

  return (
    <GQLRemoteDataView
      remoteData={remoteData}
      error={() => <GenericError />}
      loading={<Skeleton width="16rem" height="2.25rem" />}
    >
      {(data) => {
        const template = data.message_template[0];
        const rankingEnabled =
          data.form_template_by_pk?.sections[0]?.schools_ranking_section
            ?.ranking_enabled ?? false;
        const defaultTemplate = rankingEnabled
          ? defaultTemplateRankEnabled
          : defaultTemplateRankDisabled;

        const initialValues: FormType = template
          ? {
              emailSubject: template.email_subject,
              // update the richContent.html with inline images instead of cid
              emailMarkup: richContent.html
                ? replaceCIDsWithInlineImages(
                    richContent.html,
                    richContent.attachments
                  )
                : "",
              emailText: richContent?.text ?? template.email_plain_text,
              legacyMarkup: richContent.html
                ? undefined
                : template.email_markup, // only set legacy markup if there is no lexical content
              emailLexical: richContent?.lexical
                ? JSON.stringify(richContent.lexical)
                : "",
              smsBody: template.sms_body
            }
          : {
              emailSubject: defaultTemplate[templateType].emailSubject,
              emailMarkup: defaultTemplate[templateType].emailHtml,
              emailText: defaultTemplate[templateType].emailText,
              legacyMarkup: defaultTemplate[templateType].emailHtml,
              emailLexical: undefined,
              smsBody: defaultTemplate[templateType].sms
            };

        return (
          <EditFormTemplateEditMessagesProvider
            editMessagesInitialValues={initialValues}
            messageTemplateType={templateType}
            formTemplateId={formTemplateId}
          >
            <EditMessageTemplateForm
              isLoading={remoteData.isLoading()}
              messageEnabled={template?.enabled}
              existingContentId={
                template?.message_template_rich_contents[0]?.content_id
              }
            />
          </EditFormTemplateEditMessagesProvider>
        );
      }}
    </GQLRemoteDataView>
  );
};
