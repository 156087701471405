import {
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
} from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import { RiMore2Fill } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { WithConfig } from "src/components/Permissions/WithConfig";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { FormTabsTypes, SearchAndFilterTypes } from "src/constants";
import { useChunkedRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import * as List from "src/services/list";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RD from "src/types/remoteData";
import {
  FETCH_OFFER_WAITLIST_STATUS,
  FETCH_OFFER_WAITLIST_STATUS_MAX_INPUT_SIZE,
} from "../graphql/queries";
import { FormSchool } from "../types";
import { AcceptOffer } from "./AcceptOffer";
import { AddToWaitlists } from "./AddToWaitlists";
import { ClearSubstatus } from "./ClearSubstatus";
import { DeclineOffer } from "./DeclineOffer";
import { ExcludeForm } from "./ExcludeForm";
import { MakeOffer } from "./MakeOffer";
import { RemoveFromWaitlists } from "./RemoveFromWaitlists";
import { RevokeOffer } from "./RevokeOffer";
import { RunMatch } from "./RunMatch";
import { MenuItem } from "./menuItem";

const AuthorizedMatchContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <WithUserPermissions permissions={["match:create"]}>
      <WithConfig configType="Match">{children}</WithConfig>
    </WithUserPermissions>
  );
};

type Props = {
  formTemplateId: string;
  hiddenMenuItems: MenuItem[];
  fetchSelectedFormSchools: () => Promise<FormSchool[]>;
  onRefetch: () => void;
  onMakeOfferSuccess?: () => void;
};
export const MoreMenu: React.FC<React.PropsWithChildren<Props>> = ({
  formTemplateId,
  hiddenMenuItems,
  fetchSelectedFormSchools,
  onRefetch,
  onMakeOfferSuccess,
  children,
}) => {
  const [selectedFormSchools, setSelectedFormSchools] = React.useState<
    RD.RemoteData<unknown, FormSchool[]>
  >(RD.loading());

  const userPermissions = useUserPermissions();
  const hasMatchPermissions = userPermissions.hasSome(["match:create"]);
  const hasFormUpdatePermissions = userPermissions.hasSome(["form:update"]);

  const hasVisibleItems = React.useMemo(() => {
    const hasChildren = React.Children.count(children) > 0;
    const hasOffersItems =
      !hiddenMenuItems.includes(MenuItem.Offers) && hasFormUpdatePermissions;
    const hasMatchItems = hasMatchPermissions;

    return hasChildren || hasOffersItems || hasMatchItems;
  }, [
    children,
    hiddenMenuItems,
    hasFormUpdatePermissions,
    hasMatchPermissions,
  ]);

  const searchKeys: GQL.form_school_rank_bool_exp[] = React.useMemo(
    () =>
      List.filterMap(selectedFormSchools.withDefault([]), (appSchool) => {
        if (!appSchool.form || !appSchool.school) return null;

        return {
          form_id: { _eq: appSchool.form.id },
          school_id: { _eq: appSchool.school.id },
        };
      }),
    [selectedFormSchools]
  );

  const { remoteData: statusData } = useChunkedRemoteDataQuery<
    GQL.FetchOfferWaitlistStatus,
    GQL.FetchOfferWaitlistStatusVariables
  >(
    FETCH_OFFER_WAITLIST_STATUS,
    React.useMemo(
      () =>
        _.chunk(searchKeys, FETCH_OFFER_WAITLIST_STATUS_MAX_INPUT_SIZE).map(
          (chunk) => ({ search_keys: chunk })
        ),
      [searchKeys]
    ),
    {
      skip: !selectedFormSchools.hasData(),
      fetchPolicy: "cache-and-network",
    }
  );

  const selectedFormSchoolStatuses = React.useMemo(() => {
    return statusData.map((data) =>
      data.flatMap((dataChunk) => dataChunk.form_school_rank)
    );
  }, [statusData]);

  const selectedFormSchoolsList = selectedFormSchools.withDefault([]);

  const onOpen = React.useCallback(async () => {
    setSelectedFormSchools(RD.loading());
    const formSchools = await fetchSelectedFormSchools();
    setSelectedFormSchools(RD.success(formSchools));
  }, [fetchSelectedFormSchools]);

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get(SearchAndFilterTypes.Tab);

  if (!hasVisibleItems) {
    return null;
  }

  return (
    <Menu onOpen={onOpen}>
      <MenuButton
        as={Button}
        variant="banner"
        size="sm"
        leftIcon={<RiMore2Fill />}
      >
        More
      </MenuButton>
      <MenuList>
        {
          // Show children that are not visible on the TableBanner
          React.Children.count(children) > 0 && (
            <MenuGroup>
              {children}
              {!hiddenMenuItems.includes(MenuItem.Offers) && <Divider />}
            </MenuGroup>
          )
        }
        {!hiddenMenuItems.includes(MenuItem.Offers) && (
          <WithUserPermissions permissions={["form:update"]}>
            {selectedTab !== FormTabsTypes.Offers && (
              <>
                <MenuGroup title="Waitlists">
                  {(selectedTab === FormTabsTypes.All ||
                    selectedTab === FormTabsTypes.Submissions ||
                    selectedTab === FormTabsTypes.FormListImports) && (
                    <AddToWaitlists
                      onRefetch={onRefetch}
                      selectedFormSchoolStatuses={selectedFormSchoolStatuses}
                      selectedFormSchools={selectedFormSchoolsList}
                    />
                  )}

                  {(selectedTab === FormTabsTypes.All ||
                    selectedTab === FormTabsTypes.Waitlists ||
                    selectedTab === FormTabsTypes.FormListImports) && (
                    <RemoveFromWaitlists
                      selectedFormSchoolStatuses={selectedFormSchoolStatuses}
                      selectedFormSchools={selectedFormSchoolsList}
                      onRefetch={onRefetch}
                    />
                  )}
                </MenuGroup>
                <Divider />
              </>
            )}
            <MenuGroup title="Offers">
              <MakeOffer
                selectedFormSchoolStatuses={selectedFormSchoolStatuses}
                selectedFormSchools={selectedFormSchoolsList}
                onRefetch={onRefetch}
                onMakeOfferSuccess={onMakeOfferSuccess}
              />
              {(selectedTab === FormTabsTypes.All ||
                selectedTab === FormTabsTypes.Offers ||
                selectedTab === FormTabsTypes.FormListImports) && (
                <AcceptOffer
                  selectedFormSchoolStatuses={selectedFormSchoolStatuses}
                  selectedFormSchools={selectedFormSchoolsList}
                  onRefetch={onRefetch}
                />
              )}
              {(selectedTab === FormTabsTypes.All ||
                selectedTab === FormTabsTypes.Offers ||
                selectedTab === FormTabsTypes.FormListImports) && (
                <DeclineOffer
                  selectedFormSchoolStatuses={selectedFormSchoolStatuses}
                  selectedFormSchools={selectedFormSchoolsList}
                  onRefetch={onRefetch}
                />
              )}
              {(selectedTab === FormTabsTypes.All ||
                selectedTab === FormTabsTypes.Offers ||
                selectedTab === FormTabsTypes.FormListImports) && (
                <RevokeOffer
                  selectedFormSchoolStatuses={selectedFormSchoolStatuses}
                  selectedFormSchools={selectedFormSchoolsList}
                  onRefetch={onRefetch}
                />
              )}
            </MenuGroup>
            <WithRequiredHasuraRoles
              roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
            >
              <Divider />
              <MenuGroup>
                <ExcludeForm
                  selectedFormSchoolStatuses={selectedFormSchoolStatuses}
                  onRefetch={onRefetch}
                />
              </MenuGroup>
            </WithRequiredHasuraRoles>
            <Divider />
            <MenuGroup>
              <ClearSubstatus
                selectedFormSchoolStatuses={selectedFormSchoolStatuses}
                onRefetch={onRefetch}
              />
            </MenuGroup>
            <AuthorizedMatchContainer>
              <Divider />
            </AuthorizedMatchContainer>
          </WithUserPermissions>
        )}
        <AuthorizedMatchContainer>
          <MenuGroup title="Match">
            <RunMatch
              formTemplateId={formTemplateId}
              selectedFormSchools={selectedFormSchoolsList}
            />
          </MenuGroup>
        </AuthorizedMatchContainer>
      </MenuList>
    </Menu>
  );
};
