import { Flex, Heading } from "@chakra-ui/react";
import { HasuraRole } from "src/types/hasuraRole";
import { Configuration } from "./Configuration";
import { FormTemplateRules } from "./FormTemplateRules";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";

export const AdvancedSettings: React.FC = () => {
  const isAdvancedFormTemplatedEditor = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN
  ]);
  if (!isAdvancedFormTemplatedEditor) {
    return null;
  }

  return (
    <Flex direction="column" gap={6}>
      <Heading fontSize="xl" fontWeight="600" color="gray.700">
        Advanced settings
      </Heading>
      <Configuration />
      <FormTemplateRules />
    </Flex>
  );
};
