import { FunctionComponent, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomQuestionAnswersByQuestionId } from "src/components/Form/QuestionForm/formik";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { formatNestedQuestionAsProps } from "src/services/formTemplate/customQuestion";
import { isNotNull } from "src/services/predicates";
import { Form } from "src/services/url/Parent";
import { ClonedQuestion, WithId } from "src/types/formTemplate";
import {
  useSaveCustomQuestionAnswers,
  useUpdatePersonAnswerBankMutation,
} from "../api";
import { useCustomQuestionContext } from "../context/CustomQuestionContext";
import { mapBankAnswersToFormAnswers } from "../helpers";
import { AnswerBankRecord } from "../list/schemas/AnswerBank";
import { AnswerBankEntryForm } from "./AnswerBankEntryForm";
import {
  formatInitialValues,
  formatInsertPersonAnswersWithAnswerBankIdPayload,
} from "./helpers";
import * as amplitude from "@amplitude/analytics-browser";

type EditAnswerBankEntryFormProps = {
  initialAnswer: AnswerBankRecord;
};

export const EditAnswerBankEntryForm: FunctionComponent<
  EditAnswerBankEntryFormProps
> = (props) => {
  const { initialAnswer } = props;
  const { answerBankId } = initialAnswer;

  const {
    organization,
    customQuestion,
    customQuestionType,
    sourceIdCloneIdMapping,
    formId,
    setShowAnswerBank,
    setAnswerToEdit,
  } = useCustomQuestionContext();

  const { step = "" } = useParams();
  const navigate = useNavigate();

  const toast = useAvelaToast({
    position: "bottom",
    containerStyle: { marginBottom: 16 },
    isClosable: true,
  });

  const updatePersonAnswerBankEntry = useUpdatePersonAnswerBankMutation();
  const upsertCustomQuestionAnswers = useSaveCustomQuestionAnswers();

  const initialValues = formatInitialValues(
    initialAnswer,
    sourceIdCloneIdMapping
  );

  const fieldQuestions: ClonedQuestion<WithId>[] = useMemo(() => {
    return customQuestionType.custom_question_type_fields
      .map((field) => {
        return formatNestedQuestionAsProps(field.question);
      })
      .filter(isNotNull);
  }, [customQuestionType.custom_question_type_fields]);

  const onSubmitHandler = useCallback(
    async (values: CustomQuestionAnswersByQuestionId) => {
      try {
        amplitude.track("Save entry clicked", {
          cqt_name: customQuestion.question,
        });
        await updatePersonAnswerBankEntry({
          variables: {
            answer_bank_id: answerBankId,
            last_used_at: new Date().toISOString(),
            person_answers: formatInsertPersonAnswersWithAnswerBankIdPayload({
              answerBankId,
              answersByQuestionId: values,
              fieldQuestions,
            }),
          },
        });
        await upsertCustomQuestionAnswers({
          formId,
          customQuestion,
          answersByQuestionId: mapBankAnswersToFormAnswers(
            values,
            sourceIdCloneIdMapping
          ),
          answerBankId,
        });
        toast({
          title: `${customQuestion.question} saved`,
        });
        setShowAnswerBank(true);
        setAnswerToEdit(null);
        navigate(Form.edit(organization, formId, parseInt(step, 10)), {
          state: { questionId: customQuestion.id },
        });
      } catch (error) {
        console.error(error);
        toast({
          title: `Error saving ${customQuestion.question}`,
          description:
            "Please try again later or report the problem to our support team.",
          status: "error",
        });
      }
    },
    [
      updatePersonAnswerBankEntry,
      answerBankId,
      fieldQuestions,
      upsertCustomQuestionAnswers,
      formId,
      customQuestion,
      sourceIdCloneIdMapping,
      toast,
      setShowAnswerBank,
      setAnswerToEdit,
      navigate,
      organization,
      step,
    ]
  );

  return (
    <AnswerBankEntryForm
      initialValues={initialValues}
      onSubmitHandler={onSubmitHandler}
    />
  );
};
