import { Heading, HStack } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { useOrganization } from "src/hooks/useOrganization";
import * as breadcrumb from "src/services/breadcrumb";
import { SelectionObject } from "../../dataServices/exports/types";
import { BulkExportForm } from "./BulkExportForm";
export interface BulkExportState {
  formTemplateId: uuid;
  selection: SelectionObject;
  questionIdsInOrder: uuid[];
  dropoffFormTemplateId?: uuid;
}

export function BulkExport() {
  const organization = useOrganization();
  const { selectedNavFormTemplate } = useFormTemplates();

  const location = useLocation();
  const {
    formTemplateId,
    questionIdsInOrder,
    selection,
    dropoffFormTemplateId,
  } = (location.state ?? {}) as Partial<BulkExportState>;
  const isBadState = !formTemplateId || !questionIdsInOrder || !selection;

  const breadcrumbItems: breadcrumb.BreadcrumbType[] = [
    ...breadcrumb.form.getBreadcrumbsForList(
      organization,
      selectedNavFormTemplate
    ),
    { label: "Export forms", href: "" },
  ];

  return (
    <Flex direction="column" gap="0.5rem" minBlockSize="100%">
      <Breadcrumb items={breadcrumbItems} mb={4} />
      <HStack pb={8}>
        <Heading as="h1" size="lg">
          Export forms
        </Heading>
      </HStack>
      {!isBadState && (
        <BulkExportForm
          formTemplateId={formTemplateId}
          selection={selection}
          questionIdsInOrder={questionIdsInOrder}
          dropoffFormTemplateId={dropoffFormTemplateId}
        />
      )}
      {isBadState && <GenericError />}
    </Flex>
  );
}
