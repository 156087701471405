import axios, { AxiosProgressEvent, ResponseType } from "axios";
import { useCallback } from "react";
import { GET_DOCUMENT_INFO } from "src/components/graphql/queries";
import {
  GENERATE_DELETE_URL,
  GENERATE_DOWNLOAD_URL,
  GENERATE_UPLOAD_URL,
} from "src/constants";
import { normalizeToAWSAcceptableKey } from "src/services/format";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import useAccessToken from "./useAccessToken";
import { useLazyRemoteDataQuery } from "./useRemoteDataQuery";
import useUser from "./useUser";
import { useOrganization } from "./useOrganization";

const PresignedDownloadUrlSchema = z.object({
  signed_url: z.object({
    URL: z.string(),
  }),
});

const PresignedUploadUrlSchema = z.object({
  document_id: z.string(),
  signed_url: z.object({
    URL: z.string(),
    Method: z.string(),
    SignedHeader: z.object({
      "Content-Disposition": z.array(z.string()),
      "X-Amz-Meta-Document-Type": z.array(z.string()),
      "X-Amz-Meta-Filename": z.array(z.string()),
      "X-Amz-Meta-Form-Answer-Id": z.array(z.string()).optional(),
      "X-Amz-Meta-Owner-User-Id": z.array(z.string()),
      "X-Amz-Meta-Organization-Id": z.array(z.string()),
      "X-Amz-Server-Side-Encryption": z.array(z.string()),
    }),
  }),
});

export type PresignedDownloadUrl = z.infer<typeof PresignedDownloadUrlSchema>;
export type PresignedUploadUrl = z.infer<typeof PresignedUploadUrlSchema>;

export function useDocumentStorage() {
  const documentStorageUrl = process.env.REACT_APP_AWS_FILE_UPLOAD_URL;
  const accessToken = useAccessToken();
  const user = useUser();
  const organization = useOrganization();

  const [fetchDocumentInfo] = useLazyRemoteDataQuery<
    GQL.GetDocumentoInfo,
    GQL.GetDocumentoInfoVariables
  >(GET_DOCUMENT_INFO);

  const getDocumentInfo = useCallback(
    async (documentId: string) => {
      if (accessToken.status !== Status.OK) return;

      const result = await fetchDocumentInfo({
        variables: { document_id: documentId },
      });

      return result.data?.document_metadata_by_pk;
    },
    [accessToken, fetchDocumentInfo]
  );

  const getDownloadUrl = useCallback(
    async (documentId: string): Promise<string | undefined> => {
      if (!documentStorageUrl || accessToken.status !== Status.OK) {
        return;
      }

      try {
        const response = await axios.post(
          `${documentStorageUrl}/${GENERATE_DOWNLOAD_URL}`,
          {
            document_id: documentId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken.data}`,
              "Content-Type": "application/json",
            },
          }
        );
        return PresignedDownloadUrlSchema.parse(response.data).signed_url.URL;
      } catch (err) {
        console.error(err);
      }
    },
    [documentStorageUrl, accessToken]
  );

  const getUploadUrl = useCallback(
    async (
      document: {
        filename: string;
        document_content_type: string;
        document_type: "General" | "MessagePayload";
        form_answer_id?: string;
      },
      onUploadProgress?: (event: AxiosProgressEvent) => void
    ): Promise<PresignedUploadUrl | undefined> => {
      if (
        !documentStorageUrl ||
        accessToken.status !== Status.OK ||
        user.status !== Status.OK
      ) {
        console.error("Document storage service unavailable:", {
          documentStorageUrl,
          accessTokenStatus: accessToken.status,
        });
        return;
      }

      try {
        const filename = normalizeToAWSAcceptableKey(document.filename);
        const response = await axios.post(
          `${documentStorageUrl}/${GENERATE_UPLOAD_URL}`,
          {
            filename,
            owner_user_id: user?.data.id,
            document_content_disposition: `attachment; filename=${filename}`,
            document_content_type: document.document_content_type,
            document_type: document.document_type,
            organization_id: organization.map((org) => org.id).withDefault(""),
            form_answer_id: document.form_answer_id ?? undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken.data}`,
              "Content-Type": "application/json",
            },
            onUploadProgress: onUploadProgress,
          }
        );
        return PresignedUploadUrlSchema.parse(response.data);
      } catch (err: any) {
        return err;
      }
    },
    [documentStorageUrl, accessToken, user, organization]
  );

  const uploadFile = useCallback(
    async (
      file: File,
      { signed_url: { URL, SignedHeader } }: PresignedUploadUrl,
      onUploadProgress?: (event: AxiosProgressEvent) => void
    ) => {
      if (!URL) return;

      try {
        const putHeaders = {
          ...SignedHeader,
          "Content-Type": file.type,
        };

        return await axios.put(URL, file, {
          headers: putHeaders,
          onUploadProgress: onUploadProgress,
        });
      } catch (err: any) {
        console.error(err);
        return err;
      }
    },
    []
  );

  const upload = useCallback(
    async (
      data: string,
      contentType: string,
      { signed_url: { URL, SignedHeader } }: PresignedUploadUrl,
      onUploadProgress?: (event: AxiosProgressEvent) => void
    ) => {
      if (!URL) return;

      try {
        const putHeaders = {
          ...SignedHeader,
          "Content-Type": contentType,
        };

        return await axios.put(URL, data, {
          headers: putHeaders,
          onUploadProgress: onUploadProgress,
        });
      } catch (err: any) {
        console.error(err);
        return err;
      }
    },
    []
  );

  const download = useCallback(
    async (url: string, responseType: ResponseType) => {
      const response = await axios.get(url, {
        responseType,
      });
      return response.data;
    },
    []
  );

  const getDeleteUrl = useCallback(
    async (documentId: string): Promise<string | undefined> => {
      if (!documentStorageUrl || accessToken.status !== Status.OK) {
        return;
      }

      try {
        const response = await axios.post(
          `${documentStorageUrl}/${GENERATE_DELETE_URL}`,
          {
            document_id: documentId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken.data}`,
              "Content-Type": "application/json",
            },
          }
        );
        return PresignedDownloadUrlSchema.parse(response.data).signed_url.URL;
      } catch (err) {
        console.error(err);
      }
    },
    [documentStorageUrl, accessToken]
  );

  const deleteFile = useCallback(async (url: string) => {
    const response = await axios.delete(url);
    return response.status >= 200 && response.status < 300;
  }, []);

  return {
    getDocumentInfo,
    getDownloadUrl,
    getUploadUrl,
    getDeleteUrl,
    uploadFile,
    upload,
    download,
    deleteFile,
  };
}
