import { Button, Divider, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { GET_GLOSSARY } from "src/hooks/useGlossary/graphql/queries";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { CustomGlossaryForm } from "src/scenes/admin/glossaries/CustomGlossaryForm";
import { GlossaryStatusSelect } from "src/scenes/admin/glossaries/GlossaryStatusSelect";
import {
  DELETE_GLOSSARY_BY_ORGANIZATION,
  INSERT_GLOSSARY_BY_ORGANIZATION,
} from "src/scenes/admin/glossaries/graphql/mutations";
import {
  GET_GLOSSARY_BY_ORGANIZATION,
  GET_GLOSSARY_LIST,
} from "src/scenes/admin/glossaries/graphql/queries";
import {
  initialFormValues,
  toInsertMutations,
} from "src/scenes/admin/glossaries/services";
import * as BC from "src/services/breadcrumb";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { FormSchema, FormType } from "./types";

type Props = {};
export const Edit: React.FC<Props> = () => {
  const { id: organizationId } = useParams();
  const { remoteData } = useRemoteDataQuery<
    GQL.GetGlossaryByOrganization,
    GQL.GetGlossaryByOrganizationVariables
  >(GET_GLOSSARY_BY_ORGANIZATION, {
    variables: { organizationId: organizationId ?? "" },
    skip: organizationId === undefined,
  });

  const [insertGlossary] = useRemoteDataMutation<
    GQL.InsertGlossaryByOrganization,
    GQL.InsertGlossaryByOrganizationVariables
  >(INSERT_GLOSSARY_BY_ORGANIZATION);
  const [deleteGlossary] = useRemoteDataMutation<
    GQL.DeleteGlossaryByOrganization,
    GQL.DeleteGlossaryByOrganizationVariables
  >(DELETE_GLOSSARY_BY_ORGANIZATION);

  const toast = useAvelaToast();
  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    async (values: FormType) => {
      if (organizationId === undefined) {
        console.error(
          "Unable to save glossary because organizationId is undefined"
        );
        return;
      }

      try {
        switch (values.status) {
          case "Custom":
            await insertGlossary({
              variables: {
                organizationId,
                glossary: toInsertMutations(organizationId, values),
              },
              refetchQueries: [
                GET_GLOSSARY_LIST,
                GET_GLOSSARY_BY_ORGANIZATION,
                GET_GLOSSARY,
              ],
            });
            break;

          case "Standard":
            await deleteGlossary({
              variables: { organizationId },
              refetchQueries: [
                GET_GLOSSARY_LIST,
                GET_GLOSSARY_BY_ORGANIZATION,
                GET_GLOSSARY,
              ],
            });
            break;

          default:
            const _exhaustiveCheck: never = values;
            return _exhaustiveCheck;
        }
        toast({
          title: "Glossary updated!",
        });
        navigate(Url.Admin.Glossaries.index());
      } catch (error: unknown) {
        console.error(error);
        toast.error({
          title: "Something went wrong!",
          description: "Check your network and try again",
        });
      }
    },
    [deleteGlossary, insertGlossary, navigate, organizationId, toast]
  );

  if (organizationId === undefined) {
    return <NotFound />;
  }

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        const initialValues: FormType = initialFormValues(organizationId, data);

        return (
          <Formik<FormType>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validateWithZod(FormSchema)}
          >
            {(formik) => {
              if (data.organization_by_pk === null) {
                return <NotFound />;
              }

              return (
                <Flex as={Form} direction="column" gap="6" minHeight="100%">
                  <Breadcrumb
                    items={BC.avelaAdmin.glossaries.getBreadcrumbsForEdit(
                      organizationId,
                      data.organization_by_pk.name
                    )}
                  />
                  <Heading as="h1" size="xl" fontWeight="600">
                    {data.organization_by_pk.name}
                    {data.organization_by_pk.name.endsWith("s")
                      ? "'"
                      : "'s"}{" "}
                    glossary
                  </Heading>
                  <Flex direction="column" alignItems="start" gap="10">
                    <GlossaryStatusSelect />
                    {formik.values.status === "Custom" && (
                      <>
                        <Divider />
                        <CustomGlossaryForm />
                      </>
                    )}
                  </Flex>
                  <Spacer />
                  <AdminFormButtons>
                    <Button
                      variant="outline"
                      colorScheme="gray"
                      onClick={() => {
                        navigate(Url.Admin.Glossaries.index());
                      }}
                    >
                      Cancel
                    </Button>
                    <Spacer />
                    <Button type="submit">Save</Button>
                  </AdminFormButtons>
                </Flex>
              );
            }}
          </Formik>
        );
      }}
    </GQLRemoteDataView>
  );
};
