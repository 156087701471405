import { z } from "zod";

export const RichContentDocumentSchema = z.object({
  lexical: z.any(),
  html: z.string().optional(),
  text: z.string(),
  attachments: z
    .array(
      z.object({
        cid: z.string(),
        data: z.string(),
        filename: z.string(),
        mimeType: z.string(),
      })
    )
    .optional(),
});

export type RichContentDocument = z.infer<typeof RichContentDocumentSchema>;

export type EmptyRichContentDocument = {
  lexical: null;
  html: null;
  text: null;
  attachments: null;
};

export type RichContentDocumentType =
  | RichContentDocument
  | EmptyRichContentDocument;
