import {
  Box,
  Button,
  Code,
  Flex,
  Heading,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import { Form, Formik } from "formik";
import { LexicalEditor } from "lexical";
import { useCallback, useMemo, useState } from "react";
import { RiLightbulbLine } from "react-icons/ri";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { NotFound } from "src/components/Feedback/NotFound";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import { RecipientsTable } from "src/components/Table/RecipientsTable";
import { EditorRef } from "src/components/WysiwygEditor/lexical/Editor";
import { convertPlainTextToHtml } from "src/components/WysiwygEditor/lexical/utils/converters";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useMessageRichContent } from "src/hooks/useMessageRichContent";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { NewMessageState } from "src/hooks/useSendMessage";
import useUser from "src/hooks/useUser";
import { generateMessageSchema } from "src/schemas/Message";
import * as breadcrumb from "src/services/breadcrumb";
import { validateWithZod } from "src/services/formValidations";
import * as Person from "src/services/person";
import { checkUnverifiedCommsPerOrg } from "src/services/unverifiedCommsHelpers";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { MessageForm } from "../components/Forms/MessageForm";
import { SEND_MESSAGE } from "./graphql/mutations";
import { GET_RECIPIENTS_DETAILS, GET_SENDER_ID } from "./graphql/queries";

export const NewMessage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  const toast = useAvelaToast();
  const organization = useOrganization();
  const [searchParams] = useSearchParams();

  const flags = useFlags(["unverified-communications"]);
  const [recipientsInfo, setRecipientsInfo] = useState<
    GQL.GetRecipientsDetails_person[]
  >([]);

  const state = location.state as NewMessageState;
  const organizationId = useMemo(
    () => organization.map((org) => org.id).withDefault(""),
    [organization]
  );
  const isUnverifiedCommsEnabledForOrg = useMemo(
    () =>
      checkUnverifiedCommsPerOrg(
        organization,
        flags["unverified-communications"].enabled
      ),
    [flags, organization]
  );
  useRemoteDataQuery<
    GQL.GetRecipientsDetails,
    GQL.GetRecipientsDetailsVariables
  >(GET_RECIPIENTS_DETAILS, {
    variables: { parents_id: state?.parentsIdList || [] },
    fetchPolicy: "cache-first",
    onCompleted(data) {
      setRecipientsInfo(data.person);
    },
  });

  const { remoteData: senderIdRemoteData } = useRemoteDataQuery<
    GQL.GetSenderId,
    GQL.GetSenderIdVariables
  >(GET_SENDER_ID, {
    variables: {
      userId: user.status === Status.OK ? user.data.id : "",
      organizationId: organizationId,
    },
    skip: user.status !== Status.OK || !organization.hasData(),
  });

  const [SendMessage] = useRemoteDataMutation<
    GQL.SendMessage,
    GQL.SendMessageVariables
  >(SEND_MESSAGE);

  const sendMessageInfo = useMemo(() => {
    let emailCount = 0;
    let smsCount = 0;
    const receiversInfos = recipientsInfo.flatMap(
      (person): GQL.message_adhoc_receiver_insert_input[] => {
        const contactInformation = Person.getContactInformation(
          person,
          isUnverifiedCommsEnabledForOrg
        );
        switch (contactInformation.type) {
          case Person.ContactType.Email:
            emailCount++;
            return [
              {
                receiver_person_id: person.id,
                receiver_contact_information: contactInformation.email_address,
                message_type: GQL.message_type_enum.email,
              },
            ];

          case Person.ContactType.Phone:
            smsCount++;
            return [
              {
                receiver_person_id: person.id,
                receiver_contact_information: contactInformation.phone_number,
                message_type: GQL.message_type_enum.SMS,
              },
            ];

          case Person.ContactType.All:
            emailCount++;
            smsCount++;
            return [
              {
                receiver_person_id: person.id,
                receiver_contact_information: contactInformation.phone_number,
                message_type: GQL.message_type_enum.SMS,
              },
              {
                receiver_person_id: person.id,
                receiver_contact_information: contactInformation.email_address,
                message_type: GQL.message_type_enum.email,
              },
            ];

          case Person.ContactType.None:
          default:
            // skip this person since they don't have contact info
            return [];
        }
      }
    );

    return {
      receiversInfos,
      hasRecipientsWithEmail: emailCount > 0,
      hasRecipientsWithPhone: smsCount > 0,
    };
  }, [recipientsInfo, isUnverifiedCommsEnabledForOrg]);

  // Since we are dealing with dynamic verification, we need to build the schema based on the recipients info.
  const MessageSchema = useMemo(
    () =>
      generateMessageSchema(
        sendMessageInfo.hasRecipientsWithEmail,
        sendMessageInfo.hasRecipientsWithPhone
      ),
    [sendMessageInfo]
  );

  type MessageFormType = z.infer<typeof MessageSchema>;

  const emptyMessageForm: MessageFormType = {
    subject: "Message about your school form",
    email_body: "",
    sms_body: "",
  };
  const [emailEditor, setEmailEditor] = useState<LexicalEditor>();
  const emailEditorRef: EditorRef = (editor: LexicalEditor) => {
    if (editor != null) setEmailEditor(editor);
  };

  const { uploadRichContent } = useMessageRichContent(emailEditor);

  const handleSubmitMessage = useCallback(
    async (values: MessageFormType) => {
      try {
        if (!organization.hasData() || user.status !== "ok") {
          toast({
            title: "Error sending message",
            status: "error",
            isClosable: true,
            description: "Unable to send message without organization",
          });
          throw new Error("Unable to send message without organization");
        }
        if (!state?.formsIdList) {
          toast({
            title: "Error sending message",
            status: "error",
            isClosable: true,
            description: "Unable to send message without form IDs",
          });
          throw new Error("Unable to send message without form IDs");
        }
        const senderId = senderIdRemoteData
          .map((data) => data.person[0]?.id)
          .withDefault(undefined);
        if (!senderId) {
          toast({
            title: "Error sending message",
            status: "error",
            isClosable: true,
            description: "Unable to send message without senderId",
          });
          throw new Error("Unable to send message without senderId");
        }
        if (organizationId === "") {
          toast({
            title: "Error sending message",
            status: "error",
            isClosable: true,
            description: "Unable to send message without organization ID",
          });
          throw new Error("Unable to send message without organizationId");
        }

        const { document_id, emailDocument } = await uploadRichContent();

        const formsIds = state?.formsIdList?.map((formId) => ({
          form_id: formId,
        }));

        const messageObject: GQL.SendMessageVariables["message"] = {
          sender_person_id: senderId,
          organization_id: organizationId,
          message_adhoc_receivers: { data: sendMessageInfo.receiversInfos },
          message_adhoc_forms: { data: formsIds ?? [] },
          message_adhoc_payload_sms: {
            data: { message_body: values.sms_body },
          },
          message_adhoc_payload_email: emailDocument
            ? {
                data: {
                  message_body: emailDocument?.text || "",
                  message_subject: values.subject,
                },
              }
            : undefined,
          message_adhoc_payload_rich_contents: document_id
            ? {
                data: [
                  {
                    content_id: document_id,
                  },
                ],
              }
            : undefined,
        };

        await SendMessage({
          variables: {
            message: messageObject,
          },
        });

        toast({ title: "Messages sent" });
        navigate(-1);
      } catch (err) {
        console.error(err);
        toast.error({ title: "Error sending message" });
      }
    },
    [
      organization,
      user.status,
      state?.formsIdList,
      senderIdRemoteData,
      organizationId,
      uploadRichContent,
      sendMessageInfo.receiversInfos,
      SendMessage,
      toast,
      navigate,
    ]
  );

  const initialValues = {
    subject: emptyMessageForm.subject,
    email_body: emptyMessageForm.email_body,
    html_body: state?.emailBody
      ? convertPlainTextToHtml(state.emailBody)
      : undefined,
    sms_body: state?.smsBody || emptyMessageForm.sms_body,
  };

  const { selectedNavFormTemplate } = useFormTemplates();

  if (!state) return <NotFound></NotFound>;

  return (
    <Flex direction="column">
      <Breadcrumb
        mb={4}
        items={breadcrumb.form.getBreadcrumbsForSendMessage(
          organization,
          selectedNavFormTemplate,
          searchParams.toString()
        )}
      />

      <Heading as="h1" size="lg" pb={8}>
        Send message
      </Heading>

      <Flex>
        <Box width="50%">
          <RecipientsTable recipients={recipientsInfo} />
        </Box>

        <Flex direction="column" pl={6} width="50%">
          <Formik<MessageFormType>
            initialValues={initialValues}
            onSubmit={handleSubmitMessage}
            validate={validateWithZod(MessageSchema)}
          >
            {({ isValid, isSubmitting }) => (
              <Flex direction="column" as={Form}>
                <Text variant="filterHeader" mb={2}>
                  Messages
                </Text>
                <Flex gap={2} mb={2}>
                  <Tag
                    bg="purple.500"
                    color="white"
                    alignSelf="flex-start"
                    size="sm"
                    minWidth="60px"
                  >
                    <TagLeftIcon as={RiLightbulbLine} />
                    <TagLabel>Tip</TagLabel>
                  </Tag>
                  <Text>
                    Type <Code>{"{{"}</Code> in any content area to see
                    variables. Variables help you replace text with dynamic
                    content.
                  </Text>
                </Flex>

                <MessageForm
                  hasRecipientsWithEmail={
                    sendMessageInfo.hasRecipientsWithEmail
                  }
                  hasRecipientsWithPhone={
                    sendMessageInfo.hasRecipientsWithPhone
                  }
                  emailEditorRef={emailEditorRef}
                />
                <Flex gap={2} marginLeft="auto">
                  <Button
                    onClick={() => navigate(-1)}
                    variant="outline"
                    colorScheme="gray"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isDisabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Send
                  </Button>
                </Flex>
              </Flex>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Flex>
  );
};
