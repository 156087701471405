import { gql } from "@apollo/client";
import {
  APPLICANT_ATTENDING_SCHOOLS_FRAGMENT,
  PERSON_FRAGMENT,
  SIBLING_FRAGMENT,
} from "src/operations/fragments/person";

export const GET_STUDENT_BY_ID_ADMIN_PORTAL = gql`
  ${PERSON_FRAGMENT}
  ${APPLICANT_ATTENDING_SCHOOLS_FRAGMENT}
  ${SIBLING_FRAGMENT}
  query GetStudentByIdAdminPortal($student_id: uuid!) {
    person_by_pk(id: $student_id) {
      ...PersonFragment
      ...ApplicantAttendingSchoolsFragment
      first_relationship {
        second {
          first_relationship {
            second {
              ...SiblingFragment
            }
          }
          second_relationship {
            first {
              ...SiblingFragment
            }
          }
        }
      }
      second_relationship {
        first {
          first_relationship {
            second {
              ...SiblingFragment
            }
          }
          second_relationship {
            first {
              ...SiblingFragment
            }
          }
        }
      }
      forms(where: { status: { _nin: [Cancelled, Deleted, Withdrawn] } }) {
        id
        form_template {
          id
          name
          enrollment_period {
            name
          }
        }
      }
      organization {
        schools(where: { status: { _eq: "Active" } }) {
          id
          name
        }
        enrollment_periods(
          where: { active: { _eq: true }, deleted_at: { _is_null: true } }
        ) {
          id
          name
        }
      }
    }
  }
`;

export const GET_FORMS_BY_STUDENT_ID = gql`
  query GetFormsByStudentId($id: uuid!, $order_by: [form_order_by!]) {
    form(where: { person_id: { _eq: $id } }, order_by: $order_by) {
      id
      status
      form_template {
        id
        name
        enrollment_period {
          id
          name
        }
      }
    }
  }
`;

export const GET_STUDENTS = gql`
  ${PERSON_FRAGMENT}
  query GetStudents(
    $organizationId: uuid!
    $limit: Int
    $offset: Int
    $order_by: [person_order_by!]
    $search: person_bool_exp!
  ) {
    person_aggregate(
      where: {
        _and: [
          { organization_id: { _eq: $organizationId } }
          { person_type: { _eq: applicant } }
          $search
        ]
      }
    ) {
      ...PersonTotals
    }
    person(
      where: {
        _and: [
          { organization_id: { _eq: $organizationId } }
          { person_type: { _eq: applicant } }
          $search
        ]
      }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...PersonFragment
    }
  }
`;

export const GET_ACTIVE_FORMS_BY_STUDENT_ID = gql`
  query GetActiveFormsByStudentId($id: uuid!, $order_by: [form_order_by!]) {
    form(
      where: { person_id: { _eq: $id }, deleted_at: { _is_null: true } }
      order_by: $order_by
    ) {
      id
      status
      form_template_id
    }
  }
`;

export const GET_POSSIBLE_STUDENT_DUPLICATES = gql`
  query GetPossibleStudentDuplicates(
    $student_id: uuid!
    $organization_id: uuid!
    $first_name: String!
    $last_name: String!
    $date_of_birth: date!
  ) {
    person(
      where: {
        _and: {
          organization_id: { _eq: $organization_id }
          first_name: { _eq: $first_name }
          last_name: { _eq: $last_name }
          birth_date: { _eq: $date_of_birth }
          id: { _neq: $student_id }
          person_type: { _eq: applicant }
        }
      }
      order_by: { updated_at: desc }
    ) {
      id
      first_name
      last_name
      birth_date
    }
  }
`;
