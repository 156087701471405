import { gql } from "@apollo/client";

export const GET_MATCH_DETAILS = gql`
  query GetMatchDetails($matchId: uuid!) {
    match_run_by_pk(id: $matchId) {
      name
      created_at
      results_document_id
      match_config_snapshot_document_id
      capacities_snapshot_document_id
    }
  }
`;

export const GET_SCHOOLS_FOR_MATCH = gql`
  query GetSchoolsForMatch(
    $organizationId: uuid!
    $search: school_bool_exp!
    $school_filter: school_bool_exp!
    $enrollmentPeriodId: uuid!
  ) {
    school(
      where: {
        _and: [
          { organization_id: { _eq: $organizationId } }
          $search
          $school_filter
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
      reference_id
      street_address
      street_address_line_2
      city
      state
      zip_code
      school_default_bin_configs(
        where: { enrollment_period_id: { _eq: $enrollmentPeriodId } }
      ) {
        id
        bin_config_id
      }
    }
  }
`;

export const GET_MATCH_CONFIGURATION = gql`
  query GetMatchConfiguration($enrollmentPeriodId: uuid!) {
    match_config(
      where: { enrollment_period_id: { _eq: $enrollmentPeriodId } }
    ) {
      id
      name
      parameters
      default_bin_config_id
      enrollment_period_id
      bin_configs {
        id
        name
        parameters
        sort_fields
        priority_groups
      }
    }
  }
`;

export const GET_BIN_CONFIGURATION_BY_ID = gql`
  query GetBinConfigurationById($binConfigId: uuid!) {
    bin_config_by_pk(id: $binConfigId) {
      id
      name
      parameters
      sort_fields
      priority_groups
    }
  }
`;

export const GET_SCHOOL_DEFAULT_BIN_CONFIG = gql`
  query GetSchoolDefaultBinConfig($schoolId: uuid!) {
    school_default_bin_config(where: { school_id: { _eq: $schoolId } }) {
      id
      bin_config_id
    }
  }
`;

export const GET_MATCH_RUNS = gql`
  query GetMatchRuns(
    $enrollmentPeriodId: uuid!
    $limit: Int
    $offset: Int
    $order_by: [match_run_order_by!]
    $school_filter: match_run_bool_exp!
  ) {
    match_run_aggregate(
      where: {
        enrollment_period_id: { _eq: $enrollmentPeriodId }
        _and: [$school_filter]
      }
    ) {
      aggregate {
        count
      }
    }
    match_run(
      where: {
        enrollment_period_id: { _eq: $enrollmentPeriodId }
        _and: [$school_filter]
      }
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      id
      name
      created_at
      owner {
        id
        people {
          id
          full_name
        }
      }
      status
      status_message
    }
  }
`;

export const GET_STUDENTS_AVATARS = gql`
  query GetStudentsAvatars($studentIds: [uuid!]!) {
    person(where: { id: { _in: $studentIds } }) {
      id
      avatar
    }
  }
`;
