import { html } from "src/components/HtmlViewer/utils";
import * as GQL from "src/types/graphql";

export const FORM_DELETED = {
  type: GQL.message_template_type_enum.FormDeleted,
  emailSubject: "You've deleted your {{organization.name}} form.",
  emailHtml: html` <p>
    Dear {{parent.name}}, <br />
    <br />
    You have deleted your form for {{student.name}} for
    {{enrollmentPeriod.name}}. If this was a mistake, please contact your school
    or district administrator in order to reinstate your form.<br />
  </p>`,
  emailText: `Dear {{parent.name}},

You have deleted your form for {{student.name}} for {{enrollmentPeriod.name}}. If this was a mistake, please contact your school or district administrator in order to reinstate your form.`,
  sms: "You've deleted your form for {{organization.name}} form for {{student.name}}."
};
