import { useOutletContext } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useTimezoneName } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { FormTemplateOutletContext } from "../../Edit";
import { GET_FORM_TEMPLATE_SETTINGS_BY_ID } from "../../graphql/queries";
import { toInitialValues } from "./utils";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { EditFormTemplateSettingsProvider } from "./context";
import { SettingsForm } from "./SettingsForm";

export const SettingsTab: React.FC = () => {
  const { formTemplate } = useOutletContext<FormTemplateOutletContext>();

  const id = formTemplate.id;
  const timezoneName = useTimezoneName();
  const canEditFormTemplateRules = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN
  ]);

  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormTemplateSettingsById,
    GQL.GetFormTemplateSettingsByIdVariables
  >(GET_FORM_TEMPLATE_SETTINGS_BY_ID, {
    variables: {
      form_template_id: id,
      skip_form_template_rule: !canEditFormTemplateRules
    },
    fetchPolicy: "no-cache"
  });

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (!data.form_template_by_pk) return null;

        const initialValues = toInitialValues(
          data.form_template_by_pk,
          timezoneName
        );

        return (
          <EditFormTemplateSettingsProvider
            settingsInitialValues={initialValues}
            formTemplateId={data.form_template_by_pk.id}
          >
            <SettingsForm isLoading={remoteData.isLoading()} />
          </EditFormTemplateSettingsProvider>
        );
      }}
    </GQLRemoteDataView>
  );
};
