import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Skeleton,
  Spacer,
} from "@chakra-ui/react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import * as BC from "src/services/breadcrumb";
import { JsonEditor } from "../components/JsonEditor";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import * as Url from "src/services/url";
import { Form, Formik } from "formik";
import { useOrganizationFromId } from "./hooks/useOrganizationFromId";
import {
  UIConfigJsonSchema,
  DataConfigJsonSchema,
  UIConfig,
  DataConfig,
  UIConfigSchema,
  DataConfigSchema,
} from "./types/explore";
import * as RD from "src/types/remoteData";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { GenericError } from "src/components/Feedback/GenericError";
import { useCallback, useEffect, useState } from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { validateWithZod } from "src/services/formValidations";
import { z } from "zod";
import _ from "lodash";
import { Env, useEnv } from "src/services/env";
import { AuthData, Status } from "src/types/authData";
import useAccessToken from "src/hooks/useAccessToken";

export const EditExplore = () => {
  const { id: organizationId, explorePath } = useParams();
  const organization = useOrganizationFromId(organizationId);
  const { remoteData, update } = useExploreConfig({
    organizationId,
    explorePath,
  });
  const toast = useAvelaToast();
  const navigate = useNavigate();
  const [isSaving, setSaving] = useState(false);

  if (!explorePath) {
    throw new Error("Missing explore path");
  }

  return (
    <Flex minHeight="100%" direction="column" gap={6}>
      <Breadcrumb
        items={BC.avelaAdmin.organizations.getBreadcrumbsForEditExplore(
          organization,
          explorePath
        )}
      />
      <RemoteDataView
        remoteData={remoteData}
        error={(error) => <GenericError error={error} />}
        loading={<Loading />}
      >
        {(data) => (
          <Formik<{ uiConfig: string; dataConfig: string }>
            initialValues={{
              uiConfig: JSON.stringify(data.uiConfig, null, 2),
              dataConfig: JSON.stringify(data.dataConfig, null, 2),
            }}
            validate={(values) => {
              const uiConfig = _.attempt(() => JSON.parse(values.uiConfig));
              const dataConfig = _.attempt(() => JSON.parse(values.dataConfig));
              return validateWithZod(
                z.object({
                  uiConfig: UIConfigSchema,
                  dataConfig: DataConfigSchema,
                })
              )({
                uiConfig,
                dataConfig,
              });
            }}
            onSubmit={async (values) => {
              try {
                if (!organization.hasData()) {
                  throw new Error("Missing organizationId");
                }

                const uiConfig = UIConfigSchema.safeParse(
                  JSON.parse(values.uiConfig)
                );
                if (uiConfig.error) {
                  throw new Error("Invalid UI settings", {
                    cause: uiConfig.error,
                  });
                }
                const dataConfig = DataConfigSchema.safeParse(
                  JSON.parse(values.dataConfig)
                );
                if (dataConfig.error) {
                  throw new Error("Invalid data configuration", {
                    cause: dataConfig.error,
                  });
                }

                const uiConfigHasChanges = !_.isEqual(
                  data.uiConfig,
                  uiConfig.data
                );
                const dataConfigHasChanges = !_.isEqual(
                  data.dataConfig,
                  dataConfig.data
                );

                if (uiConfigHasChanges || dataConfigHasChanges) {
                  setSaving(true);
                  await update(
                    uiConfigHasChanges ? uiConfig.data : "NoChange",
                    dataConfigHasChanges ? dataConfig.data : "NoChange"
                  );
                  toast({
                    title: "Explore instance updated",
                  });
                }

                navigate(Url.Admin.Organizations.edit(organization.data.id));
                setSaving(false);
              } catch (error) {
                setSaving(false);
                if (error instanceof Error) {
                  toast.error({
                    title: "Instance could not be updated",
                    description: error.message,
                  });
                } else {
                  toast.error({
                    title: "Instance could not be updated",
                    description: "Unknown error",
                  });
                }
                console.error(error);
              }
            }}
          >
            {(formik) => {
              const uiConfigMeta = formik.getFieldMeta("uiConfig");
              const dataConfigMeta = formik.getFieldMeta("dataConfig");
              return (
                <Flex as={Form} direction="column" gap={10} flexGrow={1}>
                  <Heading as="h1" variant="admin">
                    Edit {explorePath}
                  </Heading>
                  <Flex direction="column" gap={4}>
                    <Heading as="h2" variant="admin">
                      Instance configuration
                    </Heading>
                    <Accordion defaultIndex={[0, 1]} allowMultiple>
                      <AccordionItem
                        as={FormControl}
                        isInvalid={uiConfigMeta.error !== undefined}
                      >
                        {(row) => {
                          const error = (
                            <FormErrorMessage>
                              Invalid ui settings
                            </FormErrorMessage>
                          );
                          return (
                            <>
                              <AccordionButton justifyContent="space-between">
                                <Flex
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <Box fontSize="md">UI settings</Box>
                                  {!row.isExpanded && error}
                                </Flex>
                                <AccordionIcon />
                              </AccordionButton>

                              <AccordionPanel>
                                <JsonEditor
                                  schema={UIConfigJsonSchema}
                                  text={formik.values.uiConfig}
                                  height="20rem"
                                  onChange={(uiConfig) => {
                                    formik.setFieldValue("uiConfig", uiConfig);
                                  }}
                                  filename={`uiConfig.${explorePath}.json`}
                                  editorOptions={{ readOnly: isSaving }}
                                  hideToolbar
                                  resizable
                                />
                                {error}
                              </AccordionPanel>
                            </>
                          );
                        }}
                      </AccordionItem>
                      <AccordionItem
                        as={FormControl}
                        isInvalid={dataConfigMeta.error !== undefined}
                      >
                        {(row) => {
                          const error = (
                            <FormErrorMessage>
                              Invalid data configuration
                            </FormErrorMessage>
                          );
                          return (
                            <>
                              <AccordionButton justifyContent="space-between">
                                <Flex
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <Box fontSize="md">Data configuration</Box>
                                  {!row.isExpanded && error}
                                </Flex>
                                <AccordionIcon />
                              </AccordionButton>
                              <AccordionPanel>
                                <JsonEditor
                                  schema={DataConfigJsonSchema}
                                  text={formik.values.dataConfig}
                                  height="20rem"
                                  onChange={(dataConfig) => {
                                    formik.setFieldValue(
                                      "dataConfig",
                                      dataConfig
                                    );
                                  }}
                                  filename={`dataConfig.${explorePath}.json`}
                                  editorOptions={{ readOnly: isSaving }}
                                  hideToolbar
                                  resizable
                                />
                                {error}
                              </AccordionPanel>
                            </>
                          );
                        }}
                      </AccordionItem>
                    </Accordion>
                  </Flex>
                  <Spacer />
                  <AdminFormButtons sticky alignItems="center">
                    <Button
                      as={NavLink}
                      to={organization
                        .map((o) => Url.Admin.Organizations.edit(o.id))
                        .withDefault("#")}
                      variant="outline"
                      colorScheme="gray"
                    >
                      Cancel
                    </Button>
                    <Spacer />
                    <Button
                      type="submit"
                      isLoading={isSaving}
                      isDisabled={!formik.isValid}
                    >
                      Update instance
                    </Button>
                  </AdminFormButtons>
                </Flex>
              );
            }}
          </Formik>
        )}
      </RemoteDataView>
    </Flex>
  );
};

const Loading = () => {
  return (
    <Flex gap={4} direction="column" width="100%">
      <Skeleton height="3rem" width="28rem" />
      <Spacer />
      <Spacer />

      <Skeleton height="3rem" width="20rem" />

      <Skeleton height="1.5rem" width="15rem" />
      <Skeleton height="10rem" width="100%" />

      <Skeleton height="1.5rem" width="15rem" />
      <Skeleton height="10rem" width="100%" />
    </Flex>
  );
};

type UseExploreProps = {
  organizationId: string | undefined;
  explorePath: string | undefined;
};
type ExploreConfig = {
  uiConfig: unknown;
  dataConfig: unknown;
};
type ReturnType = {
  remoteData: RD.RemoteData<Error, ExploreConfig>;
  update: (
    uiConfig: UIConfig | "NoChange",
    dataConfig: DataConfig | "NoChange"
  ) => Promise<void>;
};
const useExploreConfig = ({
  explorePath,
  organizationId,
}: UseExploreProps): ReturnType => {
  const accessToken = useAccessToken();
  const [remoteData, setRemoteData] = useState<
    RD.RemoteData<Error, ExploreConfig>
  >(RD.loading());

  const env = useEnv();

  useEffect(() => {
    const goFetch = async () => {
      if (accessToken.status === Status.LOADING) {
        return;
      }

      if (!explorePath) {
        throw new Error("Missing explore path");
      }

      try {
        const [uiConfig, dataConfig] = await Promise.all([
          fetchConfig(env, accessToken, explorePath, "UIConfig.json"),
          fetchConfig(env, accessToken, explorePath, "dataConfig.json"),
        ]);
        setRemoteData(RD.success({ uiConfig, dataConfig }));
      } catch (error) {
        setRemoteData(
          RD.failure(
            error instanceof Error
              ? error
              : new Error("Unknown error", { cause: error })
          )
        );
      }
    };

    goFetch();
  }, [accessToken, env, explorePath]);

  const update = useCallback(
    async (
      uiConfig: UIConfig | "NoChange",
      dataConfig: DataConfig | "NoChange"
    ) => {
      if (!explorePath) {
        throw new Error("Missing explore path");
      }

      await Promise.all([
        uiConfig === "NoChange"
          ? undefined
          : updateConfig(env, accessToken, explorePath, {
              name: "UIConfig.json",
              file: uiConfig,
            }),
        dataConfig === "NoChange"
          ? undefined
          : updateConfig(env, accessToken, explorePath, {
              name: "dataConfig.json",
              file: dataConfig,
            }),
      ]);
    },
    [accessToken, env, explorePath]
  );

  if (!organizationId) {
    return {
      remoteData: RD.failure(new Error("Missing organizationId")),
      update,
    };
  }
  if (!explorePath) {
    return { remoteData: RD.failure(new Error("Missing explorePath")), update };
  }

  return { remoteData, update };
};

async function fetchConfig(
  env: Env,
  accessToken: AuthData<string>,
  explorePath: string,
  fileName: "UIConfig.json" | "dataConfig.json"
): Promise<object> {
  if (accessToken.status !== Status.OK) {
    throw new Error("Missing access token");
  }
  const response = await fetch(
    `${env.REACT_APP_EXPLORE_SERVICE_URL}/files/config/${explorePath}/${fileName}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken.data}`,
      },
    }
  );
  const json = await response.json();
  return json;
}

type File =
  | {
      name: "UIConfig.json";
      file: UIConfig;
    }
  | { name: "dataConfig.json"; file: DataConfig };
async function updateConfig(
  env: Env,
  accessToken: AuthData<string>,
  explorePath: string,
  file: File
): Promise<void> {
  if (accessToken.status !== Status.OK) {
    throw new Error("Missing access token");
  }

  const response = await fetch(
    `${env.REACT_APP_EXPLORE_SERVICE_URL}/files/config/${explorePath}/${file.name}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken.data}`,
      },
      body: JSON.stringify(file.file),
    }
  );

  if (!response.ok) {
    throw new Error(response.statusText, { cause: await response.json() });
  }
}
