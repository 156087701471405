import { Grid, GridItem, HStack, Stack, Text } from "@chakra-ui/react";
import Mustache from "mustache";
import { FunctionComponent, useMemo } from "react";
import * as EmailBody from "src/types/messages/clientBranding/emailBody";
import { RawHtmlViewer } from "src/components/HtmlViewer";
import { usePartials } from "./partials";
import { FormType } from "./types";
import { useBranding } from "src/components/Providers/BrandingProvider";

type Props = { formValue: FormType };

export const PreviewMessageTemplate: FunctionComponent<Props> = (props) => {
  const { formValue } = props;
  const partials = usePartials();

  const branding = useBranding();

  const { emailSubject, emailMarkup, emailText, smsBody } = useMemo(() => {
    if (!branding || !partials) return {};

    const _emailMarkup = formValue.emailMarkup
      ? formValue.emailMarkup
      : formValue.legacyMarkup;

    return {
      emailSubject: Mustache.render(formValue.emailSubject, partials),
      emailMarkup: _emailMarkup
        ? EmailBody.template(branding, Mustache.render(_emailMarkup, partials))
        : "",
      emailText: Mustache.render(formValue.emailText, partials),
      smsBody: Mustache.render(formValue.smsBody, partials)
    };
  }, [branding, formValue, partials]);

  return (
    <Stack gap="5" flexGrow="1">
      <Stack>
        <Text fontSize="sm">Subject line</Text>
        <Text>{emailSubject}</Text>
      </Stack>
      <Grid
        templateColumns="repeat(auto-fit, minmax(30rem, 1fr))"
        gap="5"
        templateRows="1fr"
        flexGrow="1"
      >
        <GridItem display="flex" flexGrow="1" flexDirection="column">
          <Stack minHeight="30rem" flexGrow="1">
            <Text fontSize="sm">HTML</Text>
            <RawHtmlViewer html={emailMarkup ?? ""} flexGrow="1" />
          </Stack>
        </GridItem>
        <GridItem
          display="flex"
          flexDirection="column"
          height="100%"
          flexWrap="nowrap"
        >
          <Stack height="100%">
            <Text fontSize="sm">Plain text</Text>
            <Text as="pre" overflow="auto">
              {emailText}
            </Text>
          </Stack>
        </GridItem>
      </Grid>
      <Stack>
        <Text fontSize="sm">SMS message</Text>
        <Text>{smsBody}</Text>
      </Stack>

      <HStack justifyContent="right"></HStack>
    </Stack>
  );
};
