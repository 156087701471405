import type { Spread } from "lexical";

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from "lexical";

export type SerializedVariableNode = Spread<
  {
    variableKey: string;
    type: "variable";
    version: 1;
    isValid?: boolean;
  },
  SerializedTextNode
>;

function convertVariableElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  const variableKey = domNode.getAttribute("data-lexical-variable");

  if (textContent === null) {
    return null;
  }

  if (variableKey === "schools.name") {
    return {
      node: $createVariableNode(variableKey, true),
    };
  } else {
    return {
      node: $createVariableNode(textContent),
    };
  }

  return null;
}

const variableStyle = "background-color: rgba(24, 119, 232, 0.2)";
const invalidVariableStyle =
  "text-decoration-line: underline; text-decoration-style: wavy; text-decoration-color: red; color: red;";

export class VariableNode extends TextNode {
  private __variable: string;
  private __isValid: boolean;

  constructor(variableKey: string, isValid: boolean = true, key?: NodeKey) {
    super(`{{${variableKey}}}`, key);
    this.__variable = variableKey;
    this.__isValid = isValid;
  }

  static getType(): string {
    return "variable";
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(node.__variable, node.__isValid, node.__key);
  }

  isValid(): boolean {
    return this.__isValid;
  }

  getVariableKey(): string {
    return this.__variable;
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    const node = $createVariableNode(
      serializedNode.variableKey,
      serializedNode.isValid ?? true
    );
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  exportJSON(): SerializedVariableNode {
    return {
      ...super.exportJSON(),
      variableKey: this.__variable,
      type: "variable",
      version: 1,
      isValid: this.__isValid,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cssText = this.__isValid ? variableStyle : invalidVariableStyle;
    return dom;
  }

  createVariableElement: () => HTMLElement | null = () => {
    // <ul>
    //   {{#schools}}
    //     <li>{{{name}}}</li>
    //   {{/schools}}
    // </ul>
    if (this.__variable === "schools.name") {
      const element = document.createElement("ul");
      element.appendChild(document.createTextNode("{{#schools}}"));
      const li = document.createElement("li");
      li.textContent = "{{{name}}}";
      element.appendChild(li);
      element.appendChild(document.createTextNode("{{/schools}}"));
      return element;
    }
    // <a href="{{applyUrl}}">{{applyUrl}}</a>
    if (this.__variable === "applyUrl") {
      const element = document.createElement("a");
      element.setAttribute("href", "{{{applyUrl}}}");
      element.textContent = "{{{applyUrl}}}";
      return element;
    }
    // <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>
    if (this.__variable === "supportEmail") {
      const element = document.createElement("a");
      element.setAttribute("href", "mailto:{{supportEmail}}");
      element.textContent = "{{supportEmail}}";
      return element;
    }
    return null;
  };

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-variable", this.__variable);

    const variableElement = this.createVariableElement();
    if (variableElement) {
      element.appendChild(variableElement);
    } else {
      element.textContent = this.__text;
    }
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-variable")) {
          return null;
        }
        return {
          conversion: convertVariableElement,
          priority: 1,
        };
      },
    };
  }

  isSegmented() {
    return false;
  }

  isTextEntity() {
    return false;
  }

  isToken() {
    return true;
  }

  getPlainText(): string {
    if (this.__variable === "schools.name") {
      return "{{#schools}}\n* {{{name}}}\n{{/schools}}";
    }
    if (this.__variable === "applyUrl") {
      return "{{{applyUrl}}}";
    }
    return this.getTextContent();
  }
}

export function $createVariableNode(
  variableKey: string,
  isValid: boolean = true
): VariableNode {
  // Remove wrapper {{ and }} if they exist
  const cleanedKey = variableKey.replace(/^{{|}}$/g, "");
  const variableNode = new VariableNode(cleanedKey, isValid);
  variableNode.setMode("segmented").toggleDirectionless();
  return variableNode;
}

export function $isVariableNode(
  node: LexicalNode | null | undefined
): node is VariableNode {
  return node instanceof VariableNode;
}
