import { gql } from "@apollo/client";
import { ACTION_ITEM_FRAGMENT } from "src/operations/fragments/actionItem";
import {
  CUSTOM_QUESTION_ANSWER_BANK_RELATIONSHIPS_FRAGMENT,
  FORM_VERIFICATION_RESULT_FRAGMENT,
} from "src/operations/fragments/form";
import {
  FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT,
  FORM_TEMPLATE_FRAGMENT,
  QUESTION_OPTION_FRAGMENT,
} from "src/operations/fragments/formTemplate";
import {
  PERSON_FRAGMENT,
  SIBLING_FRAGMENT,
} from "src/operations/fragments/person";
import { SCHOOL_WITH_GRADES_FRAGMENT } from "src/operations/fragments/school";

const GUARDIAN_RELATIONSHIP_FRAGMENT = gql`
  fragment GuardianRelationshipFragment on person {
    first_relationship(
      where: {
        active: { _eq: true }
        second: {
          _and: {
            organization_id: { _eq: $organization_id }
            person_type: { _eq: guardian }
            active: { _eq: true }
            deleted_at: { _is_null: true }
          }
        }
      }
    ) {
      second {
        id
        person_type
        sms_okay
        email_okay
      }
    }
    second_relationship(
      where: {
        active: { _eq: true }
        first: {
          _and: {
            organization_id: { _eq: $organization_id }
            person_type: { _eq: guardian }
            active: { _eq: true }
            deleted_at: { _is_null: true }
          }
        }
      }
    ) {
      first {
        id
        person_type
        sms_okay
        email_okay
      }
    }
  }
`;

export const GET_FORM_IDS_BY_FORM_TEMPLATE = gql`
  query GetFormIdsByFormTemplate(
    $form_template_id: uuid!
    $search: search_form_by_school_bool_exp!
  ) {
    search_form_by_school(
      where: {
        deleted_at: { _is_null: true }
        form_template_id: { _eq: $form_template_id }
        _and: [$search]
      }
    ) {
      form_id
      form_school_rank_id
      school_id
    }
  }
`;

export const GET_FORM_IDS_AND_SCHOOL_IDS = gql`
  query GetFormIdsAndSchoolIds(
    $form_template_id: uuid!
    $search: search_form_by_school_bool_exp!
  ) {
    search_form_by_school(
      where: {
        deleted_at: { _is_null: true }
        form_template_id: { _eq: $form_template_id }
        _and: [$search]
      }
    ) {
      form_id
      form {
        previous_offer {
          school {
            id
          }
        }
        previous_waitlist {
          school {
            id
          }
        }
      }
      form_school_rank {
        id
        school_id
      }
    }
  }
`;

export const GET_PREVIOUS_FORM_TEMPLATE = gql`
  query GetPreviousFormTemplate($form_template_id: uuid!) {
    form_template(
      where: {
        deleted_at: { _is_null: true }
        forms: {
          assigned_forms: {
            deleted_at: { _is_null: true }
            has_started: { _eq: false }
            form_template_id: { _eq: $form_template_id }
          }
        }
      }
    ) {
      id
      name
    }
  }
`;

export const GET_DROPOFF_FORM_IDS_BY_FORM_TEMPLATE = gql`
  query GetDropoffFormIdsByFormTemplate(
    $form_template_id: uuid!
    $previous_form_template_id: uuid!
    $search: assigned_form_bool_exp!
  ) {
    assigned_form(
      where: {
        _and: [
          {
            deleted_at: { _is_null: true }
            form_template_id: { _eq: $form_template_id }
            previous_form: {
              form_template_id: { _eq: $previous_form_template_id }
            }
            has_started: { _eq: false }
          }
          $search
        ]
      }
    ) {
      id
      previous_offer {
        school {
          id
          name
        }
        grade {
          grade_config {
            id
            label
          }
        }
      }
      previous_waitlist {
        school {
          id
          name
        }
        grade {
          grade_config {
            id
            label
          }
        }
      }
      previous_form {
        id
        form_school_ranks {
          id
          school_id
        }
      }
    }
  }
`;

export const GET_DROPOFF_FORMS_BY_FORM_TEMPLATE = gql`
  ${FORM_TEMPLATE_FRAGMENT}
  ${GUARDIAN_RELATIONSHIP_FRAGMENT}

  query GetDropoffFormsByFormTemplate(
    $organization_id: uuid!
    $form_template_id: uuid!
    $previous_form_template_id: uuid!
    $search: assigned_form_bool_exp!
    $offset: Int
    $limit: Int
    $skip_rank: Boolean!
  ) {
    assigned_form_aggregate(
      where: {
        _and: [
          {
            deleted_at: { _is_null: true }
            form_template_id: { _eq: $form_template_id }
            previous_form: {
              form_template_id: { _eq: $previous_form_template_id }
            }
            has_started: { _eq: false }
          }
          $search
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    assigned_form(
      where: {
        _and: [
          {
            deleted_at: { _is_null: true }
            form_template_id: { _eq: $form_template_id }
            previous_form: {
              form_template_id: { _eq: $previous_form_template_id }
            }
            has_started: { _eq: false }
          }
          $search
        ]
      }

      offset: $offset
      limit: $limit
    ) {
      id
      previous_offer {
        school {
          id
          name
        }
        grade {
          grade_config {
            id
            label
          }
        }
        status_updated_at
      }
      previous_waitlist {
        status_updated_at
        school {
          id
          name
        }
        grade {
          grade_config {
            id
            label
          }
        }
      }
      previous_form {
        id
        status
        status_updated_at
        submitted_before
        form_template_id
        form_school_ranks {
          id
          rank @skip(if: $skip_rank)
          sub_status
          school_id
          tags {
            created_at
            enrollment_period_tag {
              id
              name
              tag_group_id
            }
          }
          form_school_offer_status_history {
            submitted_at
          }
        }
        form_school_tags {
          created_at
          enrollment_period_tag {
            id
            name
          }
        }
        person {
          id
          first_name
          last_name
          reference_id
          ...GuardianRelationshipFragment
        }
      }
    }
    form_template_by_pk(id: $previous_form_template_id) {
      ...FormTemplateFragment
    }
    grade_config(where: { organization_id: { _eq: $organization_id } }) {
      id
      label
    }
  }
`;

export const GET_FORMS_BY_FORM_TEMPLATE = gql`
  query GetFormsByFormTemplate(
    $form_template_id: uuid!
    $search: search_form_by_school_bool_exp!
    $order_by: search_form_by_school_order_by!
    $offset: Int
    $limit: Int
    $skip_rank: Boolean!
    $include_waitlist_position: Boolean!
    $enrollment_period_id: uuid!
  ) {
    search_form_by_school_aggregate(
      where: {
        deleted_at: { _is_null: true }
        form_template_id: { _eq: $form_template_id }
        _and: [$search]
      }
    ) {
      aggregate {
        count
      }
    }
    search_form_by_school(
      where: {
        deleted_at: { _is_null: true }
        form_template_id: { _eq: $form_template_id }
        _and: [$search]
      }
      order_by: [$order_by, { form_id: desc }, { form_school_rank_id: desc }]
      limit: $limit
      offset: $offset
    ) {
      form {
        id
        status
        status_updated_at
        submitted_before
        is_hidden_from_parent
        person {
          id
          reference_id
          first_name
          last_name
          person_bins(
            where: {
              bin: { enrollment_period_id: { _eq: $enrollment_period_id } }
            }
          ) {
            id
            bin_id
            lottery_number
            person_id
            bin {
              grades {
                id
                school_id
                grade_config {
                  id
                }
              }
            }
          }
        }
        grades_answers {
          grade_config {
            id
            label
          }
        }
        form_verification_results {
          verification_status
          form_verification {
            id
          }
        }
        tags {
          created_at
          enrollment_period_tag {
            id
            name
          }
        }
        previous_offer {
          school {
            name
          }
          grade {
            grade_config {
              id
              label
            }
          }
        }
        previous_waitlist {
          school {
            name
          }
          grade {
            grade_config {
              id
              label
            }
          }
        }
        previous_form {
          grades_answers {
            grade_config {
              label
            }
          }
        }
      }
      form_school_rank {
        id
        rank @skip(if: $skip_rank)
        sub_status
        school {
          id
          name
        }
        offers(where: { deleted_at: { _is_null: true } }) {
          status_updated_at
        }
        waitlists(where: { deleted_at: { _is_null: true } }) {
          grade {
            grade_config_id
          }
          status_updated_at
        }
        form_school_offer_status_history {
          submitted_at
        }
        tags(order_by: { enrollment_period_tag: { name: asc } }) {
          created_at
          enrollment_period_tag {
            name
            tag_group_id
          }
        }
        lottery_order
      }
      waitlist_position @include(if: $include_waitlist_position)
    }
  }
`;

export const GET_METADATA_BY_FORM_TEMPLATE = gql`
  ${FORM_TEMPLATE_FRAGMENT}
  query GetMetadataByFormTemplate(
    $form_template_id: uuid!
    $program_equals_exp: grade_bool_exp!
    $skip_capacities: Boolean!
  ) {
    form_template_by_pk(id: $form_template_id) {
      ...FormTemplateFragment
      enrollment_period @skip(if: $skip_capacities) {
        grades(where: $program_equals_exp) {
          school_id
          grade_config_id
          program_id
          seats_available
        }
      }
    }
  }
`;

export const GET_PREVIOUS_FORM_BY_FORM_ID = gql`
  ${PERSON_FRAGMENT}
  query GetPreviousFormByFormId($form_id: uuid!) {
    form_by_pk(id: $form_id) {
      id
      form_template {
        id
        enrollment_period_id
      }

      person {
        ...PersonFragment
      }

      form_tags {
        enrollment_period_tag {
          name
        }
      }

      next_forms(where: { deleted_at: { _is_null: true } }) {
        id
        form_template_id
        form_template {
          name
        }
      }

      previous_form {
        id
        form_template_id
        form_template {
          name
        }
        form_school_tags {
          school_id
          enrollment_period_tag {
            id
            name
          }
        }
        form_school_ranks {
          id
          school_id
        }
        grades_answers {
          grade_config {
            label
          }
        }
      }

      previous_offer {
        school {
          id
          name
        }
        grade {
          grade_config {
            label
          }
        }
      }

      previous_waitlist {
        school {
          id
          name
        }
        grade {
          grade_config {
            label
          }
        }
      }
    }
  }
`;

// This query is shared between parents and admin, but they have different permissions.
// TODO: split this (and other shared queries)
export const GET_FORM_VIEW_BY_ID = gql`
  ${FORM_TEMPLATE_FRAGMENT}
  ${FORM_VERIFICATION_RESULT_FRAGMENT}
  ${CUSTOM_QUESTION_ANSWER_BANK_RELATIONSHIPS_FRAGMENT}
  ${PERSON_FRAGMENT}
  ${SIBLING_FRAGMENT}

  query GetFormViewById(
    $form_id: uuid!
    $organization_id: uuid!
    $is_admin: Boolean!
  ) {
    form_by_pk(id: $form_id) {
      id
      status
      status_updated_at
      submitted_at
      is_hidden_from_parent

      form_attending_school {
        school {
          id
          name
        }
      }

      form_template {
        ...FormTemplateFragment
        enrollment_period {
          active
          name
        }
      }

      form_verification_results {
        ...FormVerificationResultFragment
      }

      custom_question_answer_bank_relationships @include(if: $is_admin) {
        ...CustomQuestionAnswerBankRelationshipsFragment
      }

      form_disclaimers(order_by: { signed_at: desc }) {
        id
        signature
        signed_at
      }

      person {
        ...PersonFragment
        first_relationship(
          where: {
            active: { _eq: true }
            second: {
              _and: {
                organization_id: { _eq: $organization_id }
                person_type: { _eq: guardian }
              }
            }
          }
        ) {
          second {
            ...PersonFragment
            person_type

            first_relationship(
              where: {
                active: { _eq: true }
                second: {
                  _and: {
                    organization_id: { _eq: $organization_id }
                    person_type: { _eq: applicant }
                  }
                }
              }
            ) {
              second {
                ...SiblingFragment
              }
            }

            second_relationship(
              where: {
                active: { _eq: true }
                first: {
                  _and: {
                    organization_id: { _eq: $organization_id }
                    person_type: { _eq: applicant }
                  }
                }
              }
            ) {
              first {
                ...SiblingFragment
              }
            }
          }
        }
        second_relationship(
          where: {
            active: { _eq: true }
            first: {
              _and: {
                organization_id: { _eq: $organization_id }
                person_type: { _eq: guardian }
              }
            }
          }
        ) {
          first {
            ...PersonFragment
            person_type

            first_relationship(
              where: {
                active: { _eq: true }
                second: {
                  _and: {
                    organization_id: { _eq: $organization_id }
                    person_type: { _eq: applicant }
                  }
                }
              }
            ) {
              second {
                ...SiblingFragment
              }
            }

            second_relationship(
              where: {
                active: { _eq: true }
                first: {
                  _and: {
                    organization_id: { _eq: $organization_id }
                    person_type: { _eq: applicant }
                  }
                }
              }
            ) {
              first {
                ...SiblingFragment
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTOM_QUESTION_TYPES_BY_ORG = gql`
  ${FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT}
  ${QUESTION_OPTION_FRAGMENT}
  query GetCustomQuestionTypesByOrg($organization_id: uuid!) {
    custom_question_type(
      where: { organization_id: { _eq: $organization_id } }
    ) {
      id
      name
      custom_question_type_fields(order_by: { question: { order: asc } }) {
        question_id
        question {
          ...FormQuestionWithoutBranchingFragment
        }
      }
    }
  }
`;

export const GET_SCHOOLS_RANK_VIEW = gql`
  ${SCHOOL_WITH_GRADES_FRAGMENT}
  ${FORM_VERIFICATION_RESULT_FRAGMENT}

  query GetSchoolsRankView(
    $form_id: uuid!
    $enrollment_period_id: uuid!
    $skip_rank: Boolean!
    $filter: form_school_rank_bool_exp!
  ) {
    form_school_rank(
      where: { _and: [{ form_id: { _eq: $form_id } }, $filter] }
    ) {
      id
      rank @skip(if: $skip_rank)
      sub_status
      school {
        ...SchoolWithGradesFragment
      }
      form {
        id
        status
        form_verification_results {
          ...FormVerificationResultFragment
        }
      }
      form_verifications_flattened {
        verifications
      }
      offers(where: { deleted_at: { _is_null: true } }) {
        id
        status
        grade {
          program {
            label
          }
        }
      }
      waitlists(where: { deleted_at: { _is_null: true } }) {
        id
        status
        waitlist_position {
          position
        }
      }
      tags(order_by: { enrollment_period_tag: { name: asc } }) {
        id
        enrollment_period_tag {
          id
          name
          tag_group_id
          tag_group {
            id
            name
          }
        }
      }
    }
    tag_group(
      where: { enrollment_period_id: { _eq: $enrollment_period_id } }
      order_by: { name: asc }
    ) {
      id
      name
      is_external
      max_tags
    }
  }
`;

export const GET_TAG_GROUPS_BY_ENROLLMENT_PERIOD = gql`
  query GetTagGroupsByEnrollmentPeriod(
    $enrollment_period_id: uuid!
    $is_external: Boolean_comparison_exp = {}
  ) {
    tag_group(
      where: {
        enrollment_period_id: { _eq: $enrollment_period_id }
        is_external: $is_external
      }
      order_by: { name: asc }
    ) {
      id
      name
      is_external
      max_tags
      is_default
    }
  }
`;

export const GET_SCHOOLS_FOR_FILTERS = gql`
  query GetSchoolsForFilters($form_template_id: uuid!, $skip_rank: Boolean!) {
    form_school_rank(
      where: {
        _and: {
          form: { form_template_id: { _eq: $form_template_id } }
          school: { status: { _eq: "Active" } }
        }
      }
      order_by: { rank: asc }
      distinct_on: [rank]
    ) @skip(if: $skip_rank) {
      rank
    }
    school(
      where: {
        status: { _eq: "Active" }
        _or: [
          {
            form_school_ranks: {
              form: {
                form_template_id: { _eq: $form_template_id }
                deleted_at: { _is_null: true }
              }
            }
          }
          {
            offers: {
              previous_forms: {
                form_template_id: { _eq: $form_template_id }
                deleted_at: { _is_null: true }
              }
            }
          }
          {
            waitlists: {
              previous_forms: {
                form_template_id: { _eq: $form_template_id }
                deleted_at: { _is_null: true }
              }
            }
          }
        ]
      }
    ) {
      id
      name
    }
    attending_school: school(
      where: {
        status: { _eq: "Active" }
        form_attending_schools: {
          form_template_id: { _eq: $form_template_id }
          deleted_at: { _is_null: true }
        }
      }
    ) {
      id
      name
    }
  }
`;

export const GET_RANKS_FOR_FILTERS = gql`
  query GetRanksForFilters($form_template_id: uuid!, $skip_rank: Boolean!) {
    form_school_rank(
      where: {
        _and: { form: { form_template_id: { _eq: $form_template_id } } }
      }
      order_by: { rank: asc }
      distinct_on: [rank]
    ) {
      rank @skip(if: $skip_rank)
    }
  }
`;

export const GET_FORM_TAGS_FOR_FILTERS = gql`
  query GetFormTagsForFilters($form_template_id: uuid!) {
    enrollment_period_tag(
      where: {
        form_tags: { form: { form_template_id: { _eq: $form_template_id } } }
      }
      order_by: { name: asc }
      distinct_on: [name]
    ) {
      id
      name
    }
  }
`;

export const GET_TAG_GROUPS_FOR_FILTERS = gql`
  query GetTagGroupsForFilters($enrollment_period_id: uuid!) {
    tag_group(
      where: { enrollment_period_id: { _eq: $enrollment_period_id } }
      order_by: { name: asc }
    ) {
      id
      name
      tags(order_by: { name: asc }) {
        id
        name
      }
    }
  }
`;

export const GET_PROGRAMS_FOR_CAPACITIES_FILTERS = gql`
  query GetProgramsForCapacitiesFilter($organization_id: uuid!) {
    program_group(where: { organization_id: { _eq: $organization_id } }) {
      programs(order_by: { order: asc }) {
        id
        label
      }
    }
  }
`;

export const GET_VERIFICATIONS_FOR_FILTERS = gql`
  query GetVerificationsForFilters($form_template_id: uuid!) {
    form_verification(
      where: {
        _and: {
          form_questions: {}
          form_template_id: { _eq: $form_template_id }
        }
      }
    ) {
      id
      label
    }
  }
`;

export const GET_FORM_HISTORY = gql`
  ${FORM_TEMPLATE_FRAGMENT}

  query GetFormHistory(
    $form_id: uuid!
    $organization_id: uuid!
    $offset: Int
    $limit: Int
  ) {
    audit_form_transaction(
      where: { form_id: { _eq: $form_id } }
      order_by: { action_tstamp_tx: desc }
      limit: $limit
      offset: $offset
    ) {
      transaction_id
      action_tstamp_tx
      logged_actions(
        where: {
          _or: [
            {
              table_name: { _in: ["application", "form"] }
              row_id: { _eq: $form_id }
            }
            # Events in adjacent tables that directly reference the form.
            { form_id: { _eq: $form_id } }
            # Events that don't directly reference any specific forms.
            {
              table_name: { _nin: ["application", "form"] }
              form_id: { _is_null: true }
            }
          ]
        }
        order_by: { action_tstamp_clk: desc }
      ) {
        user {
          people(
            where: {
              _or: [
                { organization_id: { _eq: $organization_id } }
                { person_type: { _eq: admin } }
              ]
            }
          ) {
            id
            full_name
            person_type
          }
        }
        action
        action_tstamp_clk
        table_name
        row_data
        changed_fields
      }
    }
    audit_form_transaction_aggregate(where: { form_id: { _eq: $form_id } }) {
      aggregate {
        count
      }
    }
    form_by_pk(id: $form_id) {
      form_template {
        ...FormTemplateFragment
      }
    }
    school(where: { organization_id: { _eq: $organization_id } }) {
      id
      name
    }
    grade(
      where: {
        enrollment_period: { organization_id: { _eq: $organization_id } }
      }
    ) {
      id
      grade_config {
        id
        label
      }
      program {
        id
        label
      }
    }
    enrollment_period_tag(
      where: {
        enrollment_period: { organization_id: { _eq: $organization_id } }
      }
    ) {
      id
      name
    }
  }
`;

export const FETCH_ACTION_ITEMS = gql`
  ${ACTION_ITEM_FRAGMENT}

  query FetchActionItems($form_id: uuid!, $status: action_item_status_enum!) {
    action_item(
      where: { status: { _eq: $status }, form_id: { _eq: $form_id } }
    ) {
      ...ActionItemFragment
    }
  }
`;

export const EXPORT_QUESTIONS_AND_VERIFICATIONS = gql`
  query ExportQuestionsAndVerifications($form_template_id: uuid!) {
    form_verification(
      where: {
        _and: {
          form_questions: {}
          form_template_id: { _eq: $form_template_id }
        }
      }
    ) {
      id
      label
    }
    question_by_form_template(
      where: { form_template_id: { _eq: $form_template_id } }
    ) {
      question_id
      question {
        type
        question
        form_question {
          form_question_options {
            id
            label
          }
        }
        constraints
        custom_question {
          custom_question_relationships(
            order_by: { cloned_question: { order: asc } }
          ) {
            cloned_question_id
          }
        }
        custom_question_relationship {
          custom_question_type_field_id
        }
      }
    }
    form_template_section(
      where: {
        _and: {
          form_template_id: { _eq: $form_template_id }
          type: { _eq: DisclaimerSection }
        }
      }
    ) {
      title
    }
  }
`;

export const EXPORT_FORMS = gql`
  query ExportForms(
    $form_ids: [uuid!]
    $form_school_rank_ids: [uuid!]
    $skip_rank: Boolean!
  ) {
    form(where: { id: { _in: $form_ids }, deleted_at: { _is_null: true } }) {
      id
      status
      status_updated_at
      submitted_at
      form_attending_school {
        school_id
        school {
          name
        }
        attendance_status
      }
      person {
        id
        reference_id
        first_name
        middle_name
        last_name
        birth_date
        street_address
        street_address_line_2
        city
        state
        zip_code
        phone_number
        email_address
        preferred_language
      }
      applicant_guardians {
        guardian {
          id
          reference_id
          first_name
          middle_name
          last_name
          street_address
          street_address_line_2
          city
          state
          zip_code
          phone_number
          email_address
          preferred_language
        }
      }
      form_answers {
        id
        question_id
        free_text_answer
        form_answer_options {
          form_question_option_id
        }
        number_answer
        date_answer
      }
      grades_answers {
        question_id
        grade_config {
          label
        }
      }
      form_addresses {
        id
        question_id
        city
        state
        street_address
        street_address_line_2
        zip_code
      }
      custom_question_answer_bank_relationships {
        custom_question_id
        person_answer_bank {
          id
          person_id
          person_answer_bank_external_relationship {
            external_id
          }
        }
      }
      form_disclaimers {
        signature
      }
      form_verification_results {
        form_verification_id
        verification_status
      }
      tags(order_by: { enrollment_period_tag: { name: asc } }) {
        enrollment_period_tag {
          name
        }
      }
      previous_offer {
        school {
          id
          name
        }
        grade {
          grade_config {
            label
          }
        }
      }
      previous_waitlist {
        school {
          id
          name
        }
        grade {
          grade_config {
            label
          }
        }
      }
      previous_form {
        grades_answers {
          grade_config {
            label
          }
        }
      }
      form_school_ranks(where: { id: { _in: $form_school_rank_ids } }) {
        id
        form_id
        rank @skip(if: $skip_rank)
        lottery_order
        status
        school {
          id
          name
        }
        tags(order_by: { enrollment_period_tag: { name: asc } }) {
          enrollment_period_tag {
            name
            tag_group_id
          }
        }
        form_school_offer_status_history {
          submitted_at
        }
        offers(where: { deleted_at: { _is_null: true } }) {
          id
          status
          status_updated_at
          grade {
            program {
              id
              label
            }
          }
        }
        waitlists(where: { deleted_at: { _is_null: true } }) {
          id
          status
          status_updated_at
          waitlist_position {
            position
          }
        }
      }
    }
  }
`;

export const FETCH_FORM_STATUS = gql`
  query FetchFormStatus($form_ids: [uuid!]) {
    form(where: { id: { _in: $form_ids } }, distinct_on: status) {
      status
    }
  }
`;

export const FETCH_FORM_RECIPIENTS_MAX_INPUT_SIZE = 500;
export const FETCH_FORM_RECIPIENTS = gql`
  ${GUARDIAN_RELATIONSHIP_FRAGMENT}
  query FetchFormRecipients($form_ids: [uuid!]!, $organization_id: uuid!) {
    form(where: { id: { _in: $form_ids } }) {
      person {
        id
        ...GuardianRelationshipFragment
      }
    }
  }
`;

export const FETCH_OFFER_WAITLIST_STATUS_MAX_INPUT_SIZE = 500;
export const FETCH_OFFER_WAITLIST_STATUS = gql`
  query FetchOfferWaitlistStatus($search_keys: [form_school_rank_bool_exp!]!) {
    form_school_rank(where: { _or: $search_keys }) {
      id
      form_id
      school_id
      sub_status
      form {
        status
      }
      offers(where: { deleted_at: { _is_null: true } }) {
        id
        status
        assigned_forms {
          id
        }
      }
      waitlists(where: { deleted_at: { _is_null: true } }) {
        id
        status
        assigned_forms {
          id
        }
      }
    }
  }
`;

export const FETCH_GRADE_IDS_WITH_FORM_SCHOOL_MAX_INPUT_SIZE = 500;
export const FETCH_GRADE_IDS_WITH_FORM_SCHOOL = gql`
  query FetchGradeIdsWithFormSchool($query: form_school_grade_bool_exp!) {
    form_school_grade(where: $query) {
      form_id
      school_id
      grade_id
      form_school_rank_id
    }
  }
`;

export const FETCH_SEATS_AVAILABLE = gql`
  query FetchSeatsAvailable($grade_ids: [uuid!]) {
    grade(where: { id: { _in: $grade_ids } }) {
      id
      school {
        name
      }
      grade_config {
        label
      }
      seats_available
    }
  }
`;

export const GET_PROGRAM_GROUPS_BY_ORG = gql`
  query GetProgramGroupsByOrg($organization_id: uuid) {
    program_group(where: { organization_id: { _eq: $organization_id } }) {
      id
      name
      organization_id
      programs {
        id
        label
        order
      }
    }
  }
`;

export const GET_VERIFICATIONS_FOR_ADMIN = gql`
  query GetVerificationsForAdmin($form_id: uuid!) {
    form_verification(
      where: {
        _and: {
          form_questions: {}
          form_template: { forms: { id: { _in: [$form_id] } } }
        }
      }
    ) {
      id
      label
      form_questions {
        question_id
      }
    }
    form_verification_result(where: { form_id: { _eq: $form_id } }) {
      id
      form_verification_id
      verification_status
    }
  }
`;

export const GET_APPLICABLE_VERIFICATIONS_SUMMARIES = gql`
  query GetApplicableVerificationsSummaries(
    $form_template_id: uuid!
    $form_ids: [uuid!]!
  ) {
    form_verification(where: { form_template_id: { _eq: $form_template_id } }) {
      id
      label
      form_questions {
        question {
          form_template_section_id
          ancestor_questions {
            form_question_option {
              form_answer_options(
                distinct_on: form_answer_id
                where: { form_answer: { form_id: { _in: $form_ids } } }
              ) {
                form_answer {
                  form_id
                }
              }
            }
          }
        }
      }
      form_verification_results(where: { form_id: { _in: $form_ids } }) {
        form_id
        verification_status
      }
    }
  }
`;

export const GET_FORM_MESSAGES = gql`
  query GetFormMessages(
    $form_id: uuid!
    $recipient_person_ids: [uuid!]!
    $message_receiver_filter: message_adhoc_receiver_bool_exp!
    $skip_content: Boolean!
  ) {
    message_adhoc_receiver(
      where: {
        receiver_person_id: { _in: $recipient_person_ids }
        message_adhoc: { message_adhoc_forms: { form_id: { _eq: $form_id } } }
        _and: [$message_receiver_filter]
      }
    ) {
      message_id
      message_type
      sent_at
      message_adhoc {
        sender_person {
          full_name
          user {
            name
          }
        }
        message_adhoc_payload_email {
          message_body
          message_subject
        }
        message_adhoc_payload_sms {
          message_body
        }
      }
      receiver_person {
        phone_number
        email_address
      }
      receiver_contact_information
      message_status
    }
    message_form_status(where: { form_id: { _eq: $form_id } }) {
      id
      message_type
      form_transition
      created_at
      form {
        form_template {
          enrollment_period {
            organization {
              id
              name
            }
          }
        }
      }
      receiver_contact_information
      message_subject @skip(if: $skip_content)
      message_body @skip(if: $skip_content)
      message_status
    }
    message_offer_status(where: { offer: { form_id: { _eq: $form_id } } }) {
      id
      message_type
      offer_transition
      created_at
      offer {
        form {
          form_template {
            enrollment_period {
              organization {
                id
                name
              }
            }
          }
        }
      }
      receiver_contact_information
      message_subject @skip(if: $skip_content)
      message_body @skip(if: $skip_content)
      message_status
    }
    message_waitlist_status(
      where: { waitlist: { form_id: { _eq: $form_id } } }
    ) {
      id
      message_type
      waitlist_transition
      created_at
      waitlist {
        form {
          form_template {
            enrollment_period {
              organization {
                id
                name
              }
            }
          }
        }
      }
      receiver_contact_information
      message_subject @skip(if: $skip_content)
      message_body @skip(if: $skip_content)
      message_status
    }
  }
`;

export const GET_ASSIGNED_FORMS_BY_FORM_ID = gql`
  query GetAssignedFormsByFormId($form_id: uuid!) {
    assigned_form(
      where: {
        has_started: { _eq: false }
        deleted_at: { _is_null: true }
        previous_form_id: { _eq: $form_id }
      }
    ) {
      id
      form_template {
        id
        name
      }
      person_id
      person {
        first_name
      }
      previous_form_id
      previous_offer_id
      previous_waitlist_id
    }
  }
`;
