import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Stack
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { LexicalEditor } from "lexical";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { FormMustacheInput } from "src/components/Inputs/FormMustacheInput";
import { EditorRef } from "src/components/WysiwygEditor/lexical/Editor";
import FormikLexicalEditor from "src/components/WysiwygEditor/lexical/FormikEditor";
import {
  editorStateToHtml,
  editorStateToPlainText,
  importHtml
} from "src/components/WysiwygEditor/lexical/utils/converters";
import { GENERIC_ERROR, MESSAGE_TEMPLATES } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useMessageRichContent } from "src/hooks/useMessageRichContent";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { SmsInputStats } from "src/scenes/orgAdmin/components/Forms/MessageForm";
import { FormTemplateOutletContext } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/Edit";
import {
  hasChanges,
  MessageTemplateId,
  toOriginal
} from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/common";
import { validateWithZod } from "src/services/formValidations";
import { MessageTemplate } from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { UPDATE_MESSAGE_TEMPLATE } from "../graphql/mutations";
import {
  GET_MESSAGE_TEMPLATE,
  GET_MESSAGE_TEMPLATE_TYPES
} from "../graphql/queries";
import { PreviewMessageTemplate } from "./components/PreviewMessageTemplate";
import { FormSchema, FormType } from "./components/types";
import { VariablesContext } from "src/types/messages/variables";
import { useEditFormTemplateEditMessagesContext } from "./context";
import { EditMessageTemplateActionBar } from "./EditMessageTemplateActionBar";

const FORM_ID = "edit-form-templates-edit-message-template";

interface Props {
  isLoading: boolean;
  messageEnabled?: boolean;
  existingContentId?: string;
}

export const EditMessageTemplateForm: FunctionComponent<Props> = ({
  isLoading,
  messageEnabled,
  existingContentId
}) => {
  const organization = useOrganization();
  const {
    formTemplateId = "",
    messageTemplateType = "",
    enrollmentPeriodId = "",
    isViewOnly = ""
  } = useParams();
  const [isPreview, setIsPreview] = useState(isViewOnly === "true");
  const { actionBarRef } = useOutletContext<FormTemplateOutletContext>();
  const { state, dispatch } = useEditFormTemplateEditMessagesContext();

  const templateType = messageTemplateType as GQL.message_template_type_enum;

  const [updateMessageTemplate] = useRemoteDataMutation<
    GQL.UpdateMessageTemplate,
    GQL.UpdateMessageTemplateVariables
  >(UPDATE_MESSAGE_TEMPLATE);

  const toast = useAvelaToast();
  const navigate = useNavigate();

  const [emailEditor, setEmailEditor] = useState<LexicalEditor>();
  const emailEditorRef: EditorRef = (editor: LexicalEditor) => {
    if (editor != null) setEmailEditor(editor);
  };

  const { uploadRichContent, deleteRichContent } =
    useMessageRichContent(emailEditor);

  const getEmailMarkup = useCallback(() => {
    if (emailEditor) {
      return editorStateToHtml(emailEditor);
    }
  }, [emailEditor]);

  const getEmailText = useCallback(() => {
    if (emailEditor) {
      return editorStateToPlainText(emailEditor);
    }
  }, [emailEditor]);

  const getEmailLexical = useCallback(() => {
    if (emailEditor) {
      return JSON.stringify(emailEditor.getEditorState().toJSON());
    }
  }, [emailEditor]);

  const variablesContext = useMemo(
    () =>
      ({
        type: "triggered",
        messageTemplateType: templateType
      } satisfies VariablesContext),
    [templateType]
  );

  const onSubmit = useCallback(
    async (values: FormType) => {
      try {
        const { document_id, emailDocument } = await uploadRichContent();

        await updateMessageTemplate({
          variables: {
            objects: {
              form_template_id: formTemplateId,
              type: templateType,
              email_subject: values.emailSubject,
              email_plain_text: values.emailText,
              email_markup: emailDocument?.html,
              sms_body: values.smsBody,
              enabled: messageEnabled ?? false,
              message_template_rich_contents: {
                data: [
                  {
                    content_id: document_id
                  }
                ]
              }
            },
            existing_content_id: existingContentId ? existingContentId : null,
            skip_delete: !existingContentId
          },
          refetchQueries: [GET_MESSAGE_TEMPLATE, GET_MESSAGE_TEMPLATE_TYPES]
        });

        if (existingContentId) {
          await deleteRichContent(existingContentId);
        }

        if (dispatch) {
          dispatch({
            type: "Refresh",
            initialValues: { editMessages: values },
            messageTemplateType: templateType
          });
        }

        toast({
          title: "Template updated",
          status: "success",
          containerStyle: {
            marginBottom: "6rem"
          }
        });

        // doing this after 250ms to allow dispatcher to work to Refresh the state
        // TODO: this can be solved with adding a new dispatch type "Submit" and "SubmitComplete"
        setTimeout(
          () =>
            navigate(
              organization
                .map((org) =>
                  MessageTemplate.index(org, enrollmentPeriodId, formTemplateId)
                )
                .withDefault("#")
            ),
          250
        );
      } catch (err: unknown) {
        console.error(err);
        toast.error(GENERIC_ERROR);
      }
    },
    [
      uploadRichContent,
      updateMessageTemplate,
      formTemplateId,
      templateType,
      messageEnabled,
      existingContentId,
      dispatch,
      toast,
      deleteRichContent,
      navigate,
      organization,
      enrollmentPeriodId
    ]
  );

  const draft = useMemo(
    () =>
      state?.get("editMessages")?.get(MessageTemplateId(templateType))?.draft,
    [templateType, state]
  );

  const original = useMemo(
    () =>
      state?.get("editMessages")?.get(MessageTemplateId(templateType))
        ?.original,
    [templateType, state]
  );

  useEffect(() => {
    // do nothing if not resetting
    if (!state?.get("reset")) return;

    // if editor or original not found, do nothing
    if (!emailEditor || !original) return;

    // if original has lexical state json, parse it and set the editor state
    if (original.emailLexical) {
      emailEditor.update(() => {
        if (!original.emailLexical) return;

        const parsedEditorState = emailEditor.parseEditorState(
          original.emailLexical
        );
        emailEditor.setEditorState(parsedEditorState);
      });
    }
    // if original has legacy markup, import it
    else if (original.legacyMarkup) {
      importHtml(emailEditor, original.legacyMarkup);
    }

    // changes are done, dispatch reset complete
    if (dispatch) {
      dispatch({
        type: "ResetComplete"
      });
    }
  }, [dispatch, emailEditor, original, state]);

  const formikInitialValues = useMemo(() => {
    if (draft) {
      return toOriginal(draft);
    } else {
      return original;
    }
  }, [draft, original]);

  const hasChangesResult = useMemo(
    () => hasChanges(original, draft),
    [original, draft]
  );

  const handleValidate = useCallback(
    async (values: FormType) => {
      if (!emailEditor) return;
      const updatedValues = {
        ...values,
        emailMarkup: getEmailMarkup(),
        emailText: getEmailText() ?? "",
        emailLexical: getEmailLexical(),
        isDraft: true as const
      };

      if (!hasChanges(original, updatedValues)) return;

      if (dispatch) {
        dispatch({
          type: "UpdateEditMessages",
          editMessages: updatedValues,
          messageTemplateType: templateType
        });

        dispatch({
          type: "Save",
          messageTemplateType: templateType
        });
      }

      return await validateWithZod(FormSchema)(updatedValues);
    },
    [
      dispatch,
      emailEditor,
      getEmailLexical,
      getEmailMarkup,
      getEmailText,
      original,
      templateType
    ]
  );

  if (!formikInitialValues) return <GenericError />;

  if (!formTemplateId || !templateType) {
    return <GenericError />;
  }

  return (
    <Formik<FormType>
      initialValues={formikInitialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validate={handleValidate}
    >
      {({ values, errors, isSubmitting }) => {
        const handleResetForm = () => {
          // Reset the message template state
          if (dispatch) {
            dispatch({
              type: "Reset",
              messageTemplateType: templateType
            });
          }
        };

        return (
          <Flex
            as={Form}
            id={FORM_ID}
            gap={4}
            minHeight="100%"
            direction="column"
          >
            <Heading as="h2" fontSize="xl" my={4} fontWeight={600}>
              {isPreview ? "Preview " : "Edit "}
              {MESSAGE_TEMPLATES[templateType].label}
            </Heading>
            <Flex direction="column" gap={4}>
              <Heading as="h3" fontSize="md" fontWeight={400}>
                Email message
              </Heading>
              {isPreview ? (
                <PreviewMessageTemplate formValue={values} />
              ) : (
                <Stack gap="5" flexGrow="1">
                  {isLoading ? (
                    <Stack gap="5">
                      <Skeleton height="8" />
                      <Grid
                        templateColumns="repeat(auto-fit, minmax(30rem, 1fr))"
                        gap="5"
                        templateRows="auto"
                      >
                        <GridItem>
                          <Skeleton height="32" />
                        </GridItem>
                        <GridItem>
                          <Skeleton height="32" />
                        </GridItem>
                      </Grid>
                      <Skeleton height="8" />
                    </Stack>
                  ) : (
                    <>
                      <FormMustacheInput<FormType>
                        id="emailMarkup"
                        name="emailSubject"
                        label="Subject line"
                      />

                      <Grid
                        templateColumns="repeat(auto-fit, minmax(30rem, 1fr))"
                        gap="5"
                        templateRows="auto"
                        flexGrow="0"
                      >
                        <FormikLexicalEditor
                          name="emailLexical"
                          placeholder="Email content"
                          height="300px"
                          width="100%"
                          label="Content"
                          labelProps={{ fontSize: "sm", color: "gray.700" }}
                          isRequired={true}
                          htmlContent={values.legacyMarkup}
                          editorRef={emailEditorRef}
                          settings={{
                            enableVariables: true,
                            variablesContext
                          }}
                        />
                      </Grid>
                      <Flex direction="column" gap={4}>
                        <Heading as="h3" fontSize="md" fontWeight={400}>
                          SMS message
                        </Heading>
                        <FormMustacheInput<FormType>
                          name="smsBody"
                          initialValidationEnabled
                        />
                        {!errors.smsBody && (
                          <SmsInputStats value={values.smsBody} />
                        )}
                      </Flex>
                    </>
                  )}
                </Stack>
              )}
            </Flex>
            <EditMessageTemplateActionBar
              actionBarRef={actionBarRef}
              formId={FORM_ID}
              isPreview={isPreview}
              isViewOnly={isViewOnly === "true"}
              setIsPreview={setIsPreview}
              formTemplateId={formTemplateId}
              hasChanges={hasChangesResult}
              onClearChanges={handleResetForm}
              isSubmitting={isSubmitting}
            />
          </Flex>
        );
      }}
    </Formik>
  );
};
