import { useMutation } from "@apollo/client";
import {
  Box,
  Divider,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RiMore2Fill } from "react-icons/ri";
import { RouterLink } from "src/components/Links/RouterLink";
import { Glossary } from "src/components/Text/Glossary";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { DELETE_FORM } from "../../graphql/mutations";
import {
  GET_PARENT_ASSIGNED_FORMS,
  GET_PARENT_FORMS_INDEX,
} from "../../graphql/queries";
import {
  LATE_EDIT_STATUSES,
  isFamilyEditableStatus,
} from "src/services/lateEdit";

interface Props {
  form: Pick<
    GQL.GetParentFormsIndex_person_forms,
    "id" | "status" | "form_school_ranks"
  >;
  formTemplate: GQL.GetParentFormsIndex_person_forms_form_template;
}

// Form ID Modal Component
const FormIdModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  formId: string;
  formTemplateName: string;
  enrollmentPeriodName: string;
}> = ({ isOpen, onClose, formId, formTemplateName, enrollmentPeriodName }) => {
  // Format the short ID (first 8 characters with space in middle)
  const shortId = `${formId.slice(0, 4)} ${formId.slice(4, 8)}`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={true}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent maxW="400px">
        <ModalBody py={4}>
          <VStack spacing={2} align="center">
            <Text>
              Form ID - {formTemplateName} for {enrollmentPeriodName}
            </Text>
            <Heading
              fontSize="32px"
              fontWeight="normal"
              letterSpacing="wide"
              fontFamily="mono"
            >
              {shortId}
            </Heading>
            <Text
              fontSize="sm"
              color="gray.500"
              mt={1}
              fontFamily="mono"
              textAlign="center"
            >
              Full ID: {formId}
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const FormCardActionMenu: React.FC<Props> = ({ form, formTemplate }) => {
  const toast = useToast();
  const organization = useOrganization();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const offerIDs = form.form_school_ranks
    .flatMap((x) => x.offers)
    .map((x) => x.id);
  const waitlistIDs = form.form_school_ranks
    .flatMap((x) => x.waitlists)
    .map((x) => x.id);

  const [deleteForm, deleteRemoteData] = useMutation<
    GQL.DeleteForm,
    GQL.DeleteFormVariables
  >(DELETE_FORM);

  const text =
    offerIDs.length || waitlistIDs.length
      ? `Deleting this form will also decline any offers and withdraw the student from any waitlists. This can't be undone nor recovered.`
      : `This can't be undone nor recovered`;
  const { confirm, confirmationDialog } = useConfirmationDialog({
    body: <Box>{text}</Box>,
    header: "Delete form?",
    cancelButton: { label: "No, cancel" },
    confirmButton: { label: "Yes, delete", colorScheme: "red" },
  });

  const onDeleteClick = useCallback(async () => {
    if (await confirm()) {
      try {
        await deleteForm({
          variables: {
            form_id: form.id,
          },
          refetchQueries: [GET_PARENT_FORMS_INDEX, GET_PARENT_ASSIGNED_FORMS],
        });
      } catch (err: unknown) {
        console.error(err);
        toast({
          title: "Error deleting form",
          description:
            "Please try again later or report the problem to our support team.",
          status: "error",
          isClosable: true,
        });
      }
    }
  }, [confirm, deleteForm, form.id, toast]);

  const isFamilyEditableStatusesEnabled = isFamilyEditableStatus(
    formTemplate.sections,
    form.status
  );
  const showEditFormButton =
    [GQL.form_status_enum.Submitted, GQL.form_status_enum.InProgress].includes(
      form.status
    ) && isFamilyEditableStatusesEnabled;

  const showLateEditSchoolsButton =
    LATE_EDIT_STATUSES.includes(form.status) && isFamilyEditableStatusesEnabled;

  return (
    <>
      <Menu>
        <MenuButton
          aria-label="Form options"
          as={IconButton}
          colorScheme="gray"
          icon={<RiMore2Fill />}
          isRound
          marginBlockEnd={-4 /* Negate influence on parent flex layout */}
          size="sm"
          variant="outline"
        />

        <MenuList>
          <MenuItem onClick={onOpen}>
            <Glossary>View form ID</Glossary>
          </MenuItem>
          <MenuItem
            as={RouterLink}
            to={organization
              .map((org) => Url.Parent.Form.view(org, form.id))
              .withDefault("#")}
          >
            <Glossary>View form</Glossary>
          </MenuItem>
          {showLateEditSchoolsButton && (
            <MenuItem
              as={RouterLink}
              to={organization
                .map((org) => Url.Parent.Form.lateEdit(org, form.id))
                .withDefault("#")}
            >
              <Glossary>Edit form</Glossary>
            </MenuItem>
          )}
          {showEditFormButton && (
            <>
              <MenuItem
                as={RouterLink}
                isDisabled={deleteRemoteData.loading}
                to={organization
                  .map((org) => Url.Parent.Form.edit(org, form.id))
                  .withDefault("#")}
              >
                <Glossary>Edit form</Glossary>
              </MenuItem>

              {/* TODO: remove hardcoded for philasd with ABAC implementation */}
              {!["philasd", "saisd"].includes(
                organization.toNullable()?.path ?? ""
              ) && (
                <>
                  <Divider />
                  <MenuItem
                    isDisabled={deleteRemoteData.loading}
                    onClick={onDeleteClick}
                  >
                    <Glossary>Delete form</Glossary>
                  </MenuItem>
                </>
              )}
            </>
          )}
        </MenuList>
        {confirmationDialog}
      </Menu>
      <FormIdModal
        isOpen={isOpen}
        onClose={onClose}
        formId={form.id}
        formTemplateName={formTemplate.name}
        enrollmentPeriodName={formTemplate.enrollment_period.name}
      />
    </>
  );
};
