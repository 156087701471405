import { Flex } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { useGlossary } from "src/hooks/useGlossary";
import { Selection } from "src/hooks/useMultiselectState";
import { useOrganization } from "src/hooks/useOrganization";
import { checkboxColumnDef } from "src/hooks/useTableSelection";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { MenuItem } from "./components/menuItem";
import { TableBanner } from "./components/TableBanner";
import { buildGradeColumnDef } from "./formTables/gradeColumn";
import { buildSchoolColumnDef } from "./formTables/schoolColumn";
import { buildStatusColumnDef } from "./formTables/statusColumn";
import { buildStudentColumnDef } from "./formTables/studentColumn";
import { buildSubStatusColumnDef } from "./formTables/subStatusColumn";
import {
  buildFormTagsColumnDef,
  buildTagsColumnDef,
} from "./formTables/tagsColumn";
import { GqlSchoolForm, SchoolFormId, SchoolFormKeyRecord } from "./types";

type Props = {
  enrollmentPeriodId: uuid;
  count: number;
  offset: number;
  limit: number;
  onFetchAll: () => Promise<GqlSchoolForm[]>;
  onFetchAllIds: () => Promise<SchoolFormId[]>;
  onFetchMore: (limit: number, offset: number) => void;
  onRefetch: () => Promise<unknown>;
  onFetchByIds: (ids: SchoolFormId[]) => Promise<GQL.ExportForms>;
  dropoffFormTemplateId: uuid;
  previousFormTemplateRemoteData: RD.RemoteData<
    unknown,
    GQL.FormTemplateFragment
  >;
  onSelectionChange: (
    selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>
  ) => void;
  selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>;
  remoteData: RD.RemoteData<unknown, GqlSchoolForm[]>;
  tagGroups: GQL.GetTagGroupsByEnrollmentPeriod_tag_group[];
};
export const DropoffFormsList: React.FC<Props> = (props) => {
  const {
    onFetchAll,
    onFetchAllIds,
    onRefetch,
    onFetchByIds,
    enrollmentPeriodId,
    remoteData,
    dropoffFormTemplateId,
    previousFormTemplateRemoteData,
    limit,
    offset,
    count,
    onFetchMore,
    onSelectionChange,
    selection,
    tagGroups,
  } = props;
  const { glossary } = useGlossary();
  const organization = useOrganization();
  const columns: ColumnDef<GqlSchoolForm>[] = [
    checkboxColumnDef({
      count,
      id: "is_selected",
      onSelectionChange,
      selection,
    }),
    buildStudentColumnDef({
      glossary,
      organization,
      formTemplateId: previousFormTemplateRemoteData
        .map((f) => f.id)
        .withDefault(""),
    }),
    buildSchoolColumnDef({ glossary }),
    buildGradeColumnDef(),
    buildStatusColumnDef({
      organization,
      header: previousFormTemplateRemoteData
        .map((f) => `${f.name} status`)
        .withDefault("status"),
    }),
    buildSubStatusColumnDef({
      organization,
    }),
    buildFormTagsColumnDef(),
    ...tagGroups.map((tagGroup) => buildTagsColumnDef<GqlSchoolForm>(tagGroup)),
  ];

  return (
    <Flex direction="column" gap="4">
      {remoteData.hasError() && <GenericError />}
      <PaginatedTable<GqlSchoolForm>
        data={remoteData.hasData() ? remoteData.data : []}
        columns={columns}
        isLoading={
          remoteData.isLoading() || previousFormTemplateRemoteData.isLoading()
        }
        sortableColumnIds={[]}
        count={count}
        limit={limit}
        offset={offset}
        onFetchMore={onFetchMore}
        banner={() => (
          <RemoteDataView
            error={() => <GenericError />}
            remoteData={previousFormTemplateRemoteData}
          >
            {(formTemplate) => (
              <TableBanner
                hiddenMenuItems={[
                  MenuItem.Offers,
                  MenuItem.BulkVerify,
                  MenuItem.BulkStatusUpdate,
                  MenuItem.BulkStatusDescriptionUpdate,
                ]}
                dropoffFormTemplateId={dropoffFormTemplateId}
                formTemplate={formTemplate}
                enrollmentPeriodId={enrollmentPeriodId}
                includeUpdateStatus={false}
                onFetchAll={onFetchAll}
                onFetchAllIds={onFetchAllIds}
                onFetchByIds={onFetchByIds}
                onRefetch={onRefetch}
                onSelectionChange={onSelectionChange}
                selection={selection}
              />
            )}
          </RemoteDataView>
        )}
      />
    </Flex>
  );
};
