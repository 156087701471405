import { Tooltip } from "@chakra-ui/react";
import * as Format from "src/services/format";
import * as DateFns from "date-fns";
import { useState, useEffect } from "react";
import { Result, failure, success } from "src/types/result";
import { GenericError } from "../Feedback/GenericError";

type Props = {
  timestamp: string;
  format?: string;
};
export const RelativeTime: React.FC<Props> = ({
  timestamp,
  format = "yyyy-MM-dd HH:mm:ss z",
}) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // recalculate relative time every 1 min.
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60_000);

    return () => clearInterval(interval);
  }, []);

  const timestampFormatted = Format.safeFormatIsoDate(
    currentTime.toISOString(),
    format,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const timestampParsed = safeParseISO(timestamp);

  if (!timestampFormatted.success || !timestampParsed.success) {
    return <GenericError variant="inline" message="Invalid timestamp" />;
  }

  return (
    <Tooltip label={timestampFormatted.data}>
      {DateFns.formatDistance(timestampParsed.data, currentTime)}
    </Tooltip>
  );
};

function safeParseISO(timestamp: string): Result<Date> {
  try {
    const parsedDate = DateFns.parseISO(timestamp);
    if (!DateFns.isValid(parsedDate)) {
      return failure("Invalid date");
    }
    return success(parsedDate);
  } catch (error) {
    if (error instanceof Error) return failure(error.message);
    return failure("Unknown error");
  }
}
