import * as OrgConfig from "@avela/organization-config-sdk";

export type Branding = OrgConfig.Branding.Config;
export const DefaultBranding = OrgConfig.Branding.DefaultConfig;

export function getWhiteLogo(branding: Branding): {
  url: string;
  alt: string;
} {
  if (branding.logos.whiteSvg === DefaultBranding.logos.whiteSvg) {
    return {
      url: branding.logos.whitePng,
      alt: getLogoAlt(branding),
    };
  }

  return {
    url: branding.logos.whiteSvg,
    alt: getLogoAlt(branding),
  };
}

export function getColorLogo(branding: Branding): {
  url: string;
  alt: string;
} {
  if (branding.logos.colorSvg === DefaultBranding.logos.colorSvg) {
    return {
      url: branding.logos.colorPng,
      alt: getLogoAlt(branding),
    };
  }
  return {
    url: branding.logos.colorSvg,
    alt: getLogoAlt(branding),
  };
}

export function getLogoAlt(branding: Branding) {
  return `${branding.name} logo`;
}
