import { Button, Card, Flex, Image, Link, Text } from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import { DefaultBranding } from "src/schemas/Branding";
import { generateColorPalette } from "./ColorPalette";
import * as Logo from "./Logo";
import { Branding } from "./BrandingForm";

type Props = {
  branding: Branding;
};
export const BrandingPreview: React.FC<Props> = ({ branding }) => {
  const {
    primaryColor,
    colorLogoPng,
    colorLogoSvg,
    whiteLogoPng,
    whiteLogoSvg,
  } = branding;
  const primaryColorPalette = !_.isEmpty(primaryColor)
    ? generateColorPalette(primaryColor)
    : DefaultBranding.colors.primary;

  let whiteLogoUrl: string;
  if (whiteLogoSvg && whiteLogoSvg.type !== "default") {
    whiteLogoUrl = Logo.getPreviewUrl(whiteLogoSvg);
  } else if (whiteLogoPng && whiteLogoPng.type !== "default") {
    whiteLogoUrl = Logo.getPreviewUrl(whiteLogoPng);
  } else {
    whiteLogoUrl = DefaultBranding.logos.whiteSvg;
  }

  let colorLogoUrl: string;
  if (colorLogoSvg && colorLogoSvg.type !== "default") {
    colorLogoUrl = Logo.getPreviewUrl(colorLogoSvg);
  } else if (colorLogoPng && colorLogoPng.type !== "default") {
    colorLogoUrl = Logo.getPreviewUrl(colorLogoPng);
  } else {
    colorLogoUrl = DefaultBranding.logos.colorSvg;
  }

  return (
    <Flex direction="column" width="100%" height="100%" borderRadius="md">
      <Flex
        direction="column"
        gap={2}
        background={primaryColorPalette[500]}
        paddingY={4}
        paddingX={6}
        alignItems="flex-start"
      >
        <Image src={whiteLogoUrl} alt="White logo" height="3rem" />
      </Flex>
      <Flex direction="column" padding={10} bg="gray.100" height="100%">
        <Card
          display="flex"
          width="100%"
          alignItems="flex-start"
          padding={8}
          gap={6}
        >
          <Image src={colorLogoUrl} alt="Color logo" height="3rem" />
          <Text>
            This is a diagram of how the brand will look like once live. No
            screen in Avela looks exactly like this.
          </Text>
          <Text>
            <Link
              href="#"
              color={primaryColorPalette[500]}
              textDecoration="underline"
            >
              Hyperlinks
            </Link>{" "}
            are generally underlined and use primary colors unless used over a
            background color other than white.
          </Text>
          <Text>
            Primary colors are also used in buttons and other means of
            navigating the app.
          </Text>
          <Flex direction="row" gap={4} justifyContent="right" width="100%">
            <Button
              variant="outline"
              color={primaryColorPalette[500]}
              _hover={{ background: primaryColorPalette[50] }}
            >
              Secondary button
            </Button>
            <Button
              _hover={{ background: primaryColorPalette[600] }}
              background={primaryColorPalette[500]}
            >
              Primary button
            </Button>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};
